import React, {useState} from 'react';
import {Image,Button,Media, Col, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Icofont from 'react-icofont';
import PropTypes from 'prop-types'; 


export const QuickBite = (props) => {
      const {nazev, popis, cena, weight, allergen, cislo, palcivost} =  props.item
      const [showImage, setshowImage] = useState(true)
      const handleAdd = ()=>{
        props.onClick(props.item)
      }

    return (
      <>
      <div className={"p-3 border-bottom "+props.itemClass}>
        <Row>
        <Col md={9} sm={12} className='noMarginRightMobile'>
          <Media>
            {showImage &&
            <Image onClick={()=>props.showModal(props.item.polozka_id)} className={"mr-3 "} src={`https://www.jidlopodnos.cz/data/jidla/small/${props.item.polozka_id}.png`} onError={(e)=>{e.target.onerror = null; e.target.src="";setshowImage(false)}} alt="" />
            }
            <Media.Body>
              <h6 className="mb-1">{cislo? cislo+" - ":""} {nazev} {palcivost > 0 && [...Array(palcivost)].map(() => "🌶")}</h6>
              <p className="order-big-card-item-description">{popis}</p>
              {(allergen || weight)  && 
              <p className="order-big-card-item-alergens"> {allergen && "Alergeny: "+convertAlergen(allergen)} {(allergen && weight) && <> | <br/></>} {weight}</p>
              }
              <span className="order-big-card-item-price"> {cena} Kč </span>
              
            </Media.Body>
            
          </Media>
        </Col>
        <Col md={3} sm={12} className='noMarginRightMobile' style={{"alignSelf": "center"}}>
          <div className='add-to-cart-parent'>
            {props.directToRest ? 
             
            <Link className="text-primary" to={props.directToRest}><Button variant="primary"><Icofont icon='arrow-right'/>Přejít na restauraci</Button></Link>
            :
            <Button className='add-to-cart-button' onClick={handleAdd} size="sm">Přidat do košíku</Button>  
            }
            
          </div>
        </Col>
      </Row>
       
		</div>
    </>
    );
  }

  function convertAlergen(alergen_values){
    //Example alergen_values = 1,3,7,4

    try {
      let alergeny = []
      alergeny[1]='Obiloviny obsahující lepek';
      alergeny[2]='Korýši';
      alergeny[3]='Vejce';
      alergeny[4]='Ryby';
      alergeny[5]='Arašídy';
      alergeny[6]='Sójové boby';
      alergeny[7]='Mléko a mléčné výrobky';
      alergeny[8]='Skořápkové plody';
      alergeny[9]='Celer';
      alergeny[10]='Hořčice';
      alergeny[11]='Sezamová semena';
      alergeny[12]='Oxid siřičitý a siřičitany';
      alergeny[13]='Vlčí bob';
      alergeny[14]='Měkkýši';

      return alergen_values.split(",").map(x => 
        " "+x+" - "+alergeny[x]
      ).join()

      
      
    } catch (error) {
      console.log(error)
      return alergen_values
    }
  }
  



export default QuickBite;