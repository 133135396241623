import React from 'react'
import StarRating from '../common/StarRating';
import RatingBar from '../common/RatingBar';
import Review from '../common/Review';
import {Link} from 'react-router-dom';
import {useState,useEffect} from 'react';
import {Col, Spinner, Button} from 'react-bootstrap';
import useFetch from '../../useFetch.js'
import {useParams} from 'react-router-dom';
import axios from 'axios';

const DetailRatingAndReviews = () => {

	let { restaurant } = useParams(); 

	const [page, setPage] = useState(0)
	const [lastPage, setLastPage] = useState(false)

	const {data: ratingData,loading: loadingRating} = useFetch('/api/restaurants/reviews/rating/'+restaurant)
	const [reviewData, setReviewData] = useState([])

	useEffect(() => {
		axios({
			method: 'get',
			url: '/api/restaurants/reviews/'+restaurant+"?page="+page
		}).then(response => {
			console.log(response)
			if (response.status === 200) {
				if(!response.data.length){
					return
				}
				if (response.data.length < 5) {
					setLastPage(true)
				}

				setReviewData([...reviewData, ...response.data])
				//setLoading(false)
			}
			else
				console.log("Načítání hodnocení ze serveru se nepodařilo.")
		})
	}, [page])

	//const {data: reviewData,loading: loadingReviews} = useFetch('/api/restaurants/reviews/'+restaurant+"?page="+page) 

	if (loadingRating) return <Col md={12} className="text-center load-more">
							<Button variant="primary" type="button" disabled="">
								<Spinner animation="grow" size="sm" className='mr-1' />
								Načítám...
							</Button>  
						</Col>

    return (
        <>
            
			<div className="bg-white rounded shadow-sm p-4 mb-4 clearfix graph-star-rating">
				<h5 className="mb-0 mb-4">Hodnoceni a Recenze</h5>
				<div className="graph-star-rating-header pl-3">
					<div className="star-rating">
					<StarRating fontSize={18} disabled={true} star={Math.round(ratingData?.hodnoceni)||0}/>  
					<b className="text-black ml-2">{ratingData && ratingData.pocet} <small>hodnocení</small></b>
					</div>
					<p className="text-black mb-4 mt-2">Ohodnoceno {ratingData && Math.floor(ratingData.hodnoceni* 10) / 10} z 5</p>
				</div>
				{/* <div className="graph-star-rating-body">
				<RatingBar leftText="5 Star" barValue={56} />
				<RatingBar leftText="4 Star" barValue={23} />
				<RatingBar leftText="3 Star" barValue={11} />
				<RatingBar leftText="2 Star" barValue={6} />
				<RatingBar leftText="1 Star" barValue={4} />
				</div> */}
			</div>
			<div className="bg-white rounded shadow-sm p-4 mb-4 restaurant-detailed-ratings-and-reviews">
				{/* <h5 className="mb-1">Všechny hodnocení a recenze</h5> */}
				{reviewData ? 
				<>
				{reviewData && reviewData.length>0 && reviewData.map((review) => (
					<Review
					data={review}
					/>
				))}
				{!lastPage ?
					<Col className="d-flex justify-content-center" md={12}>
						<Button onClick={() => setPage(page + 1)} className="" variant="info" type="button" disabled="">
							Načíst další...
						</Button>
					</Col>
					:
					<span className="text-secondary">To je vše...</span>
					
				}
				</>
				:
				<Col md={12} className="text-center load-more">
					<Button variant="primary" type="button" disabled="">
						<Spinner animation="grow" size="sm" className='mr-1' />
						Načítám...
					</Button>  
				</Col>
				}
			</div>
						        
        </>
    )
}

export default DetailRatingAndReviews
