import React,{useContext} from 'react';
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import CardItem from '../common/CardItem';
import useFetch from '../../useFetch.js'
import {AppContext} from '../../context.js'

const Favourites = () => {

	const {state, dispatch} = useContext(AppContext)

	const { data: restaurants, loading, error: errorInfo } = useFetch('/api/user/fav_rest')

	return (
		<>
			<div className='p-4 bg-white shadow-sm'>
				<Row>
					<Col md={12}>
						<h4 className="font-weight-bold mt-0 mb-3">Oblíbené</h4>
					</Col>
					{!loading ?
						(restaurants && restaurants.length > 0 ? (restaurants.map((restaurant) => (
							<Col md={4} className="columns">
								<CardItem
									key={restaurant.id}
									id={restaurant.id}
									title={restaurant.name}
									isFav={restaurant.isFav}
									subTitle={restaurant?.rest_categories?.split(",").splice(0,3).join()}
									imageAlt={restaurant.imageAlt}
									image={`https://www.jidlopodnos.cz/data/restaurace/mkartan/${restaurant.id}.jpg`}
									imageClass={restaurant.imageClass}
									linkUrl={"/rozvoz-jidel/" + restaurant.mesto_url + "/" + restaurant.rest_url + "/objednat"}
									time={`${restaurant.delivery_tmin+(Number(state?.location?.cas || 0))}-${restaurant.delivery_tmax+(Number(state?.location?.cas || 0))}min`}
									favIcoIconColor={restaurant.favIcoIconColor}
									rating={restaurant.rating}
									deliveryPrice={restaurant.delivery_price}
									stravenky={restaurant.stravenky}
									creditCard={restaurant.karta}
									bonusProgram={true}
									freeDelivery={true}
									canBookTable={true}
								/>
							</Col>
						)))
							:
							<Col md={12}>
								<h6 className="text-secondary">Zdá se, že tu nic není...</h6>
							</Col>)
						: <Col md={12} className="text-center load-more">
							<Button variant="primary" type="button" disabled="">
								<Spinner animation="grow" size="sm" className='mr-1' />
								Načítání...
							</Button>
						</Col>}


				</Row>
			</div>
		</>
	);

}
export default Favourites;