import React from 'react'
import {Link} from 'react-router-dom'


const CityLink = ({text,city_url}) => {
    return (
        <Link to={"/rozvoz-jidel/"+city_url} >
            <div className={`city-link `}  >
                <span className={`analytics-where-are-we-buttons`} style={{padding: "10px 20px"}} analyticsvalue={city_url}>{text}</span>
            </div>
        </Link>
    )
}

export default CityLink


