import { useEffect, useState } from "react";
import { App } from '@capacitor/app';

const useFetchARefresh = url => {
  const [state, setState] = useState({ data: null, loading: true });
  const [refresh, setRefresh] = useState(0);
  useEffect(() => {
    if(!state.data)
    setState(state => ({ data: state.data, loading: true }));
    //console.log("Fetching from: "+url)
    fetch(url)
      .then(x => x.json())
      .then(res => {
        setTimeout(function () {
          setRefresh(refresh+1)
        }, 15000);
        //console.log("Result from: "+url,res)
        if(res.error){
          setState({ data: state.data, loading: false, error: res.error })
        }else{
          setState({ data: res, loading: false, error: false });
        }
      }).catch(e => {
        setState({ data: state.data, loading: false, error: e })
      })
  }, [url,refresh]);

  useEffect(() => {
    
    App.addListener('appStateChange', ({ isActive }) => {
      if(isActive){
        console.log("App state switched to active")
        setRefresh(refresh+1)
      }
    });

  }, []);
  
  

  return state;
};

export default useFetchARefresh