
import React, {useState} from 'react';
import Icofont from 'react-icofont';
import { Button } from 'react-bootstrap';
import useWindowSize from '../../useWindowsize'

  
const ScrollButton = () =>{
  
  const [visible, setVisible] = useState(false)

  const size = useWindowSize();
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  
  window.addEventListener('scroll', toggleVisible);
  
    return (      
    <Button onClick={()=>scrollToTop()} style={{"display": visible ? 'inline' : 'none',...style}}>
        <Icofont icon="arrow-up"></Icofont> {size.width >= 767.98 && "NAHORU"}
    </Button>
  );

}
  
export default ScrollButton;

const style = {
    "zIndex":"999",
    "position":"fixed",
    "right":"2.5vw",
    "bottom":"80px",

}