import React,{useCallback,useState, useContext,useEffect} from 'react'
import {AppContext} from '../../context.js'
import {Form,Button} from 'react-bootstrap'
import Icofont from 'react-icofont'

export default function NameFilter() {

    const {state, dispatch} = useContext(AppContext)
    const [value, setValue] = useState("")

    useEffect(() => {
        
        return () => {
            dispatch({type: 'SET_NAME_RESTAURANT_FILTER', payload: ""})
        }
    }, [])

    const removeFilter = useCallback(()=>{
        dispatch({type: 'SET_NAME_RESTAURANT_FILTER', payload: ""})
    },[])

    return (
        <>
        <Form.Group style={{"position":"relative"}}>
            <Form.Control value={state.name_restaurantFilter} className=" " type="text" placeholder={"Hledat restauraci"} size='lg' onChange={e => {dispatch({type: 'SET_NAME_RESTAURANT_FILTER', payload: e.target.value})}} />
            <Button onClick={removeFilter} style={{"position":"absolute","right":"0","top":"0","background":"transparent"}} variant="none"><Icofont icon="close-line"/></Button>
        </Form.Group>
        </>
    )
}
