import React,{useEffect} from 'react'
import { useHistory } from 'react-router-dom';
import { App, URLOpenListenerEvent } from '@capacitor/app';

export const AppUrlListener = () => {

    let history = useHistory();


    useEffect(() => {
        App.addListener('appUrlOpen', (event) => { //Matchujeme pro .app v URL
            console.log("Listener activated, url opened")
            // Example url: https://beerswift.app/tabs/tab2
            // slug = /tabs/tab2
            const slug = event.url.split('application').pop();
            if (slug) {
              history.push(slug);
            }
          });
    }, [])

    return (
        <></>
    )
}
