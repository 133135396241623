import React from 'react';
import {Modal,Button} from 'react-bootstrap';

class DeleteAddressModal extends React.Component {

	render() {
    	return (
	        <Modal 
	        	show={this.props.show} 
	        	onHide={this.props.onHide}
		        centered
		        size="sm"
		   	  >
			  <Modal.Header closeButton={true}>
			    <Modal.Title as='h5' id="delete-address">Smazat</Modal.Title>
			  </Modal.Header>

			  <Modal.Body>
  				<p className="mb-0 text-black">Jste si jistý, že adresu chcete smazat?</p>   
			  </Modal.Body>

			  <Modal.Footer>
			    <Button onClick={this.props.deleteAddress} type='button' variant="primary" className='d-flex w-50 text-center justify-content-center'>SMAZAT</Button>
			  </Modal.Footer>
			</Modal>
    	);
    }
}
export default DeleteAddressModal;