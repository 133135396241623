
import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Row, Col, Container, Button, Spinner, Image, Badge, Nav, Modal } from 'react-bootstrap';

import { Switch, Route, useParams, Redirect } from 'react-router-dom';
import CityUtilityMeter from './common/CityUtilityMeter'
import axios from 'axios'


import { AppContext } from '../context.js'
import useFetch from '../useFetch.js'
import useFetchARefresh from '../useFetchAutoRefresh'
import { useHistory } from "react-router-dom";
import QuickBite from './common/QuickBite';


const PoledniMenu = (props, context) => {

	const { state, dispatch } = useContext(AppContext)
	const [destination, setDestination] = useState()
	let history = useHistory()
	let { city } = useParams();


	const { data: menu, loading: loading, error: error } = useFetch("/api/menu/cityDailyMenu/" + city)

	if (loading) return (
		<Container className="pb-5" style={{ "paddingTop": "8rem", "minHeight": "60vh" }}>
			<Row>
				<Col md={12} className="text-center load-more">
					<Button variant="primary" type="button" disabled="">
						<Spinner animation="grow" size="sm" className='mr-1' />
						Načítání...
					</Button>
				</Col>
			</Row>
		</Container>
	)

	if (error) return (
		<Container className="pb-5" style={{ "padding-top": "8rem", "min-height": "60vh" }}>
			<Row>
				<Col md={12} className="text-center load-more">
					<Button variant="primary" type="button" disabled="">
						Chyba
					</Button>
				</Col>
			</Row>
		</Container>
	)

	if (menu.length == 0) return (
		<Container className="pb-5" style={{ "paddingTop": "8rem", "minHeight": "60vh" }}>
			<Row>
				<Col md={12} className="load-more">
					<h2>Polední menu</h2>
					<br></br>
					<h6>Aktuálně tu nic není...</h6>
					<p>Žádné polední menu v tento čas není k dispozici</p>
				</Col>
			</Row>
		</Container>
	)

	return (
		<Container className="pb-5" style={{ "padding-top": "8rem", "min-height": "60vh" }}>
			<Row>
				<Col md={12} className=" load-more">
					<h2>Polední menu</h2>


					{menu && Object.keys(menu)?.map((rest, indexRest) => (
						<Row key={indexRest}>

							<h4 className="mb-4 mt-3 col-md-12 order-big-card-category-title">{rest}</h4>
							<Col md={12}>

								
									{Object.keys(menu[rest]).map((lunchCategory, indexLunch) => (
										
										<div className="order-big-card-category-parent rounded mb-4">
											<h6 className="mb-4 mt-3 col-md-12 order-big-card-category-title">Polední menu {lunchCategory && lunchCategory != "undefined" && lunchCategory?.length> 0? "- "+lunchCategory:""}</h6>
											{menu[rest][lunchCategory]?.map(item => (
												<QuickBite key={item.polozka_id} item={item} /* showModal={setShowLargeImage} */ itemClass="menu-list" directToRest={("/rozvoz-jidel/" + city + "/" + item?.restUrl + "/objednat") || "error-no-rest-url-provided"} /* onClick={displayExtraItems} */ />
											))}
										</div>
										
									))}
								
							</Col>

						</Row>
					))}

				</Col>
			</Row>
		</Container>

	)
}




export default PoledniMenu;