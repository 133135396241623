import React,{useContext,useEffect,useState} from 'react';
import {Link} from 'react-router-dom';
import {Row,Col,Container, Button, Spinner} from 'react-bootstrap';
import Icofont from 'react-icofont';
import useFetchARefresh from '../useFetchAutoRefresh'
import {useParams} from 'react-router-dom';
import {AppContext} from '../context.js'
import ReviewOrder from './ReviewOrder';
import { useLocation } from 'react-router';
 

const TrackOrder = ()=> {

	let { cislo,year } = useParams(); 
	let query = useQuery();
	let guid = query.get("guid")
	const {state, dispatch} = useContext(AppContext)
	const {data: order,loading: loadingOrder,error:errorOrder} = useFetchARefresh(`/api/orders/detail/${year}/${cislo}`) 
	const [lastUpdate_timestamp, setlastUpdate_timestamp] = useState(false)
	const [lastStatus, setLastStatus] = useState(null)

	useEffect(() => {
		if(order && order?.order_status && order.order_status != lastStatus){
			console.log("Sound notification triggered")
			setLastStatus(order.order_status)
			var audio = new Audio('data/notification.mp3');
  			audio.play();
		}
		
	}, [order])

	useEffect(() => {
		setlastUpdate_timestamp(new Date().toLocaleString())
	}, [order])

	if(loadingOrder) return (
		<Container className="pb-5" style={{"paddingTop":"8rem","minHeight":"60vh"}}>
			<Row>
				<Col md={12} className="text-center load-more">
				<Button variant="primary" type="button" disabled="">
					<Spinner animation="grow" size="sm" className='mr-1' />
					Načítání...
				</Button>  
				</Col>
			</Row>
		</Container>
	)

	if(!(order && order.order_number)) return (
		order && order?.message == "Objednávka nenalezena" ?
		<>
		<Container className="pb-5" style={{"padding-top":"8rem","min-height":"60vh"}}>
			<Row>
				<Col md={12} className="text-center load-more">
					<div className="track-order-circle filled mx-auto">
						<Icofont style={{"fontSize":"2em"}} icon="close"/>
					</div>
					<br />
					<h4>Objednávka nenalezena</h4>
					<span style={{"fontSize":"120%"}}></span>
				</Col>
			</Row>
		</Container>
		</>
		:
		<Container className="pb-5" style={{"padding-top":"8rem","min-height":"60vh"}}>
			<Row>
				<Col md={12} className="text-center load-more">
				<Button variant="primary" type="button" disabled="">
					Chyba při načítání objednávky / Objednávka neexistuje
				</Button>  
				</Col>
			</Row>
		</Container>
	)

	return (
		<section className="section bg-white osahan-track-order-page position-relative">
	         <Container className="pb-5" style={{"paddingTop":"80px"}}>
	            <Row className="d-flex align-items-center">
	               <Col md={6} className="text-center pb-4">
				   		<div className="mt-1 track-order-circle-description filled">
							{
							order.vlastni ?
							<>
							<div className="track-order-circle filled mx-auto">
								<Icofont style={{"fontSize":"2em"}} icon="basket"/>
							</div>
							<br />
							<h4>Objednávka přijata</h4>
							<span style={{"fontSize":"120%"}}>Objednávka byla přijata do systému</span><br></br>
							<span>Restaurace zajišťuje vlastní rozvoz jídla, stav objednávky se nebude aktualizovat</span>
							</>
							:
							renderStatus(order.order_status)
							}
							<br/>
							{!order.vlastni && lastUpdate_timestamp && 
								<small style={{"color":"rgb(161 161 161)"}}>Naposledy aktualizováno: {lastUpdate_timestamp}</small>
							}
							{order.horkalinka &&
								<p>
									<Icofont icon="phone"/> Horká linka: {order.horkalinka}
								</p>					
							}
							{order.r_jmeno && order.r_telefon &&
								<p>
									<Icofont icon="car"/> Objednávku doručuje: {order.r_jmeno} <b> <Icofont icon="phone"/> {order.r_telefon}</b>
								</p>	
							}					
						</div>
	               </Col>
	               <Col md={6}>
	                  <div className="track-order-header bg-white p-3 pt-5 p-l-0">
	                     <div className="mb-2"><small>Objednávka #{order.order_number}</small></div>
	                     <h6 className="mb-1 mt-1">
	                     	<span className="text-black"> {order.restaurant_name}
	                        </span>
	                     </h6>
						 
						 {state.user ? <span className="track-order-detail"><Link to="/mujucet/objednavky" className="text-black">Moje objednávky</Link></span>:""}
						 {order.deliver_later && 
						 (order.poledni == "a" ?
						 <p className="text-gray mb-0 text-primary"><Icofont icon="sand-clock"/>Objednávka bude doručována mezi {order.deliver_later} a {order.deliver_later_plus1}</p>
						 :
						 <p className="text-gray mb-0 text-primary"><Icofont icon="sand-clock"/>Objednávka bude doručována v: {order.deliver_later}</p>
						 )}
	                     <p className="text-gray mb-0"><Icofont icon="clock-time"/> {order.date} | {order.cart.length} Položek | {order.payment_method_name}</p>
	                  </div>
	                  <div className="bg-white p-4  ">
						<Row className="order-cart">
							{order && order.cart.map(item=>(<>
								
								<div className={`mt-1 order-cart-item `} style={{"width":"100%","marginBottom":"1em"}}>
									<p className="mb-0 float-right ml-2 order-cart-price">{item.product_price} Kč</p>
									<p className="mb-0 order-cart-item-text">{item.product_name}</p>
										


								{item.extraItems ? (item.extraItems.map((extraItem,index) => (
									
									<div key={index}>
										{extraItem.price>0 &&
										<p className="mb-0 float-right ml-2 order-cart-price">{extraItem.product_price} Kč</p>
										}
										<p className="mb-0 order-cart-sub-item-text">+ {extraItem.product_name}</p>
									</div> 
									
									))) : ""}

								</div>
							</>))}
							<div className={`mt-1 order-cart-item `} style={{"width":"100%","marginBottom":"1em","fontWeight":"bold"}}>
									<p className="mb-0 float-right ml-2 order-cart-price">{order.price_packaging} Kč</p>
									<p className="mb-0 order-cart-item-text">Obaly</p>
								</div>
								<div className={`mt-1 order-cart-item `} style={{"width":"100%","marginBottom":"1em","fontWeight":"bold"}}>
									<p className="mb-0 float-right ml-2 order-cart-price">{order.price_delivery > 0 ? order.price_delivery+" Kč":"zdarma"}</p>
									<p className="mb-0 order-cart-item-text">Doprava</p>
								</div>
								{order.creditsUsed > 0 ?<div className={`mt-1 order-cart-item `} style={{"width":"100%","marginBottom":"1em","fontWeight":"bold"}}>
									<p className="mb-0 float-right ml-2 order-cart-price">-{order.creditsUsed} Kč</p>
									<p className="mb-0 order-cart-item-text">Použité kredity</p>
								</div>:""}
								{order.minimalOrderAddition && order.minimalOrderAddition > 0 ? <div className={`mt-1 order-cart-item `} style={{"width":"100%","marginBottom":"1em","fontWeight":"bold"}}>
									<p className="mb-0 float-right ml-2 order-cart-price">{order.minimalOrderAddition} Kč</p>
									<p className="mb-0 order-cart-item-text">Doplatek do minimální objednávky</p>
								</div>:""}
								<div className={`mt-1 order-cart-item `} style={{"width":"100%","marginBottom":"1em","fontWeight":"bold"}}>
									<p className="mb-0 float-right ml-2 order-cart-price">{order.price_paid} Kč</p>
									<p className="mb-0 order-cart-item-text">Celkem</p>
								</div>

								
						 </Row>
	                  </div>
	                  
	               </Col>
				   
	            </Row>
				<Row> {/* Review order */}			
					{guid && order.order_status== "Doručeno" &&
					<Col className="text-center">
						<ReviewOrder hodnoceno={order.hodnoceno} guid={guid} />
					</Col>}
				</Row>
	         </Container>
	      </section>
    	);

		function renderStatus(status){
			switch(status){
				

				case "Naloženo": return (
					<>
					<div className="track-order-circle filled mx-auto">
						<Icofont style={{"fontSize":"2em"}} icon="car-alt-3"/>
					</div>
					<br />
					<h4>Naloženo</h4>
					<span style={{"fontSize":"120%"}}>Kurýr již naložil vaší objednávku</span>
					</>
				)

				case "Doručeno": 
				
				return (
					<>
					<div className="track-order-circle filled mx-auto">
						<Icofont style={{"fontSize":"2em"}} icon="tick-boxed"/>
					</div>
					<br />
					<h4>Doručeno</h4>
					<span style={{"fontSize":"120%"}}>Objednávka byla doručena</span>
					</>
				)

				case "Nezaplaceno": return (
					<>
					<div className="track-order-circle filled mx-auto">
						<Icofont style={{"fontSize":"2em"}} icon="close"/>
					</div>
					<br />
					<h4>Chyba</h4>
					<span style={{"fontSize":"120%"}}>Obědnávka nebyla potvrzena v systému. <br></br> Neproběhla platba nebo došlo k chybě.</span>
					</>
				)

				case "Stornováno": return (
					<>
					<div className="track-order-circle filled mx-auto">
						<Icofont style={{"fontSize":"2em"}} icon="close"/>
					</div>
					<br />
					<h4>Zrušeno</h4>
					<span style={{"fontSize":"120%"}}>Objednávka byla v systému stornována</span>
					</>
				)

				case "Potvrzeno": return (
					<>
					<div className="track-order-circle filled mx-auto">
						<Icofont style={{"fontSize":"2em"}} icon="basket"/>
					</div>
					<br />
					<h4>Objednávka přijata</h4>
					<span style={{"fontSize":"120%"}}>Objednávka byla přijata do systému</span>
					</>
				)

				case "Odkliknuto": return (
					<>
					<div className="track-order-circle filled mx-auto">
						<Icofont style={{"fontSize":"2em"}} icon="chef"/>
					</div>
					<br />
					<h4>Objednávka přijata v restauraci</h4>
					<span style={{"fontSize":"120%"}}>Restaurace připravuje vaší objednávku</span>
					</>
				)


				default: return (
					<>
					<div className="track-order-circle filled mx-auto">
						<Icofont style={{"fontSize":"2em"}} icon="close"/>
					</div>
					<br />
					<h4>Chyba</h4>
					<span style={{"fontSize":"120%"}}></span>
					</>
				)
			}
			
		}
    
}

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export default TrackOrder;