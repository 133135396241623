import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types'; 
import {Link} from 'react-router-dom';
import {Row,Col,Container,Form,InputGroup,FormControl,Button,Image} from 'react-bootstrap';
import { FooterCityLinkItem } from './FooterCityLinkItem';
import {useContext} from 'react';
import {AppContext} from '../../context.js'
import { useParams } from 'react-router';
//import FontAwesome from './FontAwesome';
//import GooglePlayBadge from '../../resources/google-play-badge.png';
//import AppStoreBadge from '../../resources/app-store-badge.svg';
//import Icofont from 'react-icofont';

const Footer = (props) => {

		const {state, dispatch} = useContext(AppContext)

		let { id } = useParams();

		const [city_url, setCity_url] = useState("")

		useEffect(() => {
			if(window.location.pathname.split("/")[1]=="rozvoz-jidel")
			setCity_url(window.location.pathname.split("/")[2])
			else
			setCity_url("")
		}, [window.location.pathname])

    	return (
    		<>
			    <section className="section pt-5 pb-3 text-center bg-grey">
			         <Container>
			            <Row>
							<Col sm={12}>
			                  <h5 className="m-0">Máte zájem o práci kurýra v super kolektivu? <Link to={"/stan-se-kuryrem"}>Přidejte se k nám!</Link></h5>
			               </Col>
			            </Row>
			         </Container>
			    </section>
				<section className="section pt-3 pb-5 text-center bg-white">
			         <Container>
			            <Row>
			               <Col sm={12}>
			                  <h5 className="m-0">Provozujete restauraci nebo chcete vozit jiné zboží? <Link to={"/kontakt-spoluprace"}>Pojďte do toho s námi!</Link></h5>
			               </Col>
			            </Row>
			         </Container>
			    </section>
			    <section className="footer pt-5 pb-5">
			         <Container>
			            <Row>
			               <Col md={6} sm={12}>
						   <h5 className="mb-3">Jídlo pod nos v novém!</h5>

						   <p>Dejte nám vědět, jak Vám prezentace vyhovuje na <a href="mailto:obchod@foodipsum.cz"><b>obchod@foodipsum.cz</b></a><br></br> Děkujeme</p>

			                  {/* <h6 className="mb-3">Odebírejte naše novinky!</h6>
			                  <Form className="newsletter-form mb-1">
			                     <InputGroup className="mb-3">
								    <FormControl
								       type="text" 
								       placeholder="Please enter your email" 
								    />
								    <InputGroup.Append>
								      <Button type="button" variant="primary">Odebírat</Button>
								    </InputGroup.Append>
								 </InputGroup>
			                  </Form>
			                  <p><Link className="text-info" to="register">Registrujte se nyní</Link>, abyste byli informováni o <Link to="offers">Nabídkách a Kuponech</Link></p>
			                  <div className="app">
			                     <p className="mb-2">Stáhnout aplikaci</p>
			                     <Link to="#">
			                     	<Image className="footer-app-image" src={GooglePlayBadge} alt='' fluid />
			                     </Link>
			                     <Link to="#">
			                     	<Image className="footer-app-image" src={AppStoreBadge} alt='' fluid />
			                     </Link>
								 
			                  </div> */}
			               </Col>
			               {/* <Col md={1} sm={6} className="mobile-none">
			               </Col> */}
						   
			               <Col sm={4} xs={12}>
			                  <h6 className="mb-3">O nás</h6>
			                  <ul>
							  	<li><Link to={`/kontakt-rozvoz-jidel/${state?.location?.mesto_url||city_url||""}`}>Kontakt</Link></li>
			                     <li><Link to="/podminky-uziti-stranek">Podmínky užití stránek</Link></li>
			                    
			                  </ul>
							  <br></br>
							  <h6 className="mb-3">Pro zákazníky</h6>
			                  <ul>
							  	<li><Link to="/seznam-alergenu">Seznam alergenů</Link></li>
								<li><Link to={`/vseobecne-obchodni-podminky/${state?.location?.mesto_url||city_url||"jidlopodnos"}`}>Všeobecné obchodní podmínky</Link></li>
								{/* <li><Link to="#">Blog</Link></li>
								<li><Link to="#">Careers</Link></li>
								<li><Link to="#">Contact</Link></li> */}
			                  </ul>
			               </Col>
						   <Col sm={2} xs={12}>
						   <a href="https://www.twisto.cz/pay/"><img style={{"marginTop":"20px"}} width="150px" border="0" src="https://www.jidlopodnos.cz/data/soubory/twisto_pay.png" /></a>
						   <br></br>
						   <a href="https://www.gopay.com/cs/"><img  style={{"marginTop":"30px"}} width="100px" border="0" src="https://account.gopay.com/download/banner-4-max.png?_ga=1.158163450.1106432113.1406747801" /></a>
						   </Col>
						  <Row className="w-100 mt-5 p-2">
							<FooterCityLinkItem cityName={"Karlovy Vary"} url={"karlovy-vary"}/>
							<FooterCityLinkItem cityName={"Teplice"} url={"teplice"}/>
							<FooterCityLinkItem cityName={"Ustí nad Labem"} url={"usti-nad-labem"}/>
							<FooterCityLinkItem cityName={"Mělník"} url={"melnik"}/>
							<FooterCityLinkItem cityName={"Sokolov"} url={"sokolov"}/>
							{/* <FooterCityLinkItem cityName={"Děčín"} url={"decin"}/> */}
							<FooterCityLinkItem cityName={"Cheb"} url={"cheb"}/>
							{/* <FooterCityLinkItem cityName={"Česká Lípa"} url={"ceska-lipa"}/> */}
							<FooterCityLinkItem cityName={"Litoměřice"} url={"litomerice"}/>
							<FooterCityLinkItem cityName={"Louny"} url={"louny"}/>
							<FooterCityLinkItem cityName={"Kolín"} url={"kolin"}/>
							<FooterCityLinkItem cityName={"Chodov"} url={"chodov"}/>
							<FooterCityLinkItem cityName={"Roudnice nad Labem"} url={"roudnice-nad-labem"}/>
							<FooterCityLinkItem cityName={"Žatec"} url={"zatec"}/>
							{/* <FooterCityLinkItem cityName={"Ostrava"} url={"ostrava"}/> */}
							<FooterCityLinkItem cityName={"Opava"} url={"opava"}/>
							<FooterCityLinkItem cityName={"Olomouc"} url={"olomouc"}/>
							{/* <FooterCityLinkItem cityName={"Prostějov"} url={"prostejov"}/> */}
							{/* <FooterCityLinkItem cityName={"Šternberk"} url={"sternberk"}/> */}
							<FooterCityLinkItem cityName={"Kralupy nad Vltavou"} url={"kralupy-nad-vltavou"}/>
							<FooterCityLinkItem cityName={"Rakovník"} url={"rakovnik"}/>
							<FooterCityLinkItem cityName={"Slaný"} url={"slany"}/>
							<FooterCityLinkItem cityName={"Česká Lípa"} url={"ceska-lipa"}/>
							<FooterCityLinkItem cityName={"Kladno"} url={"kladno"}/>
							{/* <FooterCityLinkItem cityName={"Lysá nad Labem"} url={"lysa-nad-labem"}/> */}
							<FooterCityLinkItem cityName={"Nové Strašecí - Tuchlovice"} url={"slany"}/>
							<FooterCityLinkItem cityName={"Kadaň"} url={"kadan"}/>
							<FooterCityLinkItem cityName={"Turnov"} url={"turnov"}/>
							<FooterCityLinkItem cityName={"Nymburk"} url={"nymburk"}/>
							<FooterCityLinkItem cityName={"Poděbrady"} url={"podebrady"}/>
							<FooterCityLinkItem cityName={"Rumburk"} url={"rumburk"}/>
							<FooterCityLinkItem cityName={"Varnsdorf"} url={"varnsdorf"}/>
							<FooterCityLinkItem cityName={"Benešov"} url={"benesov"}/>
							<FooterCityLinkItem cityName={"Aš"} url={"as"}/>
						  </Row>
			               {/* <Col sm={6} xs={12}>
						   <h6 className="mb-3">Kam vozíme</h6>
							  
			                  <ul >
							  	<li><Link to="karlovy-vary" to/>Karlovy Vary</li>
								<li><Link to="teplice" to/>Teplice</li>
								<li><Link to="usti-nad-labem" to/>Ústí nad Labem</li>
								<li><Link to="melnik" to/>Mělník</li>
								<li><Link to="sokolov" to/>Sokolov</li>
								<li><Link to="decin" to/>Děčín</li>
			                  </ul>
			               </Col>
			               <Col sm={6} xs={12} className="m-none">
						   <h6 className="mb-3">&nbsp;</h6>
							  <ul>
							  	<li><Link to="most" to/>Most</li>
							  	<li><Link to="chomutov" to/>Chomutov</li>
								<li><Link to="mlada-boleslav" to/>Mladá Boleslav</li>
								<li><Link to="kladno" to/>Kladno</li>
								<li><Link to="jablonec" to/>Jablonec</li>
								<li><Link to="brno" to/>Brno</li>
								<li></li>
								<Link to="https://www.gopay.com/cs/"><img width="100px" border="0" src="https://account.gopay.com/download/banner-4-max.png?_ga=1.158163450.1106432113.1406747801" /></Link>
			                  </ul>
			               </Col> */}
			            </Row>
			         </Container>
			    </section>
		      
		      <footer className="pt-4 pb-4 text-center">
		         <Container>
					{state.notification_token &&  <p className="mt-0 mb-0">Upozornění o změnách objednávky zapnuté</p>}
		            <p className="mt-0 mb-0">© Copyright {new Date().getFullYear()} Jídlopodnos</p>
		         </Container>
		      </footer>
		    </>
    	);
    }




Footer.defaultProps = {
    sectionclassName: 'footer-bottom-search pt-5 pb-5 bg-white',
	popularCHclassName:'text-black',
}



export default Footer;