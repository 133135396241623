import React from 'react'
import {Image, Container, Col, Row} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import creditHelp1 from '../resources/CreditHelp/step1.png'
import creditHelp2 from '../resources/CreditHelp/step2.png'
import creditHelp3 from '../resources/CreditHelp/step3.png'
import useWindowSize from '../useWindowsize';

const HowToUseCredits = () => {

    let size = useWindowSize()

    return (
        <>
            <Container className="howToUseCredits" style={{"paddingTop":"110px"}}>
                <Col>
                    <h1 className="text-center">Čerpání kreditů Jídlo pod nos</h1>
                    <h4 className="text-center text-danger">Jak čerpat vyhrané, koupené nebo darované kredity?</h4> 
                    <p className="text-center">Je to jako vždy jednoduché. Základním předpokladem je, abyste byli registrováni a přihlášení. Registrace je triviální, stačí zadat svůj mail a zvolit si heslo. Heslo zadáte ještě jednou pro kontrolu. Poté jen potvrdíte svůj účet na mailu který vám byl zaslán a zpět na stránce se přihlásíte. Můžete také zvolit možnost přihlášení přes facebook.</p>
                </Col>
                <Col className="text-center mb-3">
                    <Image className={size.width >= 767.98 ? 'w-50':'w-100'} src={creditHelp1}/>
                </Col>
                <Col>
                    <p className="text-center">Po přihlášení se vám zpřístupní složka "Můj profil". Tam po kliknutí naleznete 5 možností, včetně třetí možnosti označené kredity na tu klikněte.</p>
                </Col>
                <Col className="text-center mb-3">
                    <Image className={size.width >= 767.98 ? 'w-50':'w-100'} src={creditHelp2}/>
                </Col>
                <Col>
                    <p className="text-center">Po kliknuití na možnost kredity budete přesunuti na stránku kde naleznete políčko pro zadání kreditů které jste vyhráli, koupili nebo obdrželi jako dárek. Po zadání kreditů a stisknutí tlačítka NAHRÁT KREDITY, vám budou kredity přičteny na váš účet, pak už jen stačí v objednávce zatrhnou způsob platby kredity a máte oběd zaplacený.</p>
                </Col>
                <Col className="text-center mb-3">
                    <Image className={size.width >= 767.98 ? 'w-50':'w-100'} src={creditHelp3}/>
                </Col>
                <Link to={`/mujucet/kredity`}><h5 className="text-center text-danger text-decoration-underline"><u>Jít zpět.</u></h5></Link>
            </Container>
        </>
    )
}

export default HowToUseCredits
