import React from 'react'
import Icofont from 'react-icofont';
import {Link} from 'react-router-dom';
import {Col,Button, Table} from 'react-bootstrap';
import { floor } from 'lodash';
import {useContext} from 'react';
import {AppContext} from '../../context.js'
import moment from 'moment';
   



const DetailRestaurantInfo = (props) => {

	const {info} = props
	const {state, dispatch} = useContext(AppContext)

	let minimal_order = (state?.activeRestaurant?.minimal_order || 0) < (state?.location?.minimalni || 0) ? (state?.location?.minimalni || 0) : (state?.activeRestaurant?.minimal_order || 0)

	if(!info)
	return (<h4 className="mt-0 mb-2 col-md-12 order-big-card-title"> Načítání</h4>)

    return (
        <div id="restaurant-info" className="bg-white rounded shadow-sm p-4 mb-4">
			
			<div className="restaurant-info-parent">
				<div className="restaurant-info-parent-left">
					<h5 className="mb-3 restaurant-info-title">Informace o restauraci</h5>
					<h6 className="restaurant-info-name">{info.name}</h6>
					{info?<Table className="restaurant-info-table">
						<tbody>
						<tr>
							<td className="restaurant-info-table-icon"><Icofont icon="location-pin"/></td>
							<td className="restaurant-info-table-text"><span>{info.zip} {info.city} {info.street}</span></td>
						</tr>
						<tr>
							<td className="restaurant-info-table-icon"></td>
							<td className="restaurant-info-table-text"><span>{info.delivery_from?.slice(0,5)} - {info.delivery_to?.slice(0,5)} </span></td>
						</tr>
						<tr>
							<td className="restaurant-info-table-icon"><Icofont icon="wall-clock"/></td>
							<td className="restaurant-info-table-text"><span>Čas dodání <strong>{info.delivery_tmin+(Number(state?.location?.cas || 0))} - {info.delivery_tmax+(Number(state?.location?.cas || 0))}min</strong></span></td>
						</tr>
						<tr>
							<td className="restaurant-info-table-icon"><Icofont icon="price"/></td>
							<td className="restaurant-info-table-text"><span>Minimální cena objednávky {state?.location ? "":"od "} <strong>{info && (info.minimal_order > minimal_order ? info.minimal_order : minimal_order)} Kč</strong></span></td>
						</tr>
						<tr>
							<td className="restaurant-info-table-icon"><Icofont icon="fast-delivery"/></td>
							<td className="restaurant-info-table-text"><span>Cena rozvozu <strong>{state?.location ? "":"od "} {info?.delivery_price + Number(info?.vlastni == "a" ? 0 : Number(state?.location?.doprava || 0))} Kč</strong></span></td>
						</tr>
						{info?.isBonus ==1 && 						
						<tr>
							<td className="restaurant-info-table-icon"><Icofont icon="coins"/></td>
							<td className="restaurant-info-table-text"><span>Bonusový program <strong>vrátíme Vám {floor(info.percentage_bonus)}%</strong></span></td>
						</tr>}

						{info?.isFreeDelivery ==1 && (!state.location || state.location.nofreedelivery !== "a") &&
						<tr>
							<td className="restaurant-info-table-icon"><Icofont icon="free-delivery"/></td>
							<td className="restaurant-info-table-text"><span>Rozvoz zdarma nad <strong>{info.delivery_free} Kč</strong></span></td>
						</tr>}
						
						<tr>
							<td className="restaurant-info-table-icon"><Icofont icon="credit-card"/></td>
							<td className="restaurant-info-table-text"><span>Platba kartou <strong>{info.Cashless == 0 ? "NE" : "ANO"}</strong></span></td>
						</tr>
						<tr>
							<td className="restaurant-info-table-icon"><Icofont icon="ticket"/></td>
							<td className="restaurant-info-table-text"><span>Platba stravenkami <strong>{info.isVoucher == 0 ? "NE" : "ANO"}</strong></span></td>
						</tr>
						</tbody>
					</Table>:
					<Col md={12} className="text-center load-more" style={{"marginTop":"50px"}}>
					<Button variant="primary" type="button" disabled="">
						Chyba v připojení k serveru
					</Button>  
			  		 </Col>}

				</div>
				
				<div className="restaurant-info-parent-right pl-3">
				{info.gps_x && info.gps_y && (
					<div className="address-map">
						<div className="mapouter">
							<div className="gmap_canvas">
								<iframe title='addressMap' style={{float:"right",border:"1px solid grey"}} width="600" height="250" id="gmap_canvas" src={"https://maps.google.com/maps?q="+info.gps_x+","+info.gps_y+"&t=&z=13&ie=UTF8&iwloc=&output=embed"} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
							</div>
						</div>
					</div>
				)}
					{/* <br></br> */}
					<span>{info.desc}</span>
					<br/>
					<h5 className="mt-3">Doručovací hodiny:</h5>
					{ info.delivery_from_monday && <p className="mb-0">Po: {moment(info.delivery_from_monday,"hh:mm:ss").format("HH:mm")} - {moment(info.delivery_to_monday,"hh:mm:ss").format("HH:mm")}</p>}
					{ info.delivery_from_tuesday && <p className="mb-0">Út: {moment(info.delivery_from_tuesday,"hh:mm:ss").format("HH:mm")} - {moment(info.delivery_to_tuesday,"hh:mm:ss").format("HH:mm")}</p>}
					{ info.delivery_from_wednesday && <p className="mb-0">St: {moment(info.delivery_from_wednesday,"hh:mm:ss").format("HH:mm")} - {moment(info.delivery_to_wednesday,"hh:mm:ss").format("HH:mm")}</p>}
					{ info.delivery_from_thursday && <p className="mb-0">Čt: {moment(info.delivery_from_thursday,"hh:mm:ss").format("HH:mm")} - {moment(info.delivery_to_thursday,"hh:mm:ss").format("HH:mm")}</p>}
					{ info.delivery_from_friday && <p className="mb-0">Pa: {moment(info.delivery_from_friday,"hh:mm:ss").format("HH:mm")} - {moment(info.delivery_to_friday,"hh:mm:ss").format("HH:mm")}</p>}
					{ info.delivery_from_saturday && <p className="mb-0">So: {moment(info.delivery_from_saturday,"hh:mm:ss").format("HH:mm")} - {moment(info.delivery_to_saturday,"hh:mm:ss").format("HH:mm")}</p>}
					{ info.delivery_from_sunday && <p className="mb-0">Ne: {moment(info.delivery_from_sunday,"hh:mm:ss").format("HH:mm")} - {moment(info.delivery_to_sunday,"hh:mm:ss").format("HH:mm")}</p>}
				</div>
				

			</div>
			
			
		</div>
    )
}

export default DetailRestaurantInfo
