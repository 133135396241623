import React from 'react';
import { Container,Col } from 'react-bootstrap';
import CityLink from './CityLink';
import useWindowSize from '../../useWindowsize';

const WhereAreWe = () => {

    const size = useWindowSize();	
    return (
        <div className="where-are-we-parent ">
            
                <Container>
                    <span className="where-are-we-title" ><ion-icon className="where-are-we-title-icon" name="location-sharp"/>Kde už nás najdete</span>
                    <div className="mt-4">
                        <CityLink city_url="karlovy-vary" text="Karlovy Vary"/>
                        <CityLink city_url="teplice" text="Teplice"/>
                        <CityLink city_url="usti-nad-labem" text="Ústí nad Labem"/>
                        <CityLink city_url="melnik" text="Mělník"/>
                        <CityLink city_url="sokolov" text="Sokolov"/>
                        {/* <CityLink city_url="decin" text="Děčín"/> */}
                        
                        <CityLink city_url="cheb" text="Cheb"/>
                        <CityLink city_url="litomerice" text="Litoměřice"/>
                        <CityLink city_url="louny" text="Louny"/>
                        <CityLink city_url="kolin" text="Kolín"/>
                        <CityLink city_url="chodov" text="Chodov"/>
                        <CityLink city_url="roudnice-nad-labem" text="Roudnice nad Labem"/>
                        <CityLink city_url="zatec" text="Žatec"/>
                        {/* <CityLink city_url="ostrava" text="Ostrava"/> */}
                        {/* <CityLink city_url="opava" text="Opava"/> */}
                        {/* <CityLink city_url="prostejov" text="Prostějov"/> */}
                        {/* <CityLink city_url="olomouc" text="Olomouc"/> */}
                        {/* <CityLink city_url="sternberk" text="Šternberk"/> */}
                        <CityLink city_url="kralupy-nad-vltavou" text="Kralupy nad Vltavou"/>
                        <CityLink city_url="slany" text="Slaný"/> 
                        <CityLink city_url="rakovnik" text="Rakovník"/>
                        <CityLink city_url="ceska-lipa" text="Česká Lípa"/>
                        <CityLink city_url="kladno" text="Kladno"/>
                        {/* <CityLink city_url="lysa-nad-labem" text="Lysá nad Labem"/> */}
                        <CityLink city_url="slany" text="Nové Strašecí - Tuchlovice"/>
                        <CityLink city_url="kadan" text="Kadaň"/>
                        <CityLink city_url="turnov" text="Turnov"/>

                        <CityLink city_url="nymburk" text="Nymburk"/>
                        <CityLink city_url="podebrady" text="Poděbrady"/>
                        <CityLink city_url="rumburk" text="Rumburk"/>
                        <CityLink city_url="varnsdorf" text="Varnsdorf"/>
                        <CityLink city_url="benesov" text="Benešov"/>
                        <CityLink city_url="as" text="Aš"/>

                    </div>    

                </Container>
           
        </div>
    )
}

export default WhereAreWe
