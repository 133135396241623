import React, {useEffect} from 'react';
import useFetchARefresh from '../../useFetchAutoRefresh'
import {useParams} from 'react-router-dom';
import { CircularProgressbar, buildStyles  } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


export const CityUtilityMeter = (props) => {
     
    //const {data: cityCapacity,loading: loadingCityCapacity,error:errorCityCapacity} = useFetchARefresh('/api/menu/cityUtility/'+id)
    let cityCapacity = props.cityCapacity && props.cityCapacity !=undefined? props.cityCapacity.cityUtility : 5

    return (
      <>
      <div style={{ width: 100, height: 100 }}>
        <CircularProgressbar strokeWidth={10} value={cityCapacity} text={`${cityCapacity}%`} 
        styles={buildStyles({
          // Rotation of path and trail, in number of turns (0-1)
          rotation: 0.75,
      
          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          strokeLinecap: 'butt',
      
          // Text size
          textSize: '20px',
          pathTransitionDuration: 0.5,

      
          // Colors
          pathColor: `rgba(198, 39, 39,1)`,
          textColor: '#fff',
          trailColor: '#fff'
        })}
         />
      </div>
      
        {/* <div id="cont" data-pct="100">
        <svg id="svg" width="200" height="100" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <circle r="90" cx="100" cy="100" fill="transparent" stroke-dasharray="565.48" stroke-dashoffset="0"></circle>
          <circle id="bar" r="90" cx="100" cy="100" fill="transparent" stroke-dasharray="565.48" stroke-dashoffset="0"></circle>
          <rect y="100" width="200" height="100" style={{"fill":"rgb(255,255,255)","strokeWidth":"3","stroke":"rgb(0,0,0)"}} />
        </svg>
        </div> */}
      </>
    );
  }
  



export default CityUtilityMeter;