import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Navbar, Nav, Container, NavDropdown, Image, Button } from 'react-bootstrap';
import DropDownTitle from '../common/DropDownTitle';
import CartDropdownHeader from '../cart/CartDropdownHeader';
import Icofont from 'react-icofont';
import logo from '../../resources/logo.svg';
import Login from '../custom/Login';
import Register from '../custom/Register';
import CartItems from '../custom/CartItems';
import { AppContext } from '../../context.js'
import FacebookLogin_app from '../custom/FacebookLogin_app';
import axios from 'axios'
import google_analytics from '../../models/google_analytics'

class Header extends React.Component {

	static contextType = AppContext
	constructor(props) {
		super(props);
		this.state = {
			isNavExpanded: false
		};
	}

	analyticsMenuButtonClick = (value) => {
		//include the rest of your own code


		//push card-click event along with id to dataLayer to consume in GTM
		window.dataLayer.push({
			'menu_button_text': value,
			'event': 'menu_buttons_click'
		});

	}


	setIsNavExpanded = (isNavExpanded) => {
		this.setState({ isNavExpanded: isNavExpanded });
	}
	closeMenu = () => {
		this.setState({ isNavExpanded: false });
	}

	handleClick = (e) => {
		if (this.node.contains(e.target)) {
			// if clicked inside menu do something
		} else {
			// If clicked outside menu, close the navbar.
			this.setState({ isNavExpanded: false });
		}
	}



	componentDidMount() {
		document.addEventListener('click', this.handleClick, false);


	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleClick, false);
	}
	render() {
		const { state, dispatch } = this.context
		const logout = () => {
			axios({
				method: 'delete',
				url: '/api/auth',
			}).then(response => {
				dispatch({ type: 'LOGOUT' })
			})
		}
		return (
			<div ref={node => this.node = node}>
				<Navbar onToggle={this.setIsNavExpanded}
					expanded={this.state.isNavExpanded} color="light" expand='lg' className="navbar-light osahan-nav">
					<Container fluid style={{ "max-width": "1292px", "width": "80%" }}>
						<Link to={state.location ? `/rozvoz-jidel/${state.location.mesto_url}` : "/"} className="menu-logo" onClick={() => { this.analyticsMenuButtonClick("Logo") }}>
							<Navbar.Brand onClick={()=>window.scrollTo({top: 0, behavior: 'smooth'})} className="analytics-header-menu-button" analyticsValue="Logo" to={state.location ? `/rozvoz-jidel/${state.location.mesto_url}` : "/"} ><Image src={"https://www.jidlopodnos.cz/data/soubory/logo_navbar.svg"} style={{ height: 55 }} alt='' /></Navbar.Brand>
						</Link>

						{/* 		<Button variant="warning" className="mr-3"><Icofont icon='basket'/>Košík</Button> */}
						<Navbar.Toggle />

						<Navbar.Collapse id="navbarNavDropdown">
							<Nav activeKey={0} className="ml-auto" onSelect={this.closeMenu}>
								{state.location &&
									<Nav.Link href={`/rozvoz-jidel/${state.location.mesto_url}`} onClick={() => { this.analyticsMenuButtonClick("Adresa") }}>
										{/* Oblast: {state.location.mesto} */}
										{state.location?.adresa?.name || state.location?.adresa?.string}
									</Nav.Link>
								}
								<Nav.Link eventKey={0} as={NavLink} onClick={() => { this.analyticsMenuButtonClick("Domů") }} activeclassname="active" exact to={state.location ? `/rozvoz-jidel/${state.location.mesto_url}` : "/"}>
									Domů <span className="sr-only">(current)</span>
								</Nav.Link>


								{state.user ?
									<Link onClick={() => { this.analyticsMenuButtonClick("Můj účet") }}>
										<NavDropdown alignRight className="dropdown-cart"
											title={
												<DropDownTitle
													className='d-inline-block '
													type="user"
													imageClass="nav-osahan-pic rounded-pill"
													title='Můj účet'



												/>

											}
										>
											<div className="dropdown-account">

												{/* <NavDropdown.Item eventKey={4.1} as={NavLink} activeclassname="active" to="/mujucet/statistiky"><Icofont icon='chart-bar-graph'/> Statistiky</NavDropdown.Item> */}
												<NavDropdown.Item eventKey={4.1} as={NavLink} activeclassname="active" to="/mujucet/objednavky"><Icofont icon='food-cart' /> Objednávky</NavDropdown.Item>
												<NavDropdown.Item eventKey={4.3} as={NavLink} activeclassname="active" to="/mujucet/oblibene"><Icofont icon='heart' /> Oblíbené</NavDropdown.Item>
												<NavDropdown.Item eventKey={4.1} as={NavLink} activeclassname="active" to="/mujucet/kredity"><Icofont icon='upload-alt' /> Kredity</NavDropdown.Item>
												{/* <NavDropdown.Item eventKey={4.4} as={NavLink} activeclassname="active" to="/mujucet/platby"><Icofont icon='credit-card'/> Způsoby plateb</NavDropdown.Item> */}
												<NavDropdown.Item eventKey={4.5} as={NavLink} activeclassname="active" to="/mujucet/adresy"><Icofont icon='location-pin' /> Adresy</NavDropdown.Item>
												<NavDropdown.Item eventKey={4.6} as={NavLink} activeclassname="" to="/#" onClick={logout}><Icofont icon='logout' /> Odhlásit se</NavDropdown.Item>

											</div>
										</NavDropdown>
									</Link>
									: ""}


								{false ?
									<Link to="" />
									:
									state.cart.length > 0 ?
										<NavDropdown activeclassname="active" id="toggleCartRef" alignRight className="dropdown-cart pb-0 d-none d-none d-lg-block"
											onClick={() => google_analytics.view_cart(state.cart, state.activeRestaurant)}
											title={
												<DropDownTitle
													className='d-inline-block analytics-header-menu-button'
													faIcon='shopping-basket'
													iconClass='mr-1'
													title='Košík'
													badgeClass='ml-1'
													badgeVariant='danger'
													badgeValue={state.cart.length}
													analyticsValue="Košík"
												/>
											}
										>

											<div className="dropdown-cart-top order-cart pb-4 pl-4 pr-4 ">

												<div className="dropdown-cart-top-header pt-4">
													{
														state.activeRestaurant ?
															<img alt="Restaurace" src={`https://www.jidlopodnos.cz/data/restaurace/mkartan/${state.activeRestaurant.id}.jpg`} onError={(e) => { e.target.onerror = null; e.target.style.display = "none" }} className="img-fluid mr-3" /> : ''
													}
													<h6 className="mb-0 cart-dropdown-title">Vaše objednávka</h6>
													{
														state.activeRestaurant ?
															<p className="mb-0 cart-dropdown-subtitle">{state.activeRestaurant.name}</p> : ''
													}
												</div>
												<div className="dropdown-cart-top-body cartItems-wrap">
													<CartItems />
												</div>
												<div className="order-cart-added-item">
													<p className="mb-0 order-cart-added-item-price">{state.total.packaging} Kč</p>
													<p className="mb-0 order-cart-added-item-text">Obaly</p>
												</div>
												<div className="order-cart-added-item">
													<p className="mb-0 order-cart-added-item-price">{state.total.deliveryPrice && state.total.deliveryPrice + " Kč" || "zdarma"}</p>
													<p className="mb-0 order-cart-added-item-text">Doprava</p>
												</div>
												<div className="order-cart-price-total">
													<p className="mb-0 order-cart-price-total-price">{state.total.totalPrice} Kč</p>
													<p className="mb-0 order-cart-price-total-text">Celkem</p>
												</div>

												<Link to={"/rozvoz-jidel/" + state.activeRestaurant.mesto_url + "/" + state.activeRestaurant.rest_url + "/" + "platba"} onClick={() => { document.getElementById("toggleCartRef").click() }} className="mt-4 order-cart-submit" >Objednat</Link>

											</div>
										</NavDropdown> : ''}
								{state.activeRestaurant ?
									<Nav.Link className="d-lg-none" href={"/rozvoz-jidel/" + state.activeRestaurant.mesto_url + "/" + state.activeRestaurant.rest_url + "/" + "platba"}>
										<Icofont icon='basket' size='1' /> Košík
									</Nav.Link>
									:
									<Nav.Link className="d-lg-none">
										<Icofont icon='basket' size='1' /> Košík (prázdný)
									</Nav.Link>
								}
								
								{!state.user ?
									<>
										<Login />
										<Register />

										{state.platform == "android" ?
										<FacebookLogin_app /> 
										:
										<Nav.Link href="/api/auth/facebook">
										Přihlášení přes Facebook <Icofont icon='facebook' size=''/>
										</Nav.Link>
										}

										{/*  window.capacitor?.platform == "android"*/}

							

									</>
									: ""
								}
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>
			</div>
		);
	}
}



export default Header;