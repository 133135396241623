import React, {useState} from 'react'
import {Form,Button,Container} from 'react-bootstrap';

const Collaboration = () => {
    const [resp, setResp] = useState('')
    const [sent, setSent] = useState(false)
    const [color, setColor] = useState('danger')
    const [values, setValues] = useState({
        companyName: '',
        representativeName: '',
        phone: '',
        email: '',
        delivery: false,
        city: '',
        address: '',
        companyFocus: '',
        additionalInfo: ''
    });
    const HandleChange = e => {
        const { name, value } = e.target;
        setValues({
          ...values,
          [name]: value
        });
      };

    const CheckBoxChange = (a) =>{
        setValues({...values,  delivery: a})
    }

    const HandleValidation = e => {
        setColor('danger')
        e.preventDefault();
        if (!values.companyName.trim()) return setResp("Název podniku je nevyplněný!");
        else if (!values.representativeName.trim()) return setResp("Jméno zástupce je nevyplněné!");
        else if (isNaN(values.phone) || values.phone.trim().length < 9 || values.phone.trim().length > 13) return setResp("Telefoní číslo je neplatný!");
        else if (!values.email || !/\S+@\S+\.\S+/.test(values.email)) return setResp("Email je neplatný!");
        else if (values.city.length <= 1) return setResp("Město je neplatný!");
        else if (values.address.length < 10) return setResp("Adresa je neplatná!");
        else if (!values.companyFocus.trim()) return setResp("Zaměření podniku je nevyplněný!");

        fetch(`./api/location/contact/workWithUs`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",  
            },
            body: JSON.stringify(values)
        }).then(res => {
            if (res.ok) {
                return res.json();
            }else{
                //data nepřišla
                setColor('danger');
                setResp("Chyba serveru.");
            }
        }).then(res => {
            if (res.status == "ok") {
                setColor('success');
                setResp(res.message);
                setSent(true);
            }else{
                setColor('danger');
                setResp(res.message);
            }
            
        })
        
    }

    return (
        <>
            <Container style={{"paddingTop":"110px"}}>
                <h2 className="text-center">Dejte nám o sobě vědět</h2>
                <p className="text-center">Do 24 hodin vás budeme kontaktovat</p>

                {resp && <div className="d-flex justify-content-center m-3"><div className={"p-3 bg-"+color+" text-white w-25 text-center rounded-pill"}>{resp}</div></div>}
                <Form className={sent && "d-none"} onSubmit={HandleValidation}>
                    <Form.Group className="mb-3" controlId="">
                        <Form.Label>Název podniku</Form.Label>
                        <Form.Control name='companyName' type="text" isValid={values.companyName.trim()} value={values.companyName} onChange={HandleChange}/>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="">
                        <Form.Label>Jméno zástupce</Form.Label>
                        <Form.Control name='representativeName' type="text" isValid={values.representativeName.trim()} value={values.representativeName} onChange={HandleChange}/>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="">
                        <Form.Label>Telefon</Form.Label>
                        <Form.Control name='phone' type="tel" isValid={!isNaN(values.phone) && values.phone.trim().length >= 9 && values.phone.trim().length <= 13} value={values.phone} onChange={HandleChange}/>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Mail</Form.Label>
                        <Form.Control name='email' type="email" isValid={values.email && /\S+@\S+\.\S+/.test(values.email)} value={values.email} onChange={HandleChange}/>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="">
                        <Form.Label>Rozvážíte si sami?</Form.Label>
                        {['radio'].map((type) => (
                            <div key={`inline-${type}`} className="mb-3">
                            <Form.Check
                                inline
                                label="Ano"
                                name="ano"
                                type={type}
                                checked={values.delivery}
                                onChange={() => {CheckBoxChange(!values.delivery)}}
                            />
                            <Form.Check
                                inline
                                label="Ne"
                                name="ne"
                                type={type}
                                checked={!values.delivery}
                                onChange={() => {CheckBoxChange(!values.delivery)}}
                            />
                            </div>
                        ))}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="">
                        <Form.Label>Město kde se podnik nachází</Form.Label>
                        <Form.Control name='city' type="text" isValid={values.city.length > 1} value={values.city} onChange={HandleChange}/>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="">
                        <Form.Label>Adresa podniku</Form.Label>
                        <Form.Control name='address'as="textarea" rows={3} type="text" isValid={values.address.length > 10} value={values.address} onChange={HandleChange}/>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="">
                        <Form.Label>Čím se váš podnik zabývá (příklad: Česká kuchyně, burgery, květiny, balónky)</Form.Label>
                        <Form.Control name='companyFocus' type="text" as="textarea" isValid={values.companyFocus.trim()} style={{ height: '100px' }} value={values.companyFocus} onChange={HandleChange}/>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="">
                        <Form.Label>Další informace</Form.Label>
                        <Form.Control name='additionalInfo' type="text" as="textarea" style={{ height: '80px' }} value={values.additionalInfo} onChange={HandleChange}/>
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Odeslat
                    </Button>
                </Form>
            </Container>
        </>
    )
}

export default Collaboration
