import React from 'react'
import {Link} from 'react-router-dom';
import { Col } from 'react-bootstrap';


export const FooterCityLinkItem = (props) => {

    let {cityName, url} = props

    return (
        <Col md={4} sm={6} xs={12}>
            <h5>{cityName}</h5>
            <p>
                <Link className="text-secondary analytics-footer-city-categories" onClick={()=>window.scrollTo(0, 0)} to={`/rozvoz-jidel/${url}?kategorie=Pizza`}>Pizza {cityName}</Link>, &nbsp;
                <Link className="text-secondary analytics-footer-city-categories" onClick={()=>window.scrollTo(0, 0)} to={`/rozvoz-jidel/${url}?kategorie=Burgery`}>Burgery {cityName}</Link>, &nbsp;
                <Link className="text-secondary analytics-footer-city-categories" onClick={()=>window.scrollTo(0, 0)} to={`/rozvoz-jidel/${url}?kategorie=Kebab`}>Kebab {cityName}</Link>, &nbsp;
                <Link className="text-secondary analytics-footer-city-categories" onClick={()=>window.scrollTo(0, 0)} to={`/rozvoz-jidel/${url}?kategorie=Sushi`}>Sushi {cityName}</Link>, &nbsp;
                <Link className="text-secondary analytics-footer-city-categories" onClick={()=>window.scrollTo(0, 0)} to={`/rozvoz-jidel/${url}?kategorie=Česká`}>Česká kuchyně {cityName}</Link>, &nbsp;
                <Link className="text-secondary analytics-footer-city-categories" onClick={()=>window.scrollTo(0, 0)} to={`/rozvoz-jidel/${url}?kategorie=Čína`}>Čína {cityName}</Link>,&nbsp;
                <Link className="text-secondary analytics-footer-city-categories" onClick={()=>window.scrollTo(0, 0)} to={`/rozvoz-jidel/${url}?kategorie=Indická`}>Indická {cityName}</Link>
            </p>
        </Col>
    )
}
