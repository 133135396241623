import React, { Component } from 'react';
import PropTypes from 'prop-types';
import google_analytics from '../../models/google_analytics'

const CheckoutItem = (props) => {

  const { nazev, popis, cena, quantity, extraItems } = props.item

  const IncrementItem = () => {
    let newItem = props.item
    newItem.quantity += 1
    /* props.dispatch({ type: 'UPDATE_ITEM', payload: {...props.item,quantity:quantity+1} }) */
    props.dispatch({ type: 'UPDATE_ITEM', payload: newItem })
  }
  const DecreaseItem = () => {
    let newItem = props.item
    if (newItem.quantity > 1) {
      newItem.quantity -= 1
      props.dispatch({ type: 'UPDATE_ITEM', payload: newItem })
    } else {
      props.dispatch({ type: 'DELETE_ITEM', payload: newItem })
      google_analytics.remove_from_cart(newItem, props.restaurant)
    }
  }



  return (
    <div className={`mt-1 order-cart-item ${props.canEditQty ? "addPadding mt-4" : ""}`}>
      <p className="mb-0 float-right ml-2 order-cart-price">{quantity ? cena * quantity : cena} Kč</p>
      <p className="mb-0 order-cart-item-text">{nazev}</p>



      {extraItems ? (extraItems.map((extraItem, index) => (

        <div key={index}>
          {extraItem.price > 0 &&
            <p className="mb-0 float-right ml-2 order-cart-price">{extraItem.price * quantity} Kč</p>
          }
          <p className="mb-0 order-cart-sub-item-text">+ {extraItem.name}</p>
        </div>

      ))) : ""}

      {props.canEditQty ?
        <>
          <div className="mt-1 cart-plus-button" onClick={IncrementItem}> + </div>
          <input className="mt-1 count-number-input float-right" type="text" value={quantity ? quantity : 1} readOnly />
          <div className="mt-1 cart-minus-button " onClick={DecreaseItem} > - </div>
        </> : ""}

    </div>
  );

}





export default CheckoutItem;