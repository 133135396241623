import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Row,Col,Container,Image, Form, Button, Spinner} from 'react-bootstrap';
import axios from 'axios'
import {useLocation} from "react-router-dom";
import useQuery from "../useQuery"


const ResetPassword = ()=>{

	const [resetPwdInput, setresetPwdInput] = useState("")
	const [resetPwdCheckInput, setresetPwdCheckInput] = useState("")
	const [loading, setLoading] = useState(true)
	const [tokenValid, setTokenValid] = useState(false)
	const [status, setStatus] = useState("loading")
	let query = useQuery();

	useEffect(() => {
		axios({
			method: 'post',
			url: '/api/auth/register/'+query.get("token")
		  }).then(response => {
			console.log(response)
			if(response.status===200){	
				setTokenValid(true)
				setStatus("done")
			}
			else{
				setStatus("invalid")
			}
				
		  }).catch(
			function (error) {
			  //console.log(error.response.status)
			  setStatus("invalid")
			}
		  )
	}, [])


    	return (
    		<section className="section pt-5 pb-5 osahan-not-found-page">
		         <Container>
		            <Row>
						<Col md={4} />
						{status == "loading" && (<Col><Spinner animation="border" role="status">
						<span className="sr-only mt-5">Načítání...</span>
						</Spinner></Col>)}

					   {status == "invalid" && (<Col md={4} className="text-center pt-5 pb-5"><h1 className="mt-2 mb-2">Tento odkaz už je neplatný</h1><br></br><h5></h5></Col>)}
					   {status == "done" && (<Col md={4} className="text-center pt-5 pb-5"><h1 className="mt-2 mb-2">Hotovo!</h1><br></br></Col>)}
		            </Row>
		         </Container>
		    </section>
    	);
    }
	



export default ResetPassword;