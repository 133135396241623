import React,{useEffect,useState} from 'react'
import {Col, Spinner, Button, Row, Container} from 'react-bootstrap';
import { Redirect, useParams } from "react-router-dom";
/* import AllRestaurants from './AllRestaurants'
import NewestRestaurants from './NewestRestaurants' */
import Restaurants from '../Restaurants.js'
import AllRestaurants from './AllRestaurants'
import useFetch from '../../useFetch.js'
import useFetchARefresh from '../../useFetchAutoRefresh'
import {useContext} from 'react';
import {AppContext} from '../../context.js'
    



const RestaurantOffers = (props) => {
    let { id } = useParams(); 
    const {state, dispatch} = useContext(AppContext)

    const {data: restaurants_data,loading,error} = useFetch('/api/restaurants?city='+id)

    const [restaurants, setRestaurants] = useState([])

    useEffect(() => { // Filtering a korekce restaurací pro vlastní rozvoz
        console.log("REST DATA:",restaurants_data)
        if(restaurants_data)
        resolveRestaurants()
    }, [restaurants_data,state.location])

    const resolveRestaurants = async ()=>{
        //const data = await fetch("https://api.jidlopodnos.cz/api.php/gpstest/50.236/12.877/")
        //const result = await data.json()

        console.log("Resolving restaurants")

        if(!state.location?.polygonRestaurants){
            console.log("NO polygon restaurants loaded")
            return setRestaurants(restaurants_data)
        }

        let polygonRest = state.location.polygonRestaurants

        let restaurants_filtered = []

        console.log("VLASTNI ROZVOZ CHECK:")

        restaurants_data.forEach(rest=>{
            if(rest.vlastni == "a"){
                console.log("CHECKING REST:",rest,polygonRest)

                let rest_found = polygonRest.find(r => r.restaurace_id == rest.id)
                let rest_override = {...rest}

                if(rest_found && (rest_found.mesto_id == state.location.mesto_id || state.location.mesto_url == "externi-oblast")){ //Includes restaurant
                    console.log("INCLUDED REST:",rest_override,rest_found)
                    //porovnat hodnoty proti polygon resultu
                    rest_override.delivery_price+= Number(rest_found.doprava)
                    rest_override.delivery_tmax += Number(rest_found.cas)
                    rest_override.delivery_tmin += Number(rest_found.cas)
                    rest_override.isFreeDelivery = rest_found.nofreedelivery ? 0 : rest_override.isFreeDelivery
                    
                    //
                    
                    restaurants_filtered.push(rest_override)
                }else{
                    console.log("REMOVED: ",rest)
                }

            }else{
                restaurants_filtered.push(rest)
            }

        })

        setRestaurants(restaurants_filtered)
    }



    if(error) return <Col md={12} className="text-center load-more" style={{"marginTop":"50px"}}>
                                <Button variant="primary" type="button" disabled="">
                                    Chyba v připojení k serveru
                                </Button>  
                           </Col>

    if (loading) return <Col md={12} className="text-center load-more">
                                <Button variant="primary" type="button" disabled="">
                                    <Spinner animation="grow" size="sm" className='mr-1' />
                                    Načítám...
                                </Button>  
                           </Col>

    return (
        <div className = "restaurant-offers">
            <AllRestaurants restaurants={restaurants} />       
        </div>
    )
}

export default RestaurantOffers


/*             <NewestRestaurants/>
            <AllRestaurants/> */
