import React from 'react'


export const HorizontalCategories = (props) => {
    let {menu} = props
    return (
        <div className=" pb-2 col-md-12 order-big-card-categories pl-0" style={{whiteSpace:"nowrap",overflow:"auto"}}>
					{menu && Object.keys(menu).map((category,index) => (category!="Polední menu"?<div key={index} onClick={()=>{scrollTo(category.split("~")[0] || category)}} className="order-big-card-single-category">{category.split("~")[0] || category}</div>:""))}
        </div>
    )
}




function scrollTo(id){
	try {
		const yOffset = -80; 
		const element = document.getElementById(id);
		const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
	
		window.scrollTo({top: y, behavior: 'smooth'});
	} catch (error) {
		console.log("Scroll to Error")
	}

}