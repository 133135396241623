import React, { useState,useEffect } from 'react'
import { Button } from 'react-bootstrap'
import Icofont from 'react-icofont'
import { Link } from 'react-router-dom'
import useWindowSize from '../useWindowsize'

export const Alerts = () => {
    const [toggleAlertCookies, setToggleAlertCookies] = useState(true)
    
	const size = useWindowSize();

    useEffect(() => {
       if(localStorage.getItem("disabledAlerts")?.includes("cookies"))
        setToggleAlertCookies(false) 
    }, [])



    if(size.width >= 767.98)
    return (
    <>
        {/* <a href="https://old.jidlopodnos.cz/"><Button style={{position:"fixed","bottom":45,"left":30,"background":"white","zIndex": 999,"padding":"10px"}}> <Icofont icon='arrow-right'/> Zpět na starý web</Button></a> */}
        
        {/* <p className="text-danger test-alert" style={{position:"fixed","bottom":45,"right":30,"background":"white","zIndex": 9999,"padding":"10px"}}>!Testovací verze Jídlopodnos, nepoužívejte pro skutečné objednávky!</p> */}
        
        {/* <p className={`text-danger test-alert ${!toggleAlertCookies && "d-none"}`} style={{position:"fixed","bottom":0,"right":30,"background":"white","zIndex": 9999,"padding":"10px"}}>Pokračováním v prohlížení naší stránky souhlasíte s využitím Cookies. Více informací najdete na stránce Zásady ochrany osobních údajů. <Icofont onClick={()=>{setToggleAlertCookies(false);localStorage.setItem('disabledAlerts', "cookies");}} icon="close"></Icofont></p> */}
        
    </>
    )
    else if(false && window.capacitor?.platform=="android") return (<><div className="alert alert-danger p-1" style={{"position":"absolute","top":"80px"}}><h6>Zaznamenáváme technické potíže při platbách kreditní kartou a přihlášení přes Facebook.</h6></div></>)
    else return (<></>)
}
