import React, {useContext, useState} from 'react'
import DropDownTitle from '../common/DropDownTitle';
import {NavDropdown,Form, Button,Modal} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import axios from 'axios'
import {AppContext} from '../../context.js'



const Login = () => {

	const context = useContext(AppContext)

	const [login, setLogin] = useState("")
	const [password, setPassword] = useState("")
	const [error, setError] = useState("")
	const [resetError, setResetError] = useState("")
	const [showResetPwd, setshowResetPwd] = useState(false)
	const [resetPwdInput, setresetPwdInput] = useState("")
	const [didEmailReset, setdidEmailReset] = useState(false)

	const handleLogin = (e) => {
		e.preventDefault()
		axios({
			method: 'post',
			url: '/api/auth',
			data: {
			  email: login,
			  password: password
			}
		  }).then(response => {
			if(!response.data.error)
				context.dispatch({type: 'LOADUSER', payload: response.data})
			else
				setError(response.data.error)
		  }).catch(error=>{
			setError(error)
		  })
	}

	const hideSetshowResetPwd = (e) => {
		setshowResetPwd(false)
	}
	const showSetshowResetPwd = (e) => {
		setshowResetPwd(true)
	}
	const handleResetPwd = (e) => {
		e.preventDefault()
		axios({
			method: 'post',
			url: '/api/auth/pswd_reset',
			data: {
			  email: resetPwdInput
			}
		  }).then(response => {
			console.log(response)
			if(response.status===200){	
				setdidEmailReset(response?.data?.message)
			}
			if(response.data.status="error"){
				setResetError(response.data?.message)
			}
			else
				alert("Omlouváme se. Došlo k systémové chybě. Pro obnovu hesla kontaktujte prosím administrátora.")
		  }).catch(error=>{
			alert("Omlouváme se. Došlo k systémové chybě. Pro obnovu hesla kontaktujte prosím administrátora.")
		  })
	}

    return (
		<>
		<Modal className="extra-items" show={showResetPwd}>
				
				<Modal.Body>
					
				  <Button className="extra-items-close-button" onClick={hideSetshowResetPwd}><ion-icon className="extra-items-close-button-icon" name="close"/></Button>
				  {!didEmailReset && (<>
				  <h5 className="extra-items-title">Obnovit heslo</h5>
		
				  <p className="extra-items-food-description mt-1">Pokud pro váš email exituje registrovaný účet, zašleme vám na něj odkaz pro obnovu hesla</p>
				  
				  
					  
					  
				  <Form onSubmit={handleResetPwd}>
						<div className="form-label-group">
							<Form.Control required onChange={e => setresetPwdInput(e.target.value)} type="email" id="resetPwdInput" placeholder="" value={resetPwdInput} />
							<Form.Label htmlFor="resetPwdInput">Váš email</Form.Label> 
						</div>
						<p className="text-danger">{resetError}</p>
  
					  <Button type="submit" className="extra-items-add-to-cart">Obnovit</Button>
					  
				  </Form>
				  
				  </>)}
				  {didEmailReset && (<>
				  <h5 className="extra-items-title">Hotovo</h5>
				  <br></br>
				  <p>{didEmailReset}</p>
				  </>)}
			  </Modal.Body>
  
		  </Modal>
        <NavDropdown activeclassname="active" alignRight className="dropdown-cart" id="loginRef"
			            	title={
			            		<DropDownTitle 
			            			className='d-inline-block' 
			            			title='Přihlásit se'
			            		/>
			            	}
			            >

			                <div className="dropdown-login">
							<Form onSubmit={handleLogin}>
	                              <div className="form-label-group">
	                                 <Form.Control required onChange={e => {setLogin(e.target.value); setresetPwdInput(e.target.value)}} type="email" id="inputEmail" placeholder="" />
	                                 <Form.Label htmlFor="inputEmail">Email / Mobil</Form.Label> 
	                              </div>
	                              <div className="form-label-group">
	                                 <Form.Control required onChange={e => setPassword(e.target.value)} type="password" id="inputPassword" placeholder="" />
	                                 <Form.Label htmlFor="inputPassword">Heslo</Form.Label>
	                              </div>
	                              <Form.Check  
	                              	className='mb-3'
							        custom
							        type="checkbox"
							        id="custom-checkbox"
							        label="Zapamatovat si heslo"
							      />
								  <p className="text-danger">{error}</p>
	                              <Button type="submit" className="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2 analytics-button-header">Přihlásit se</Button>
	                              <div className="text-center pt-3">
								  	Zapoměli jste heslo? <Link to="#" onClick={showSetshowResetPwd} className="font-weight-bold" >Obnovit heslo</Link>
	                              </div>
	                           </Form>

			                  
			                </div>
			            </NavDropdown>
						</>
    )
}

export default Login
