import React,{useState,useEffect} from 'react'
import { Col, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import axios from 'axios'
import useFetch from '../../useFetch'
import { update } from 'lodash';


const Credits = (props) => {

    const [creditInput, setCreditInput] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [addedCredits, setAddedCredits] = useState(null)
    const [update, setUpdate] = useState(false)

    //const {data: credits,loading: loadingCredits,error:errorCredits} = useFetch('/api/user/credits/',addedCredits) 
    const [credits, setCredits] = useState(null)
    const [creditsLoadError, setCreditsLoadError] = useState(false)

    useEffect(() => {
      
        axios({
            method: 'get',
            url: '/api/user/credits/',
          }).then(response => {
            console.log(response.data)
            
                setCredits(response.data)
           

          }).catch(error => {
            console.log(error.response)
            setCreditsLoadError(true)
            //	setError(error.response.data.message)
          })
    
      
    }, [update])
    


    const submitCredits = () => {
        if(creditInput.length==0){
            return setErrorMessage("Zadejte kuponový kod")
        }
        setErrorMessage("")
        axios({
            method: 'post',
            url: '/api/user/credits/'+creditInput,
          }).then(response => {
            console.log(response.data)
            if(response.data.status=="ok"){
                setErrorMessage("")
                setCreditInput("")
                setAddedCredits(response.data.credit)
                setUpdate(!update)
            }else{
                setErrorMessage(response.data.message || "Kupon není platný / byl už použitý")
            }

          }).catch(error => {
            console.log(error.response)
            setErrorMessage("Nepodařilo se kod ověřit")
            //	setError(error.response.data.message)
          })
    }
    return (
        <div className="p-4 bg-white shadow-sm ">
            <h4 className="font-weight-bold mt-0 mb-4">Kredity</h4>
            <Col className="my-account-credits" md={12}>
                <input  onChange={(e)=>{setCreditInput(e.target.value)}} value={creditInput} className="my-account-credits-input" type="text" placeholder="Vlož kód"/> <Button onClick={submitCredits} className="my-account-credits-button">NAHRÁT KREDITY</Button>
                {errorMessage && <><h6 className="homepage-search-description text-danger mt-3">{errorMessage} </h6><br></br></>}
                {addedCredits && <><h6 className="homepage-search-description text-success mt-3">Na účet bylo přidáno {addedCredits} kreditů</h6><br></br></>}

                <div className="my-account-credits-stats mt-4 mb-4"> 
                        {/*
                        {credits &&
                         <><p className="my-account-credits-stats-left" >Kredity použitelné ve všech restauracích <strong></strong></p><p className="my-account-credits-stats-right">{(credits?.credits_jpn+addedCredits)}</p></>
                        }
                        */}
                        {credits && !creditsLoadError && (credits?.map((credit) => (
                            <>
                                <p className="my-account-credits-stats-left" >{credit.name}</p><p className="my-account-credits-stats-right">{credit.sumApplicable}</p>
                            </>
                        )))}

                        {creditsLoadError && 
                        <span>Chyba, kredity se nepodařilo načíst.</span>
                        }

                        
                </div>


                <div className="my-account-credits-links">
                <Link to={`/cerpani-kreditu`}><span>Jak použít zaslaný kód?</span></Link><br/>
                    {/* <span>Prohlédněte si Vaše statistiky</span> */}
                </div>
            </Col>

        </div>
    )
}

export default Credits
