import React, { useState, useEffect } from 'react';
import OrderCard from '../common/OrderCard';
import useFetch from '../../useFetch.js'
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import axios from 'axios'


const Orders = () => {

	const [page, setPage] = useState(0);
	//const {data: orders,loading} = useFetch('/api/orders/userOrders?page='+page)
	const [orders, setOrders] = useState([])
	const [loading, setLoading] = useState(true)
	const [lastPage, setLastPage] = useState(false)

	useEffect(() => {
		axios({
			method: 'get',
			url: '/api/orders/userOrders?page=' + page
		}).then(response => {
			console.log(response)
			if (response.status === 200) {
				if (response.data.length < 5) {
					setLastPage(true)
				}

				setOrders([...orders, ...response.data])
				setLoading(false)
			}
			else
				alert("Načítání objednávek ze serveru se nepodařilo.")
		})
	}, [page])


	return (
		<>

			<div className='p-4 bg-white shadow-sm'>
				<h4 className="font-weight-bold mt-0 mb-4">Vaše objednávky</h4>
				{loading ?
					<Col md={12} className="text-center load-more">
						<Button variant="primary" type="button" disabled="">
							<Spinner animation="grow" size="sm" className='mr-1' />
							Načítání...
						</Button>
					</Col>
					:
					<>
						{orders.length > 0 ?
							<>
								{orders.map((order) => (
									<OrderCard
										key={order.order_id}
										order={order}
									/>


								))}
								{!lastPage ?
									<Col className="d-flex justify-content-center" md={12}>
										<Button onClick={() => setPage(page + 1)} className="" variant="info" type="button" disabled="">
											Načíst další...
										</Button>
									</Col>
									:
									<span className="text-secondary">To je vše...</span>
									
								}
							</>
							:
							<h6 className="text-secondary">Zdá se, že tu nic není...</h6>

						}

					</>
				}


			</div>
		</>
	);

}
export default Orders;