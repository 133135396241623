export const getTotal = (cart,activeRestaurant,totalAddition,location) => { 

    if(!cart || cart.length==0 || !activeRestaurant)
      return false
  
    let total = loadTotal(cart,activeRestaurant,totalAddition,location)
    
    return total
}
  
let loadTotal = (cart,activeRestaurant,totalAddition,location)=>{
    
    let foodPrice = 0
    let deliveryPrice = 0
    let packaging = 0
    let extraItemPackaging = 0
    try {
      for(const item of cart){
        let itemPrice = 0
        if(item.eshop==0){
  
          //Položka + obal + plusobal
          itemPrice = Number(item.cena) + item.obal + activeRestaurant.delivery_pluspackaging
  
          //Suma za extraItems
          if(item.extraItems && item.extraItems.length>0){ 
            itemPrice += item.extraItems.map(extraItem => Number(extraItem.price)).reduce((prev, next) => prev + next)
            extraItemPackaging += item.extraItems.map(extraItem => Number(extraItem.obal)).reduce((prev, next) => prev + next)
            itemPrice += extraItemPackaging
          }
  
          //Cena obalu
          packaging += (item.obal+activeRestaurant.delivery_pluspackaging+extraItemPackaging) * item.quantity 
  
        }else{ //Eshop
          itemPrice = Number(item.cena)
        }
        
        
        foodPrice += itemPrice * item.quantity
        
      }
  
  
      //CHECK DELIVERYORPICKUP
      if(activeRestaurant.deliverOrPickup || ((activeRestaurant.delivery_free !== 0 && location?.nofreedelivery !== "a") && foodPrice > activeRestaurant.delivery_free)){
        deliveryPrice = 0
      }else{
        deliveryPrice = activeRestaurant.delivery_price
        if(activeRestaurant.vlastni=="n" || !activeRestaurant.vlastni){
          deliveryPrice += Number(location?.doprava || 0)
        }
      }
    
    
    //Check if any item contains item.notaska == "n" and delivery_orderpackaging
    let delivery_orderpackaging = 0
    if(!cart.every(item => item.notaska == "a")){
      delivery_orderpackaging = activeRestaurant.delivery_orderpackaging; // Obal za objědnávku
    }
    let {tipCourier,creditsUsed} = totalAddition
    let minimalOrderAddition = 0
    let minimal_order = (Number(location?.minimalni)||0) > (Number(activeRestaurant.minimal_order)||0) ? (Number(location?.minimalni)||0) : (Number(activeRestaurant.minimal_order)||0)
  
    let totalPrice = foodPrice + deliveryPrice + (delivery_orderpackaging||0) + tipCourier
    
    let total_minimalCurrent = foodPrice + (delivery_orderpackaging||0)//cena započítávána do minimální objednávky
    
    //Doplatek minimální objednávky
    //Doplatek přičte zbývající částku pro splnění minimální objednávky
    if(total_minimalCurrent < minimal_order){
      minimalOrderAddition = minimal_order - total_minimalCurrent
      totalPrice += minimalOrderAddition 
    }

    totalPrice = totalPrice - creditsUsed
  

  
    return {
      totalPrice,
      minimalOrderAddition,
      deliveryPrice,
      packaging,
      delivery_orderpackaging
    }

  } catch (error) { 
    console.log("TOTAL PRICE CALCULATION ERROR: ",error);return "chyba"; 
    }
  }
