import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Form, Button, Container } from 'react-bootstrap';

const CourierForm = () => {
    const [resp, setResp] = useState('')
    const [sent, setSent] = useState(false)
    const [color, setColor] = useState('danger')
    const [cities, setCities] = useState({})
    const [values, setValues] = useState({
        name: '',
        phone: '',
        vehicle: '',
        email: '',
        age: true,
        city: { name: '', email: '' }
    });

    useEffect(() => {
        axios({
            method: 'get',
            url: '/api/location/cities'
        }).then(response => {
            console.log("Result from: /api/location/cities", response)
            if (response.status === 200) {
                setCities(response.data.filter(city=>city?.novykuryrkontakt=="a"))
            }
            else
                console.log("Načítání adres ze serveru se nepodařilo.")
        })
    }, [])

    const HandleChange = e => {
        const { name, value } = e.target;
        if (name === 'city') return setValues({ ...values, city: JSON.parse(value) })

        setValues({
            ...values,
            [name]: value
        });

    };
    const CheckBoxChange = (a) => {
        setValues({ ...values, age: a })
    }
    const HandleValidation = e => {
        setColor('danger')
        e.preventDefault();
        if (values.city.name?.length <= 1) return setResp("Město je neplatný!");
        else if (isNaN(values.phone) || values.phone.trim().length < 9 || values.phone.trim().length > 13) return setResp("Telefoní číslo je neplatný!");
        else if (!values.email || !/\S+@\S+\.\S+/.test(values.email)) return setResp("Email je neplatný!");
        else if (!values.name.trim()) return setResp("Jméno a přijmění není vyplněné!");
        else if (!values.vehicle) return setResp("Vozidlo není zvoleno!");

        fetch(`./api/location/contact/deliverWithUs`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values)
        }).then(res => {
            if (res.ok) {
                return res.json();
            } else {
                //data nepřišla
                setColor('danger');
                setResp("Chyba serveru.");
            }
        }).then(res => {
            if (res.status == "ok") {
                setColor('success');
                setResp(res.message);
                setSent(true);
            } else {
                setColor('danger');
                setResp(res.message);
            }

        })

    }

    return (
        <>
            <Container style={{ "paddingTop": "110px" }}>
                <h2 className="text-center">Buď svým pánem</h2>
                <p className="text-center">a staň se našim kurýrem</p>

                {resp && <div className="d-flex justify-content-center m-3"><div className={"p-3 bg-" + color + " text-white w-25 text-center rounded-pill"}>{resp}</div></div>}
                <Form className={sent && "d-none"} onSubmit={HandleValidation}>
                    {/* <Form.Group className="mb-3" controlId="">
                        <Form.Label>V jakém městě chceš jezdit</Form.Label>
                        <Form.Control name='city' type="text" isValid={values.city.trim()} value={values.city} onChange={HandleChange} />
                    </Form.Group> */}

                    <Form.Group className="mb-3" controlId="">
                        <Form.Label>V jakém městě chceš jezdit</Form.Label>
                        <Form.Control as="select" name='city' value={JSON.stringify(values.city)} isValid={values.city.name?.trim()} onChange={HandleChange}>
                            <option value="" />

                            {cities.length > 0 &&
                                cities?.map(city =>
                                    <option key={city.id_city} value={JSON.stringify({ name: city.name_city, email: city.novykuryrkontaktemail, info: {...city} })}>
                                        {city.name_city}
                                    </option>)}

                        </Form.Control>
                    </Form.Group>

                    {values.city.name &&
                    <Form.Group className="mb-3" controlId="">
                        <Form.Label>Čím chceš jezdit</Form.Label>
                        <Form.Control as="select" name='vehicle' isValid={values.vehicle} value={values.vehicle} onChange={HandleChange}>
                            <option value="" />
                            {values.city.info.novykuryr_vlastni_kolo == "a" && <option value="bike">Vlastní kolo</option>}
                            {values.city.info.novykuryr_vlastni_skutr == "a" && <option value="scooter">Vlastní skútr</option>}
                            {values.city.info.novykuryr_vlastni_auto == "a" && <option value="ownCar">Vlastní auto</option>}
                            {values.city.info.novykuryr_firemni_auto == "a" && <option value="companyCar">Firemní auto</option>}
                        </Form.Control>
                    </Form.Group>
                    }

                    <Form.Group className="mb-3" controlId="">
                        <Form.Label>Jméno a příjmení</Form.Label>
                        <Form.Control name='name' type="text" isValid={values.name} value={values.name} onChange={HandleChange} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="">
                        <Form.Label>Mobil</Form.Label>
                        <Form.Control name='phone' type="tel" isValid={!isNaN(values.phone) && values.phone.trim().length >= 9 && values.phone.trim().length <= 13} value={values.phone} onChange={HandleChange} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control name='email' type="email" isValid={values.email && /\S+@\S+\.\S+/.test(values.email)} value={values.email} onChange={HandleChange} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="">
                        <Form.Label>Je ti víc, jak 18 let?</Form.Label>
                        {['radio'].map((type) => (
                            <div key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                    inline
                                    label="Ano"
                                    name="ano"
                                    type={type}
                                    checked={values.age}
                                    onChange={() => { CheckBoxChange(!values.age) }}
                                />
                                <Form.Check
                                    inline
                                    label="Ne"
                                    name="ne"
                                    type={type}
                                    checked={!values.age}
                                    onChange={() => { CheckBoxChange(!values.age) }}
                                />
                            </div>
                        ))}
                    </Form.Group>

                    <p>Souhlasím se zásadami ochrany osobních údajů. Tlačítkem Odeslat souhlasím s tím, že můžete shromažďovat, používat a zveřejňovat mnou poskytnuté informace v souladu s vašimi Zásadami ochrany osobních údajů, které jsem četl a souhlasím s nimi.</p>

                    <Button variant="primary" type="submit">
                        Odeslat
                    </Button>
                </Form>
            </Container>
        </>
    )
}

export default CourierForm
