import React, { createContext, useReducer, useEffect } from "react";
import axios from 'axios'
import { now } from "moment";

import { checkTrackingPermision, checkForConversions } from "./tracking";
import { getTotal } from "./cart"
import google_analytics from './models/google_analytics'


/* --- */
const VERSION = "2.0" // VERSION, CHANGE FOR LOCALSTORAGE OVERRIDE
const EXPIRATION_TIME = 120 //[minutes]LOCALSTORAGE EXPIRY 
/* --- */

let AppContext = createContext();

const initialState = {
  user: false,
  cart: [],
  activeRestaurant: null,
  location: null,
  total: false,
  totalAddition: { creditsUsed: 0, tipCourier: 0 },
  isLunch: false,
  platform: "web",
  notification_token: null,
  user_tracking: false,
  cookies_permission: null,
  eventLog: ""
}

let reducer = (state, action) => {
  console.log(action.type)
  // state.eventLog += JSON.stringify({
  //   timestamp:new Date().getTime(),
  //   ...action
  // })

  checkExpiry() //Refresh expiry on user action

  switch (action.type) {
    case "LOGIN": {
      return { ...state, user: action.payload }
    }
    case "LOADUSER": {
      console.log("Loading user:", action.payload)
      checkForPromotionReward()
      return { ...state, user: action.payload }
    }
    case "LOGOUT": {
      return { ...state, user: false }
    }

    case "LOADCART": {
      return { ...state, cart: action.payload }
    }
    case "ADD_TO_CART": { //Přidat položku do košíku
      const found = state.cart.find(x => x.polozka_id === action.payload.polozka_id)
      if (found && JSON.stringify(found.extraItems) == JSON.stringify(action.payload.extraItems)) { //Pokud už obsahuje zvýšit quantity
        found.quantity += action.payload.quantity
        return { ...state, cart: [...state.cart] }
      } else { //přidat do košíku novou položku
        return { ...state, cart: [...state.cart, action.payload] }
      }
    }
    case "UPDATE_ITEM": {
      //state.cart.find(x => x.polozka_id === action.payload.polozka_id) 
      return { ...state, cart: [...state.cart] }
    }
    case "DELETE_ITEM": {
      if (state.cart.length == 1) {
        localStorage.setItem('cart', "[]");
        localStorage.setItem('activeRestaurant', "null");
        return { ...state, activeRestaurant: null, cart: state.cart.filter((item) => item !== action.payload) }
      } else {
        return { ...state, cart: state.cart.filter((item) => item !== action.payload) }
      }
    }
    case "EMPTY_CART": {
      localStorage.setItem('cart', "[]");
      return { ...state, cart: [] }
    }
    case "SET_ACTIVERESTAURANT": { //Nastavit aktivní restauraci pro vytvářenou objednávku
      let activeRestaurant = action.payload

      localStorage.setItem('activeRestaurant', JSON.stringify(action.payload));
      return { ...state, activeRestaurant }
    }
    case "LOADACTIVERESTAURANT": { //Načíst aktivní restauraci z localstorage
      return { ...state, activeRestaurant: action.payload }
    }
    case "LOADLOCATION": {
      localStorage.setItem('location', JSON.stringify(action.payload));
      if (state.activeRestaurant && state.cart.length > 0 && state.activeRestaurant.mesto_url !== action.payload.mesto_url) { //Město změněno, smazat vybranou aktivní restauraci
        localStorage.removeItem('activeRestaurant')
        localStorage.removeItem('cart')
        return { ...state, cart: [], activeRestaurant: null, location: action.payload }
      } else { //Default
        return { ...state, location: action.payload }
      }

    }
    case "REMOVELOCATION": {
      console.log("remove location")
      localStorage.removeItem('location')
      return { ...state, location: null }
    }
    case "ORDERFINISHED": { //Volá se při dokončení objednávky
      localStorage.removeItem('cart')
      localStorage.removeItem('activeRestaurant')

      return { ...state, cart: [], activeRestaurant: null, totalAddition: { creditsUsed: 0, tipCourier: 0 }, }
    }
    case "CONVERSION": { //Volá se při dokončení objednávky - měření konverze
      console.log(action.payload)
      let { order_number, data } = action.payload
      google_analytics.purchase(state.cart, order_number, data, state.activeRestaurant)
      if (order_number && data && state.user_tracking == true && state.cookies_permission?.analytic && state.cookies_permission?.marketing) {
        checkForConversions(order_number, data)
      }
      return { ...state }
    }
    case "COMPUTETOTAL": {
      return { ...state, total: getTotal(state.cart, state.activeRestaurant, state.totalAddition, state.location) }
    }
    case "SET_TOTALADDITION": {
      return { ...state, totalAddition: action.payload }
    }
    case "SET_RESTAURANT_FILTER": {
      return { ...state, restaurantFilter: action.payload }
    }
    case "REMOVE_RESTAURANT_FILTER": {
      return { ...state, restaurantFilter: false }
    }
    case "SET_CHECKBOX_RESTAURANT_FILTER": {
      return { ...state, checkbox_restaurantFilter: action.payload }
    }
    case "SET_NAME_RESTAURANT_FILTER": {
      return { ...state, name_restaurantFilter: action.payload }
    }
    case "SET_PLATFORM": {
      return { ...state, platform: action.payload }
    }

    case "COMPARE_LOCALSTORAGE": {
      let { cart, activeRestaurant, location } = action.payload
      let overrideRest = false
      let overrideLocation = false
      if (activeRestaurant == "rest_is_inactive")
        overrideRest = true
      if (location == "non_existing_kod_adm")
        overrideLocation = true

      return { ...state, cart: (cart || state.cart), activeRestaurant: (overrideRest ? null : (activeRestaurant || state.activeRestaurant)), location: (overrideLocation ? null : location || state.location) }
    }
    case "SET_ORDER_PROPERTIES": {
      return { ...state, ...action.payload }
    }
    case "LOAD_NOTIFICATIONTOKEN": {
      return { ...state, notification_token: action.payload }
    }
    case "ENABLE_TRACKING": {
      console.log("ENABLED USER TRACKING")
      return { ...state, user_tracking: true }
    }
    case "SET_COOKIES_PERMISSION": {
      console.log("SET COOKIES PERMISSION")
      localStorage.setItem('cookies_permission', JSON.stringify(action.payload));
      return { ...state, cookies_permission: action.payload }
    }

  }
  return state;
}


function AppContextProvider(props) {

  let [state, dispatch] = useReducer(reducer, initialState);
  let value = { state, dispatch };

  useEffect(() => {
    dispatch({ type: 'COMPUTETOTAL' })
  }, [state.cart, state.activeRestaurant, state.totalAddition, state.location])

  useEffect(() => {
    axios({
      method: 'get',
      url: '/api/auth'
    }).then(response => {
      console.log("Response: /api/auth/ ", response)
      if (response.data)
        dispatch({ type: 'LOADUSER', payload: response.data })
    })
  }, [])

  useEffect(() => {
    if (state.cart.length > 0) {
      localStorage.setItem('cart', JSON.stringify(state.cart));
    }

    let isLunch = !!state.cart.find(item => item.isLunchItem)

    let deliveryLaterFrom = state.cart.find(item => item.naDruhyDenOd)?.naDruhyDenOd || false

    let isMultiRest = !!state.cart.find(item => item.user_id != state.cart[0]?.user_id)

    console.log("deliveryLaterFrom: ", deliveryLaterFrom)

    dispatch({ type: 'SET_ORDER_PROPERTIES', payload: { isLunch, isMultiRest, deliveryLaterFrom } })

  }, [state.cart])

  useEffect(() => {
    dispatch({ type: 'SET_PLATFORM', payload: window.Capacitor?.platform })

  }, [window.Capacitor?.platform])

  useEffect(() => {

    checkVersion()
    checkExpiry()
    checkTrackingPermision(dispatch)

    let cookies_permission = JSON.parse(localStorage.getItem('cookies_permission'))

    if (cookies_permission) {
      dispatch({ type: 'SET_COOKIES_PERMISSION', payload: cookies_permission })
    }

    let localCart = JSON.parse(localStorage.getItem('cart'))
    let localActiveRestaurant = JSON.parse(localStorage.getItem('activeRestaurant'))
    let localLocation = JSON.parse(localStorage.getItem('location'))

    // !!!!
    //Loadlocation je pro zatím vypnuto, způsobovalo issues při neaktuálních datech v LS kde poté neseděla cena
    //Musíme integrovat refresh dat z API a v LS ukládat pouze ID lokace
    //První se musí nacenit a schválit
    // !!!!


    if (localLocation) {


      try {


        let location = localLocation.adresa
        console.log("GetValidLocation", location)
        axios.get(`https://api.jidlopodnos.cz/api.php/gpstest/${location.gps_x}/${location.gps_y}`).then(response => {

          console.log(response)

          if (response.status == 200) {

            if (response.data.oblast?.length == 0) {
              return
            }


            //console.log(response.data)
            let gpsResult
            let polygonRestaurants
            let isMulti = false
            if (Array.isArray(response.data.oblast)) {

              
              //if (localLocation) { //Pokud má aktivní restauraci, vyber z odpovědi oblast restaurace
                
                
              //}
              //gpsResult = matchingOblast ? matchingOblast : response.data.oblast[0]
              gpsResult = response.data.oblast.find(location => location.mesto === localLocation.mesto);

              polygonRestaurants = response.data.restaurace
              console.log("Founde multiple gps: ", response.data)
              if (response.data.oblast.length > 1)
                isMulti = true
            } else {
              gpsResult = response.data.oblast
              console.log("Singlelocation found", gpsResult)
            }

            if (gpsResult && gpsResult.code !== "error") {
              let mesto_url = gpsResult.mesto;


              mesto_url = mesto_url.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/\s+/g, '-');


              let locationData = { source: "LocalAdresValidated", ...gpsResult, mesto_url: mesto_url, polygonRestaurants, adresa: { ...location, kod_adm: location.kod_adm || location.id, string: location.string || location.name } }
              if (isMulti) locationData.multiLocation = response.data.oblast

              //return locationData
              dispatch({ type: 'LOADLOCATION', payload: locationData })

            } else {
              console.log("LOCALLOCATION VALIDATION ERROR:", gpsResult)
            }
          } else {
            console.log("chyba serveru")
          }


        })
      } catch (error) {
        console.log("LOCALLOCATION VALIDATION ERROR:", error)
        return false
      }

      console.log("LOCALLOCATION: ", localLocation)

      //dispatch({ type: 'LOADLOCATION', payload: localLocation })
    }

    if (localCart && localActiveRestaurant) {
      dispatch({ type: 'LOADCART', payload: localCart })
      dispatch({ type: 'LOADACTIVERESTAURANT', payload: localActiveRestaurant })
    }


  }, [])



  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
}

let AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };



const checkVersion = (version = VERSION) => {
  if (JSON.parse(localStorage.getItem('version')) != version) {
    localStorage.clear();
    localStorage.setItem('version', version);
    if ('caches' in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach(name => {
          caches.delete(name);
        })
      });
      window.location.reload(true);
    }
  }
}

const checkExpiry = (expirationTime = EXPIRATION_TIME) => {
  expirationTime = expirationTime * 1000 * 60;
  let now = new Date()
  let expires = localStorage.getItem('expires')

  if (!expires || now.getTime() > expires) {
    let remove = ['cart', 'activeRestaurant']
    remove.forEach(item => localStorage.removeItem(item))
  }

  localStorage.setItem('expires', now.getTime() + expirationTime)
}

const checkLsValidity = async (data) => {
  console.log("Checking validity for data: ", data)
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: '/api/user/checkLocalStorageValidity',
      data
    }).then(response => {
      console.log("Response: /api/user/checkLocalStorageValidity", response)
      if (response.data && response.data.hasOwnProperty("activeRestaurant") && response.data.hasOwnProperty("location") && response.data.hasOwnProperty("cart")) {
        resolve(response.data)
      } else {
        console.log("checkLsValidity Error")
        resolve(data)
      }
    })
  })
}


//Zkontroluje pokud uživatel nemá nárok za odměnu za přihlášení.
//Využito při spouštění aplikace pro odměnu za login.
const checkForPromotionReward = () => {

  if (window.Capacitor?.platform == "ios" || window.Capacitor?.platform == "android") {
    axios({
      method: 'get',
      url: '/api/user/userRegisterBonus'
    }).then(response => {
      console.log("Response: /api/user/[reward aplikace]", response)
      if (response.data) {
        //console.log("Dostal jsi odměnu za použití aplikace.")
      } else {
        //console.log("Reward check no reward")
      }
    }).catch(e => {
      //console.log("Reward check error:",e)
    })
  } else {
    //console.log("Platform web, no reward")
  }

}


const logEvent = () => {

}

const getValidLocation = async (location) => {

  try {



    console.log("GetValidLocation", location)
    axios.get(`https://api.jidlopodnos.cz/api.php/gpstest/${location.gps_x}/${location.gps_y}`).then(response => {

      console.log(response)

      if (response.status == 200) {

        if (response.data.oblast?.length == 0) {
          return
        }


        //console.log(response.data)
        let gpsResult
        let polygonRestaurants
        let isMulti = false
        if (Array.isArray(response.data.oblast)) {

          gpsResult = response.data.oblast[0]

          polygonRestaurants = response.data.restaurace
          console.log("Founde multiple gps: ", response.data)
          if (response.data.oblast.length > 1)
            isMulti = true
        } else {
          gpsResult = response.data.oblast
          console.log("Singlelocation found", gpsResult)
        }

        if (gpsResult && gpsResult.code !== "error") {
          let mesto_url = gpsResult.mesto;


          mesto_url = mesto_url.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/\s+/g, '-');


          let locationData = { source: "AddressSearch", ...gpsResult, mesto_url: mesto_url, polygonRestaurants, adresa: { ...location, kod_adm: location.kod_adm || location.id, string: location.string || location.name } }
          if (isMulti) locationData.multiLocation = response.data.oblast

          return locationData

        } {
          console.log("LOCALLOCATION VALIDATION ERROR:", gpsResult)
        }
      } else {
        console.log("chyba serveru")
      }


    })
  } catch (error) {
    console.log("LOCALLOCATION VALIDATION ERROR:", error)
    return false
  }
}