import React from 'react'
import {Row,Col} from 'react-bootstrap';
import PropTypes from 'prop-types';

const DetailGallery = (props) => {
    return (
        <Row>
            {props.images ? (props.images.map((image) => (
                <Col md={3} className="p-1" >
                    <div className=" detail-gallery-image" style={{backgroundImage: `url(${image.url})` }}>
                        
                    </div>
                    {/*<Image fluid className="detail-gallery-image" src={image.url} alt={image.alt} />*/}
                </Col>
            
            ))) : ""
            
            }
            <p className="text-center" style={{"width":"100%","font-size":"130%"}}>
            Zatím tu nic není..
            </p>
        </Row>
    )
}

export default DetailGallery

DetailGallery.propTypes = {
    images: PropTypes.array,
};

DetailGallery.defaultProps = {
    images: [],
}