import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import oneDeliveryImage from '../resources/jedna_doprava.png'
import useWindowSize from '../useWindowsize';


const RestaurantGroups = (props) => {
    const size = useWindowSize();

    if (!props.restaurant?.section_restaurants?.length) return (<></>);
    return (
        <Row style={{ "margin": 0, "background-color": "#ededed", "marginBottom": "10px", "border": "1px solid #ced4da", "justifyContent": size.width <= 767.98 ? "center" : "unset" }}>
            <Col lg={4} style={{ overflow: 'auto', minWidth: '' }} className='mb-4 mt-4 d-flex align-items-center'>
                <Image style={{ width: "25%" }} src={oneDeliveryImage} />
                <div className='flex-grow-1 ms-3 ml-2' style={{ maxWidth: '250px' }}>
                    <h6 className='text-secondary'>Objednejte z více provozoven</h6>
                    <h5 className='text-danger'>ZA 1 DOPRAVU</h5>
                    <p>Při splnění minimální objednávky můžete přiobjednat libovolné množství jídel z těchto provozoven</p>
                </div>
            </Col>
            { props.restaurant.section_restaurants.map((restaurant, i) => (
                /* <div style={{"width":"234px","margin":"0 5px"}} className='mb-4 mt-4'>
                    <Link to={`/rozvoz-jidel/${props.restaurant.mesto_url}/${restaurant.rest_url}/objednat`}>
                       <Image style={{"width":"234px"}} src={`https://www.jidlopodnos.cz/data/restaurace/mkartan/${restaurant.user_id}.jpg`} />
                   </Link>
               </div>  */
                !restaurant.isOpen ?
                    ""
                    // <Col xs={12} sm={6} md={4} lg={2} className='mb-4 mt-4' style={{ "display": "flex", "alignItems": "center", "justifyContent": "center" }}>
                    //     <div className="text-center w-100 text-white" style={{ "position": "absolute", "zIndex": "2", "background-color": "rgba(255,0,0,0.7)" }}> Zavřeno </div>
                    //     <Image fluid className=" w-100" style={{ "position": "absolute"}} src={`https://www.jidlopodnos.cz/data/restaurace/mkartan/${restaurant.user_id}.jpg`} />
                    // </Col> 
                    :
                    <Col xs={12} sm={6} md={4} lg={2} className='mb-4 mt-4' style={{ "display": "flex", "alignItems": "center", "justifyContent": "center" }}>
                        <Link to={`/rozvoz-jidel/${props.restaurant.mesto_url}/${restaurant.rest_url}/objednat`}>
                            <Image style={{ "width": "100%" }} src={`https://www.jidlopodnos.cz/data/restaurace/mkartan/${restaurant.user_id}.jpg`} />
                        </Link>
                    </Col>

            ))
                
            }
        </Row>
    )
}

export default RestaurantGroups
