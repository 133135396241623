import React from 'react';
import {Link} from 'react-router-dom';
import {Row,Col,Container, Button, Spinner} from 'react-bootstrap';
import Icofont from 'react-icofont';
import useFetch from '../../useFetch.js'
import {useParams} from 'react-router-dom';
 

const TrackOrder = ()=> {

	let html = `<img alt="seznam_alergenu" height="943" src="http://www.jidlopodnos.cz/data/soubory/poledni_menu_cantina/seznam_alergenu.png" width="700">`


	//const {data: order,loading: loadingOrder,error:errorOrder} = useFetch(`/api/orders/detail/${year}/${cislo}`) 

/* 	if(loadingOrder) return (
		<Container className="pb-5" style={{"paddingTop":"8rem","minHeight":"60vh"}}>
			<Row>
				<Col md={12} className="text-center load-more">
				<Button variant="primary" type="button" disabled="">
					<Spinner animation="grow" size="sm" className='mr-1' />
					Načítání...
				</Button>  
				</Col>
			</Row>
		</Container>
	) */

/* 	if(errorOrder) return (
		<Container className="pb-5" style={{"padding-top":"8rem","min-height":"60vh"}}>
			<Row>
				<Col md={12} className="text-center load-more">
				<Button variant="primary" type="button" disabled="">
					Chyba 
				</Button>  
				</Col>
			</Row>
		</Container>
	) */

	return (
		<section dangerouslySetInnerHTML={{ __html: html }} className="section bg-white osahan-track-order-page position-relative text-center" style={{"paddingTop":"130px"}}>
	
		</section>
    	);
    
}


export default TrackOrder;