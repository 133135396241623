import React, {useContext, useState} from 'react'
import DropDownTitle from '../common/DropDownTitle';
import {NavDropdown,Form,Button, Modal} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import axios from 'axios'

const Register = () => {

	const [login, setLogin] = useState("")
	const [password, setPassword] = useState("")
	const [passwordCheck, setPasswordCheck] = useState("")
	const [firstname, setFirstname] = useState("")
	const [lastname, setLastname] = useState("")
	const [phone, setPhone] = useState("")
	const [error, setError] = useState("")
	const [showDoneModal, setshowDoneModal] = useState(false)

	const handleRegister = (e) => {
		e.preventDefault()
		if(password!==passwordCheck){
			setError("Hesla se neshodují")
			return
		}

		axios({
			method: 'post',
			url: '/api/auth/register',
			data: {
			  login: login,
			  heslo: password,
			  firstname,
			  lastname,
			  phone
			}
		  }).then(response => {
			console.log(response)
			if(response.data.status=="ok"){
				setshowDoneModal(true)
				setLogin("")
				setPassword("")
				setFirstname("")
				setLastname("")
				setPhone("")
			}else{
				setError(response.data.message)
			}

		  }).catch(error => {
			  	console.log(error.response)
				setError(error.response.data.message)
		  })
	}

    return (
		<>
		<Modal className="extra-items" show={showDoneModal}>
				
				<Modal.Body>
					
				  <Button className="extra-items-close-button" onClick={()=>{setshowDoneModal(false)}}><ion-icon className="extra-items-close-button-icon" name="close"/></Button>
				  
				  <h5 className="extra-items-title">Registrace úspěšná</h5>
		
				  <p className="extra-items-food-description mt-1">Na váš email jsme odeslali odkaz k aktivaci</p>
				  
			  </Modal.Body>
  
		  </Modal>
        <NavDropdown activeclassname="active" alignRight className="dropdown-cart analytics-button-header" 
			title={
				<DropDownTitle 
					className='d-inline-block' 
					title='Registrovat se'
				/>
		}>

		<div className="dropdown-login">
			<Form onSubmit={handleRegister}>
				<div className="form-label-group">
					<Form.Control required onChange={e => setLogin(e.target.value)} type="email" id="inputEmail2" placeholder="" />
					<Form.Label htmlFor="inputEmail2">Email / Mobil <span className="text-primary">*</span></Form.Label>
				</div>
				<div className="form-label-group">
					<Form.Control required onChange={e => setPassword(e.target.value)} type="password" id="inputPassword2" placeholder="" />
					<Form.Label htmlFor="inputPassword2">Heslo <span className="text-primary">*</span></Form.Label>
				</div>
				<div className="form-label-group">
					<Form.Control required onChange={e => setPasswordCheck(e.target.value)} type="password" id="inputpasswordCheck" placeholder="" />
					<Form.Label htmlFor="inputpasswordCheck">Kontrola hesla <span className="text-primary">*</span></Form.Label>
				</div>
				<span>Údaje k objednávce <small>(nepovinné)</small></span>
				<div className="pt-3"></div>
				<div className="form-label-group">
					<Form.Control  onChange={e => setPhone(e.target.value)} type="text" id="inputphone" placeholder="" />
					<Form.Label htmlFor="inputphone">Telefon </Form.Label>
				</div>
				<div className="form-label-group">
					<Form.Control  onChange={e => setFirstname(e.target.value)} type="text" id="inputfirstname" placeholder="" />
					<Form.Label htmlFor="inputfirstname">Jméno </Form.Label>
				</div>
				<div className="form-label-group">
					<Form.Control  onChange={e => setLastname(e.target.value)} type="text" id="inputlastname" placeholder="" />
					<Form.Label htmlFor="inputlastname">Příjmení</Form.Label>
				</div>
				<p className="text-danger">{error}</p>
				<Button type="submit" className="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2">Registrovat se</Button>
				<div className="text-center pt-3" onClick={()=>{document.getElementById("loginRef").click()}}>
					Již máte účet? <Link className="font-weight-bold" to="#">Přihlásit se</Link>
				</div>
			</Form>
		</div>
		</NavDropdown>  
		</>
    )
}

export default Register
