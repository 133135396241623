import React from 'react';
import { Col, Row, Container, Image } from 'react-bootstrap';
import GooglePlayBadge from '../../resources/google-play-badge.png';
import AppStoreBadge from '../../resources/app-store-badge.svg';

function AppBanner() {
    return (
        <div className = "app-banner-container-parent">
            <Container  className = "app-banner-container">
                <Row className="row-eq-height">
                    <Col className="banner-text-part" md={6}>
                        <p className="banner-title">Stáhněte si a používejte naši novou</p>
                        <p className="banner-title-app">APLIKACI</p>
                        <p className="banner-text">
Nainstalujte si novou apku, kde na vás čeká 20 Kč na první objednávku. A další peníze můžete získat od restaurací formou zpětných bonusů. 
Stav vašeho konta, ale i spoustu dalších informací, vychytávek, her a soutěží naleznete právě v naší aplikaci.
Tak neváhejte...</p>
<h3 className="text-danger">... a naklikejte si 20 Kč</h3>
                        <a target="_blank" className="analytics-app-banner" analyticsvalue="Android" href="https://play.google.com/store/apps/details?id=cz.jidlopodnos.app&hl=cs"><Image className ="banner-image" src={GooglePlayBadge}/></a>
                        <a target="_blank" className="analytics-app-banner" analyticsvalue="iOS" href="https://apps.apple.com/cz/app/j%C3%ADdlo-pod-nos/id1594194405?l=cs"><Image className ="banner-image" src={AppStoreBadge}/></a>

                    </Col>
                    <Col className=" banner-app-image " md ={6}>
                        {/*Image*/}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AppBanner
