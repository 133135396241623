import React,{useState,useEffect, useCallback} from 'react';
import {Row,Col} from 'react-bootstrap';
import AddAddressModal from '../modals/AddAddressModal';
import DeleteAddressModal from '../modals/DeleteAddressModal';
import AddressCard from '../common/AddressCard';
import useFetch from '../../useFetch';
import {Card,Media} from 'react-bootstrap';
import Icofont from 'react-icofont';
import axios from 'axios';

const Addresses = () => {

	const [showAddressModal, setShowAddressModal] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [deleted, setDeleted] = useState(false)
	const [update, setUpdate] = useState(true)
	
	//const {data: addresses,loading: loading,error:error} = useFetch("/api/location/fav") 
	
	const [addresses, setAddresses] = useState([])

	useEffect(() => {
		if(update){
		console.log("updating")
		axios({
			method: 'get',
			url: '/api/location/fav'
		  }).then(response => {
			console.log(response)
			if(response.status===200){
				setAddresses(response.data)
				setUpdate(false)
			}
			else
				alert("Načítání adres ze serveru se nepodařilo.")
		  })
		}
	}, [update]) 

    const hideDeleteModal = () => setShowDeleteModal(false)
    const hideAddressModal = () => setShowAddressModal(false)

	const deleteAddress = useCallback(
		() => {
			console.log(addresses)
			console.log(deleted)
			axios.delete("/api/location/fav/"+deleted)
			setAddresses(addresses.filter(ad=>ad.kod_adm!=deleted))
			setShowDeleteModal(false)
			setDeleted(false)
		},
		[deleted],
	)


    	return (
	      <>
	        <AddAddressModal show={showAddressModal} update={setUpdate} onHide={hideAddressModal}/>
	        <DeleteAddressModal show={showDeleteModal} onHide={hideDeleteModal} deleteAddress={deleteAddress}/>
		    <div className='p-4 bg-white shadow-sm'>
              <Row>
               <Col md={12}>
                  <h4 className="font-weight-bold mt-0 mb-3">Spravovat Adresy</h4>
               </Col>
			   {addresses &&  addresses.length>0 && addresses.map(loc=>(
					<Col md={6}>
					<AddressCard 
						key={loc.kod_adm}
						boxClass="border border-primary shadow"
						title= {loc.name ? loc.name: loc.typ_so=="č.p." ? loc.nazev_ulice ?(loc.nazev_ulice+" "+loc.cd+ (loc.co ? "/"+loc.co:"")) : loc.nazev_obec+" "+loc.cd+ (loc.co ? "/"+loc.co:"") +", "+loc.psc  :  loc.nazev_obec+" č.e. "+loc.cd+" "+loc.psc}
						address= {loc.name? getLocationString(loc) : loc.nazev_obec+" "+loc.psc}
						icoIcon= 'ui-home'
						kod_adm={loc.kod_adm}
						iconclassName= 'icofont-3x'					
						onEditClick= {() => setShowAddressModal(true)}
						onDeleteClick={() => setShowDeleteModal(true)}
						setDeleted={setDeleted}
					/>
					</Col>
			   ))
			}
			<Col md={6}>
				
			<Card onClick={()=>setShowAddressModal(true)} className={"bg-white addresses-item mb-4 border border-primary shadow" }>
				<div className="gold-members p-4">
				<Media>
					<div className="mr-3"><Icofont icon="plus" className="icofont-3x"/></div>
					<div className="media-body">
						<h6 className="mb-1 text-secondary">Přidat adresu</h6>
					</div>
				</Media>
				</div>
			</Card>
			</Col>
               
               
              </Row>
		    </div>
	      </>
    	);
    
}

const getLocationString = loc => {
	return loc.typ_so=="č.p." ? loc.nazev_ulice ?(loc.nazev_ulice+" "+loc.cd+ (loc.co ? "/"+loc.co:"") +", "+loc.nazev_obec+" "+loc.psc) : loc.nazev_obec+" "+loc.cd+ (loc.co ? "/"+loc.co:"") +", "+loc.psc  :  loc.nazev_obec+" č.e. "+loc.cd+" "+loc.psc
}

const deleteAddress = (kod_adm)=>{
	axios.delete("/api/location/fav/"+kod_adm)
}
export default Addresses;