import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container, Image, Button, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import Icofont from 'react-icofont';
import { AppContext } from '../context.js'


function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const Thanks = () => {

	let { year, cislo } = useParams();
	const [status, setStatus] = useState()

	const { state, dispatch } = useContext(AppContext)
	let query = useQuery();

	useEffect(() => {
		console.log(state)
		if (query.get("status") == "payment_unfinished") {
			setStatus("payment_unfinished")
		} else {
			dispatch({ type: 'ORDERFINISHED' })
		}


		window.scrollTo(0, 0)

	}, [])

	if (cislo && year) return (
		<>

			<section className="section pt-5 pb-5 osahan-not-found-page">
				<Container>
					<Row>
						<Col md={12} className="text-center pt-5 pb-5">
							<Image className="img-fluid" src="/img/thanks.png" alt="404" />
							<h1 className="mt-2 mb-2">Gratulujeme</h1>
							<p className="mb-5">Vaše objednávka byla odeslána do našeho systému</p>
							<Link className="btn btn-primary btn-lg" to={`/objednavka/${year}/${cislo}`}>Sledujte stav objednávky</Link>
						</Col>
					</Row>
				</Container>
			</section>
		</>
	);

	if (status == "payment_unfinished") return (
		<section className="section pt-5 pb-5 osahan-not-found-page">
			<Container>
				<Row>
					<Col md={12} className="text-center pt-5 pb-5">
						<Icofont style={{ "color": "red", "fontSize": "13rem" }} icon="close-circled" />
						<h1 className="mt-2 mb-2">Platba se nezdařila</h1>
						<p className="mb-5">Platba kartou se nezdařila, použijte jinou kartu nebo využijte platbu hotově</p>

						{state.activeRestaurant &&
							<Link className="" to={"/rozvoz-jidel/" + state.activeRestaurant.mesto_url + "/" + state.activeRestaurant.rest_url + "/" + "platba"}><Button>Zpět k dokončení objednávky</Button></Link>
						}
					</Col>
				</Row>
			</Container>
		</section>
	)

	return (
		<section className="section pt-5 pb-5 osahan-not-found-page">
			<Container>
				<Row>
					<Col md={12} className="text-center pt-5 pb-5">
						<Icofont style={{ "color": "red", "fontSize": "13rem" }} icon="close-circled" />
						<h1 className="mt-2 mb-2">Chyba</h1>
						<p className="mb-5">Něco se pokazilo...</p>
					</Col>
				</Row>
			</Container>
		</section>
	);

}



export default Thanks;