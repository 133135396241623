import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Row,Col,Container,Image, Form, Button, Spinner} from 'react-bootstrap';
import axios from 'axios'
import {useLocation} from "react-router-dom";


const ResetPassword = ()=>{

	const [resetPwdInput, setresetPwdInput] = useState("")
	const [resetPwdCheckInput, setresetPwdCheckInput] = useState("")
	const [loading, setLoading] = useState(true)
	const [tokenValid, setTokenValid] = useState(false)
	const [status, setStatus] = useState("loading")
	let query = useQuery();

	useEffect(() => {
		axios({
			method: 'put',
			url: '/api/auth/pswd_reset/'+query.get("token")
		  }).then(response => {
			console.log(response)
			if(response.status===200){	
				setTokenValid(true)
				setStatus("valid")
			}
			else{
				setStatus("invalid")
			}
				
		  }).catch(
			function (error) {
			  //console.log(error.response.status)
			  setStatus("invalid")
			}
		  )
	}, [])

	const handleResetPwd = (e) => {
		e.preventDefault()
		axios({
			method: 'post',
			url: '/api/auth/pswd_reset/'+query.get("token"),
			data: {
			  password: resetPwdInput,
			  password_retype: resetPwdCheckInput,
			}
		  }).then(response => {
			console.log(response)
			if(response.status===200){	
				console.log("ResetDone")
				setStatus("done")
			}
			else
				alert("Omlouváme se. Došlo k systémové chybě. Pro obnovu hesla kontaktujte prosím administrátora.")
		  })
	}


    	return (
    		<section className="section pt-5 pb-5 osahan-not-found-page">
		         <Container>
		            <Row>
						<Col md={4} />
						{status == "loading" && (<Col><Spinner animation="border" role="status">
  <span className="sr-only">Načítání...</span>
</Spinner></Col>)}
						{status == "valid" && (
		               <Col md={4} className="text-center pt-5 pb-5">
		                  <h1 className="mt-2 mb-2">Obnovit heslo</h1>
		                  <Form onSubmit={handleResetPwd}>
								<div className="form-label-group">
									<Form.Control required onChange={e => setresetPwdInput(e.target.value)} type="password" id="resetPwdInput" placeholder=""  />
									<Form.Label htmlFor="resetPwdInput">Nové heslo</Form.Label> 
								</div>
								<div className="form-label-group">
									<Form.Control required onChange={e => setresetPwdCheckInput(e.target.value)} type="password" id="resetPwdInputCheck" placeholder="Email address" />
									<Form.Label htmlFor="resetPwdInputCheck">Kontrola hesla</Form.Label> 
								</div>
							
		
							<Button type="submit" className="extra-items-add-to-cart">Obnovit</Button>
							
						</Form>
		               </Col>
					   )}
					   {status == "invalid" && (<Col md={4} className="text-center pt-5 pb-5"><h1 className="mt-2 mb-2">Tento odkaz už je neplatný</h1><br></br><h5>Zkuste heslo obnovit znovu</h5></Col>)}
					   {status == "done" && (<Col md={4} className="text-center pt-5 pb-5"><h1 className="mt-2 mb-2">Heslo obnoveno</h1><br></br></Col>)}
		            </Row>
		         </Container>
		    </section>
    	);
    }
	function useQuery() {
		return new URLSearchParams(useLocation().search);
	}



export default ResetPassword;