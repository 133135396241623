import React,{useState,useEffect,useContext} from 'react';
import {Link} from 'react-router-dom';
import {Row,Col,Container,Form,Button} from 'react-bootstrap';
import Icofont from 'react-icofont';
import CategoriesCarousel from '../common/CategoriesCarousel'
import axios from 'axios'
import {AppContext} from '../../context.js'
import { useHistory } from "react-router-dom";
import AddAddressModal from '../modals/AddAddressModal';
import AddressSearch from '../AddressSearch.js';
import AddressPick from '../AddressPick.js';
import useWindowSize from '../../useWindowsize';
import { getLocationString } from '../../getLocationString';


function TopSearch (props) {

		const {state, dispatch} = useContext(AppContext)

		let history = useHistory();
		const size = useWindowSize();		

		const [addresses, setAddresses] = useState([])

		//Fav locations
		useEffect(() => {
			if(state.user)
			axios({
				method: 'get',
				url: '/api/location/fav'
			}).then(response => {
				console.log("Result from: /api/location/fav",response)
				if (response.status === 200) {
					
					let addresses_array = response.data.map(loc=>{ return {...loc,string:getLocationString(loc)} })
					
					setAddresses(addresses_array)
	
				}
				else
					console.log("Načítání adres ze serveru se nepodařilo.")
			})
		}, [state.user])


    	return (<>
		{/* <AddAddressModal show={true} onHide={false}/> */}
	      <section className="pb-5 homepage-search-block position-relative" style={{"paddingTop":"6rem","height":size.width <767.98 ?"auto":"500px"}}>
	         <div className="banner-overlay"></div>
	         <Container fluid style={{"max-width":"1292px","width":size.width > 767.98 ?"80%":"100%"}}>
	            <Row className="d-flex align-items-center" style={{"marginTop":"80px"}}>
	               <Col md={8}>
	                  <div className="homepage-search-title mt-4">
	                     <h1 className="mb-5">Restaurace u Vás doma</h1>
	                  </div>
					  <br></br>
	                  {state.location && props.didSearch?
					  <AddressPick isHomepage={true} addresses={addresses} />
					  :
					  <AddressSearch addresses={addresses} isHomepage={true} />
					  }
					  <span className="homepage-search-description text-secondary pl-0 ml-0 mb-3">{!state.location && "Zadejte svoji adresu ve tvaru Ulice, číslo domu, město."} {state.user ? <Link style={{"borderBottom": "1px solid #c62727"}} className="text-secondary pl-0" to="/mujucet/adresy">V sekci Můj účet / Adresy můžete spravovat uložené adresy</Link>:"Přihlašte se, a my si budeme vaši adresu pamatovat."} </span>
					  <br></br>
					  <div className="mt-5"></div>
					  	{props.didSearch &&
					   <CategoriesCarousel></CategoriesCarousel>
						}
	               </Col>
	               <Col md={4}>
	                  
	               </Col>
	            </Row>
	         </Container>
	      </section>
	    </>)
}


const options2={
	responsive: {
        0:{
            items:2,
        },
        764:{
            items:2,
        },
        765: {
          items: 1,
        },
        1200: {
          items: 1,
        },
      },
      lazyLoad: true,
      loop: true,
      autoplay: true,
      autoplaySpeed: 1000,
      dots: false,
      autoplayTimeout: 2000,
      nav: true,
      navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
      autoplayHoverPause: true,
}


export default TopSearch;