import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types'; 
import {Image} from 'react-bootstrap';
import Icofont from 'react-icofont';


class ProductBox extends React.Component {
	render() {
    	return (
          <div onClick={()=>{this.props.onClick("all-rest-head");this.props.setFilter(this.props.title)}} className={this.props.boxClass}>
            <div className="mt-2"></div>

              {/* <Image src={this.props.image} className={this.props.imageClass} alt={this.props.imageAlt} /> */}
              <Icofont icon={this.props.icon || "chef"} size="3"></Icofont>
              {this.props.title?
                <h6>{this.props.title}</h6>
                :""
              }
              <div className="mb-2"></div>


          </div>
		);
	}
}


ProductBox.propTypes = {
  linkUrl: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  imageClass: PropTypes.string,
  imageAlt: PropTypes.string,
  boxClass: PropTypes.string,
  title: PropTypes.string,
  counting: PropTypes.string,
};
ProductBox.defaultProps = {
  	imageAlt:'image',
    image:'',
    imageClass:'img-fluid rounded',
    linkUrl: '#',
    boxClass:'products-box',
    title:'Restaurace',
    counting:'counting',
}

export default ProductBox;