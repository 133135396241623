import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import ExtraItemsSingle from './ExtraItemsSingle';
import PropTypes from 'prop-types';
import useFetch from '../../useFetch.js'
import axios from 'axios'
import Icofont from 'react-icofont';
import google_analytics from '../../models/google_analytics'

const ExtraItems = (props) => {

    const [categoryTitle, setCategoryTitle] = useState("");
    const [createSpan, setCreateSpan] = useState(true);
    const [extraItemsList, setExtraItemsList] = useState({})
    const [extraItemsSelected, setExtraItemsSelected] = useState([])
    const [extraItemsRequired, setExtraItemsRequired] = useState([])
    const [selectQuantity, setSelectQuantity] = useState(1)
    const [showError, setShowError] = useState(false)
    const [loadingExtraitems, setLoadingExtraitems] = useState(false)

    const [analyticsArray, setAnalyticsArray] = useState([])

    const finishExtraItems = (event) => {
        event.preventDefault();
        event.stopPropagation();
    
        // Manual validation for required items ("p" elements)
        const allRequiredSelected = extraItemsList.p 
            ? Object.keys(extraItemsList.p).every((category) => {
                // Check if at least one item in each "p" category is selected
                return extraItemsList.p[category].some(item => 
                    extraItemsSelected.some(selectedItem => selectedItem.id === item.id)
                );
            })
            : true; // If no "p" elements, consider all required items as selected
    
        analyticsArray.push({ extraItems: extraItemsSelected });
    
        if (event.currentTarget.checkValidity() === false || !allRequiredSelected) {
            setShowError(true);
        } else {
            props.handleAddItem({ 
                ...props.addedItem, 
                extraItems: extraItemsSelected, 
                quantity: selectQuantity 
            });
            google_analytics.add_to_cart(
                [props.addedItem], 
                analyticsArray[2], 
                selectQuantity, 
                props.restaurant
            );
            setShowError(false);
            setExtraItemsSelected([]);
            setSelectQuantity(1);
            setAnalyticsArray([]);
        }
    };
    

    const toggleExtraItemChechbox = (extraItem, isRadio = false) => {
        //console.log(extraItemsRequired)
        if (extraItemsSelected.includes(extraItem)) {
            //  console.log("removing")
            setExtraItemsSelected(extraItemsSelected.filter(item => item !== extraItem))
        }
        else {
            //console.log("adding")
            let addedItems = extraItemsSelected
            if (isRadio) {
                addedItems = addedItems.filter(item => item.group_name !== extraItem.group_name)
            }
            setExtraItemsSelected([...addedItems, extraItem])
        }
    }

    useEffect(() => {
        //console.log(props.addedItem)
        setExtraItemsList({})
        setExtraItemsSelected([])

        if (props.addedItem && props.addedItem.additions.length > 0){
            setLoadingExtraitems(true)
            axios({
                method: 'post',
                url: 'api/menu/addition',
                data: props.addedItem.additions
            }).then(response => {
                setLoadingExtraitems(false)
                if (response.data) {
                    setExtraItemsList(response.data)
                    setAnalyticsArray([{ item: props.addedItem }, { additions: response.data }])

                }
            })
        }
    }, [props.addedItem])

    useEffect(() => {
        google_analytics.select_item(analyticsArray[0], props.restaurant)
        google_analytics.view_item(analyticsArray[0], analyticsArray[1], props.restaurant)
    }, [analyticsArray])



    return (
        <Modal className="extra-items" show={props.show}>

            <Modal.Body>
                <Button className="extra-items-close-button" onClick={props.onClose}><ion-icon className="extra-items-close-button-icon" name="close" /></Button>
                <h5 className="extra-items-title">{props.addedItem && props.addedItem.additions.length > 0 ? "Dáte si něco navíc?" : "Přidat položku"}</h5>
                <h6 className="extra-items-food-title mt-1">{props.addedItem ? props.addedItem.nazev : ""}<span className="extra-items-food-title-alergens">{props.addedItem && props.addedItem.allergen}</span></h6>


                <Form noValidate onSubmit={finishExtraItems}>
                    {props.addedItem && props.addedItem.additions.length > 0 ?
                        Object.keys(extraItemsList).length > 0 ? <>
                            <p className="extra-items-food-description mt-1">{props.addedItem && props.addedItem.popis}</p>
                            {extraItemsList.p && Object.keys(extraItemsList.p).map((category, index) => (
                                <>

                                    <div key={index}>
                                        <span className="extra-items-category-title ">{category?.split("_")[0]} *</span>
                                        {/* {setExtraItemsRequired(...extraItemsRequired,category)} */}
                                        {/* <ExtraItemsSingle key={extraItem.id} type={extraItem.type} name={extraItem.name} id={extraItem.id} price={extraItem.price} title={extraItem.title}/> */}
                                        {extraItemsList.p[category].map((extraItem, index) => (
                                            <Form.Group key={index} className={`extra-items-form-group`} style={{ "marginTop": index == 0 ? "8px" : "2px" }}>
                                                <span className="extra-items-price">{extraItem.price > 0 ? extraItem.price + " Kč" : "zdarma"} </span>

                                                <Form.Label style={{ "maxWidth": "80%" }} htmlFor={extraItem.id}  >
                                                    <Form.Check required type="radio" onChange={() => { toggleExtraItemChechbox(extraItem, true) }} inline name={category} id={extraItem.id} />
                                                    {extraItem.name}
                                                </Form.Label>
                                            </Form.Group>
                                        ))}

                                    </div>
                                </>
                            ))}

                            {extraItemsList.i && Object.keys(extraItemsList.i).map((category, index) => (
                                <div key={index}>
                                    <span className="extra-items-category-title ">{category?.split("_")[0]}</span>
                                    {/* <ExtraItemsSingle key={extraItem.id} type={extraItem.type} name={extraItem.name} id={extraItem.id} price={extraItem.price} title={extraItem.title}/> */}
                                    {extraItemsList.i[category].map((extraItem, index) => (
                                        <Form.Group key={index} className={`extra-items-form-group`} style={{ "margin-top": index == 0 ? "8px" : "2px" }}> {/* <- lazy css řešení :D */}
                                            <span className="extra-items-price">{extraItem.price > 0 ? extraItem.price + " Kč" : "zdarma"}</span>
                                            <Form.Label style={{ "maxWidth": "80%" }}>
                                                <Form.Check onChange={() => { toggleExtraItemChechbox(extraItem) }} inline name={extraItem.name} id={extraItem.id} />
                                                {extraItem.name}
                                            </Form.Label>
                                        </Form.Group>
                                    ))}

                                </div>
                            ))}





                        </> : "Načítání..."
                        : ""}
                    {showError && <>
                        <div className="extra-items-line"></div>
                        <h6 className="text-danger"><Icofont icon="exclamation" style={{ "fontSize": "1.5rem" }} /> Vyberte povinné přílohy</h6>
                    </>}
                    <div className="extra-items-line"></div>

                    <span className="quantSpan">Množství: </span>

                    <div className="mt-1 cart-minus-button quantButton" onClick={() => { if (selectQuantity > 1) setSelectQuantity(selectQuantity - 1) }} style={{ float: "left" }} > - </div>
                    <input className="mt-1 count-number-input quantButton" style={{ float: "left" }} type="text" value={selectQuantity} readOnly />
                    <div className="mt-1 cart-plus-button quantButton" onClick={() => { setSelectQuantity(selectQuantity + 1) }} style={{ float: "left" }} > + </div>

                    {/* <Button onClick={finishExtraItems} className="extra-items-add-to-cart">Vložit do košíku</Button> */}
                    <Button disabled={loadingExtraitems} type="submit" className="extra-items-add-to-cart">Vložit do košíku</Button>

                </Form>


            </Modal.Body>

        </Modal>
    )
}

export default ExtraItems

ExtraItems.propTypes = {
    extraItems: PropTypes.array,

};

ExtraItems.defaultProps = {
    extraItems: [],

}