import React from 'react'
import {Row,Col,Form,Button} from 'react-bootstrap';

const DetailBookTable = () => {
    return (
        <div id="book-a-table" className="bg-white rounded shadow-sm p-4 mb-5 rating-review-select-page">
			<h5 className="mb-4">Rezervovat stůl</h5>
			<Form>
				<Row>
				<Col sm={6}>
					<Form.Group>
						<Form.Label>Celé jméno</Form.Label>
						<Form.Control type="text" placeholder="Zadejte celé jméno" /> 
					</Form.Group>
				</Col>
				<Col sm={6}>
					<Form.Group>
						<Form.Label>Emailová adresa</Form.Label>
						<Form.Control type="text" placeholder="Vložte emailovou adresu" />
					</Form.Group>
				</Col>
				</Row>
				<Row>
				<Col sm={6}>
					<Form.Group>
						<Form.Label>Telefon</Form.Label>
						<Form.Control type="text" placeholder="Zadejte číslo mobilního telefonu" />
					</Form.Group>
				</Col>
				<Col sm={6}>
					<Form.Group>
						<Form.Label>Datum a čas</Form.Label>
						<Form.Control type="text" placeholder="Zadejte datum a čas" />
					</Form.Group>
				</Col>
				</Row>
				<Form.Group className="text-right text-center">
					<Button variant="primary" type="button"> Odeslat rezervaci </Button>
				</Form.Group>
			</Form>
		</div>
    )
}

export default DetailBookTable
