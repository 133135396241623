import React, { useState } from 'react'
import { Button, CloseButton, Form, ListGroup, ListGroupItem, Modal } from 'react-bootstrap'

const CookiesModal = (props) => {
    const [analytic, setAnalytic] = useState(true)
    const [marketing, setMarketing] = useState(false)

    return (
        <Modal show={props.show} centered size='lg'>
            <Modal.Header>
                <Modal.Title>Nastavení cookies</Modal.Title>
                <CloseButton onClick={() => props.setShow(false)}/>
            </Modal.Header>

            <Modal.Body>
                <ListGroup className="list-group-flush">
                    <ListGroupItem>
                        Používáme cookies, abychom Vám umožnili pohodlné prohlížení webu a díky analýze provozu webu neustále zlepšovali jeho funkce, výkon a použitelnost.
                    </ListGroupItem>

                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-center"
                    >
                        <div className="ms-2 me-auto">
                            <div><b>Nezbytné</b> (vždy aktivní nelze vypnout)</div>
                            Tyto cookies jsou potřeba, aby web fungoval správně.
                        </div>
                        <Form.Check
                            type="switch"
                            id="custom-switch1"
                            value={true}
                            checked
                            disabled
                        />
                    </ListGroup.Item>

                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-center"
                    >
                        <div className="ms-2 me-auto">
                            <div><b>Analytické</b></div>
                            Pomáhají nám pochopit, jak web používáte. S jejich pomocí ho můžeme zlepšovat
                        </div>
                        <Form.Check
                            type="switch"
                            id="custom-switch2"
                            onClick={() => setAnalytic(!analytic)}
                            checked={analytic}
                        />
                    </ListGroup.Item>

                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-center "
                    >
                        <div className="ms-2 me-auto">
                            <div><b>Profilující</b> (marketingové)</div>
                            Díky těmto cookies vám můžeme zobrazovat relevantní obsah a reklamu, které pro vás mohou být zajímavé a užitečné.
                        </div>
                        <Form.Check
                            type="switch"
                            id="custom-switch3"
                            onClick={() => setMarketing(!marketing)}
                            checked={marketing}
                        />
                    </ListGroup.Item>
                        
                    <ListGroup.Item className="d-flex align-items-center justify-content-end">
                        <Button className='mr-3' variant="danger" onClick={() => { props.setShow(false); props.closeCookies(true);props.handleChoice({marketing:true,analytic:true}) }} >Přijmout vše</Button>
                        <Button variant="light" onClick={() => { props.setShow(false);props.closeCookies(true);props.handleChoice({analytic,marketing}) }}>Uložit nastavení</Button>
                    </ListGroup.Item>

                </ListGroup>

            </Modal.Body>

        </Modal>
    )
}

export default CookiesModal