import React from 'react'
import {Link} from 'react-router-dom'


const CityLinkDelivery = ({text,city_url}) => {
    return (
        <Link to={"/kontakt-rozvoz-jidel/"+city_url}>
            <div className={`city-link-delivery`}>
                <span>{text}</span>
            </div>
        </Link>
    )
}

export default CityLinkDelivery


