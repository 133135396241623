
import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Form, InputGroup, Button, Modal, Image, Spinner } from 'react-bootstrap';


import QuickBite from '../common/QuickBite';
import Icofont from 'react-icofont';
import CityUtilityMeter from '../common/CityUtilityMeter'
import RestaurantGroups from '../RestaurantGroups';

import axios from 'axios';
import { AppContext } from '../../context.js'
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import useFetch from '../../useFetch.js'

import ExtraItems from './ExtraItems.js';
import { HorizontalCategories } from './HorizontalCategories';
import moment from 'moment';
import { size } from 'lodash';
import useWindowSize from '../../useWindowsize';
import useQuery from '../../useQuery';

import google_analytics from '../../models/google_analytics'


const DetailFoodOffers = (props) => {
	const { state, dispatch } = useContext(AppContext)
	const size = useWindowSize();

	const [showAddressModal, setShowAddressModal] = useState(false);
	const [addedItem, setaddedItem] = useState(null)
	const [show, setShow] = useState(false);
	const hideAddressModal = () => setShowAddressModal({ showAddressModal: false });
	const [showChooseRestaurant, setShowChooseRestaurant] = useState(false)
	const [showLargeImage, setShowLargeImage] = useState(false)
	const [chooseRestaurantTempItem, setChooseRestaurantTempItem] = useState(null)
	const [searchInput, setSearchInput] = useState("")

	let { restaurant } = useParams();
	const { menu, loadingMenu } = props

	let query = useQuery();

	useEffect(() => {
		if (menu) {
			google_analytics.menu_view(menu, props.restaurant);
		}

		let cat = query.get("kategorie")
		console.log("Scroll to kategorie = ", cat)
		if (cat)
			scrollToId(cat)
	}, [menu])

	const handleClose = () => setShow(false);
	const handleChangeRestaurant = () => {
		dispatch({ type: 'SET_ACTIVERESTAURANT', payload: props.restaurant })
		dispatch({ type: 'EMPTY_CART' })
		dispatch({ type: 'ADD_TO_CART', payload: chooseRestaurantTempItem })

		setShowChooseRestaurant(false)
		setChooseRestaurantTempItem(null)
	}

	const displayExtraItems = (item) => {
		setaddedItem(item)
		setShow(true)
	};

	//Přidání do košíku
	const handleAddItem = (item) => {
		item.eshop = 0;
		setShow(false)
		if (state.activeRestaurant == null || (props.restaurant.name == state.activeRestaurant.name || (state.activeRestaurant.section_restaurants?.filter(rest => rest.restaurace === props.restaurant.name)?.length > 0))) {
			dispatch({ type: 'SET_ACTIVERESTAURANT', payload: props.restaurant })
			dispatch({ type: 'ADD_TO_CART', payload: item })
		} else {
			setChooseRestaurantTempItem(item)
			setShowChooseRestaurant(true)
		}

	}

	if (loadingMenu) return (
		<Col md={12} className="text-center load-more">
			<Button variant="primary" type="button" disabled="">
				<Spinner animation="grow" size="sm" className='mr-1' />
				Načítání...
			</Button>
		</Col>
	)

	if (Object.keys(menu).length < 2 && Object.keys(menu["Polední menu"]).length === 0) return (
		<Col md={12} className="text-center load-more">
			<h6 className="text-secondary">Zdá se, že tu nic není...</h6>
		</Col>
	)

	return (
		<>
			<RestaurantGroups restaurant={props.restaurant} />

			<Modal className="extra-items" show={showChooseRestaurant}>
				<Modal.Body>

					<Button className="extra-items-close-button" onClick={() => { setShowChooseRestaurant(false) }}><ion-icon className="extra-items-close-button-icon" name="close" /></Button>

					<h5 className="extra-items-title">Upozornění</h5>

					<p className="extra-items-food-description mt-1">Objednávat můžete vždy jen z jedné provozovny.</p>
					<p className="">V košíku máte zboží z {state.activeRestaurant ? state.activeRestaurant.name : ""}</p>
					<p className="">Chcete přejít na {props.restaurant ? props.restaurant.name : ""}</p>

					<Button onClick={() => setShowChooseRestaurant(false)} className="chooseRest chooseRestAlt">Dokončit {state.activeRestaurant ? state.activeRestaurant.name : ""}</Button>
					<Button onClick={handleChangeRestaurant} className="chooseRest">Přejít na {props.restaurant ? props.restaurant.name : ""}</Button>

				</Modal.Body>
			</Modal>

			<Modal size="lg" className="extra-items" show={showLargeImage}>
				<Modal.Body>

					<Button className="extra-items-close-button" onClick={() => { setShowLargeImage(false) }}><ion-icon className="extra-items-close-button-icon" name="close" /></Button>
					{/* <h5 className="extra-items-title">Upozornění</h5> */}

					<Image style={{ "maxWidth": "100%", "padding": "5px" }} className={""} src={`https://www.jidlopodnos.cz/data/jidla/big/${showLargeImage}.png`} alt="" />

				</Modal.Body>
			</Modal>

			<ExtraItems show={show} restaurant={props.restaurant} handleAddItem={handleAddItem} addedItem={addedItem} onClose={handleClose} />
			<Form className="explore-outlets-search mb-4">
				<InputGroup>
					<Form.Control value={searchInput} onChange={e => setSearchInput(e.target.value)} type="text" placeholder="Hledat pokrmy..." />
					<InputGroup.Append>
						{searchInput.length > 0 ?
							<Button onClick={() => setSearchInput("")} style={{ "position": "absolute", "right": "0", "top": "0", "background": "transparent" }} variant="none"><Icofont icon="close-line" /></Button>
							:
							<Button type="button" variant="link">
								<Icofont icon="search" />
							</Button>
						}
					</InputGroup.Append>
				</InputGroup>
			</Form>
			<div className="sticky-top-offset" style={{ "marginRight": "0", maxHeight: "50vh" }}>
				<h4 className="mt-0 mb-2 col-md-12 order-big-card-title pl-0"> Naše Nabídka</h4>
				{size.width >= 767.98 ?
					<div className="mb-3 col-md-12 order-big-card-categories pl-0">
						{menu && Object.keys(menu).map((category, index) => (category != "Polední menu" ? <div key={index} onClick={() => { scrollToId(category.split("~")[0] || category) }} className="order-big-card-single-category">{category.split("~")[0] || category}</div> : ""))}
					</div>
					:
					<HorizontalCategories menu={menu} />
				}
			</div>
			{searchInput.length > 2 ? Object.keys(menu).map(category => (
				category != "Polední menu" ?
					category.length > 0 ? menu[category].filter(y => y.nazev.toLowerCase().includes(searchInput.toLowerCase())).map(item => (
						<QuickBite key={item.polozka_id} item={item} showModal={setShowLargeImage} itemClass="menu-list" onClick={displayExtraItems} />
					)) : ""
					:
					Object.keys(menu[category]).map((lunchCategory, indexLunch) => (
						<>
							<Row key={indexLunch}>
								<Col className="menuCategorySection" md={12}>
									<div className="order-big-card-category-parent rounded mb-4">
										{menu[category][lunchCategory].filter(y => y.nazev.toLowerCase().includes(searchInput.toLowerCase())).map(item => (
											<QuickBite key={item.polozka_id} item={item} showModal={setShowLargeImage} itemClass="menu-list" onClick={displayExtraItems} />
										))}
									</div>
								</Col>
							</Row>
						</>
					))

			))
				:
				menu && Object.keys(menu).map((category, index) => (
					category != "Polední menu" ? <>
						<Row key={index} id={category.split("~")[0] || category}>
							<h4 className="mb-4 mt-3 col-md-12 order-big-card-category-title">{category.split("~")[0] || category}
								<br /><small style={{ "fontSize": "70%", "fontWeight": "initial", "display": "block" }}>
									{category.split("~")[1] || ""}</small></h4>
							<Col className="menuCategorySection" md={12}>
								<div className="order-big-card-category-parent rounded mb-4">
									{menu[category].map(item => (
										<QuickBite key={item.polozka_id} item={item} showModal={setShowLargeImage} itemClass="menu-list" onClick={displayExtraItems} />
									))}
								</div>
							</Col>
						</Row>
					</>
						:
						<>
							{Object.keys(menu[category]).length > 0 && <><h4 className="mb-4 mt-3 col-md-12 order-big-card-category-title pl-0">Polední menu {moment().format("D.M.")} ({props.restaurant?.lunch_from?.slice(0, 5)} - {props.restaurant?.lunch_to?.slice(0, 5)})</h4><hr></hr></>}

							{Object.keys(menu[category]).map((lunchCategory, indexLunch) => (
								<>
									<Row key={indexLunch}>
										<h6 className="mb-4 mt-3 col-md-12 order-big-card-category-title">Polední menu {lunchCategory && lunchCategory != "undefined" && lunchCategory?.length > 0 ? "- " + lunchCategory : ""}</h6>
										<Col className="menuCategorySection" md={12}>
											<div className="order-big-card-category-parent rounded mb-4">
												{menu[category][lunchCategory].map(item => (
													<QuickBite key={item.polozka_id} item={item} showModal={setShowLargeImage} itemClass="menu-list" onClick={displayExtraItems} />
												))}
											</div>
										</Col>
									</Row>
								</>
							))}
						</>
				))}
		</>
	)
}

function scrollToId(id) {
	try {
		const yOffset = -200;
		const element = document.getElementById(id);
		const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

		window.scrollTo({ top: y, behavior: 'smooth' });
	} catch (error) {
		console.log("Scroll to Error ", error)
	}

}

function scrollToURLParamAdd(id) {
	// const urlParams = new URLSearchParams(window.location.search);

	// urlParams.set('kategorie', id);

	// window.history.replaceState(null, null, "?arg=123");
	//window.location.search = urlParams;

	const url = new URL(window.location.href);
	url.searchParams.set('kategorie', `Hlavní jídla`);

	window.history.replaceState(null, null, url);
}

export default DetailFoodOffers


