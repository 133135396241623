import React, { useState } from 'react'
import { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';

export const MinimalOrderAdditionModal = ({minimalOrderAddition}) => {

    const [show, setShow] = useState(false)
    const [lastValue, setLastValue] = useState(false)

    useEffect(() => {
      if(!lastValue && minimalOrderAddition){
        setShow(true)
      }
      setLastValue(minimalOrderAddition)
    }, [minimalOrderAddition])
    

  return (
        <Modal 
        show={show} 
        
        >
    <Modal.Header closeButton={false}>
        <Modal.Title as='h5' id="add-address">Do minimální objednávky vám chybí {minimalOrderAddition} Kč</Modal.Title>
    </Modal.Header>

    <Modal.Body>
        <span>Vaše současná objednávka nedosahuje minimální hodnoty. Objednávku můžete dokončit, rozdíl vám však musíme naúčtovat.</span>
    </Modal.Body>
    

    <Modal.Footer>
          
    <Button type='button' variant="secondary" className='d-flex text-center justify-content-center'><Link to="objednat" style={{color:"white"}}>Zpět na nabídku</Link></Button>
            
        {/* <Button type='button' onClick={this.props.onHide} variant="outline-primary" className="d-flex w-50 text-center justify-content-center">CANCEL</Button> */}
        <Button onClick={()=>{setShow(false)}} type='button' variant="primary" className='d-flex w-50 text-center justify-content-center'>V pořádku</Button>
    </Modal.Footer>
    </Modal>
  )
}
