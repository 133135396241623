import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Row, Col, Container, Button, Image, Badge, Nav, Modal, Spinner } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import { floor } from 'lodash';
import Icofont from 'react-icofont';
import { AppContext } from '../context.js'
import CartItems from './custom/CartItems';
import useWindowSize from '../useWindowsize.js';
import { Tooltip } from 'react-bootstrap';
import { OverlayTrigger } from 'react-bootstrap';

export const CheckoutCart = (props) => {

    const { state, dispatch } = useContext(AppContext)

    const size = useWindowSize();

    const { info, isInCheckout, isOpen, submitNewOrder } = props

    const hideFinishButton = props.hideFinishButton || false

    const [deliverOrPickup, setDeliverOrPickup] = useState(false)

    let totalPrice = Number(state.total.totalPrice) || 0
    let obal = Number(state.total.packaging)
    let doprava = Number(state.total.deliveryPrice)
    let minimalOrderAddition = Number(state.total.minimalOrderAddition)

    useEffect(() => {
        if (state.activeRestaurant)
            dispatch({ type: 'SET_ACTIVERESTAURANT', payload: { ...state.activeRestaurant, deliverOrPickup: deliverOrPickup } })
    }, [deliverOrPickup])

    useEffect(() => {
        if (state.activeRestaurant && state.activeRestaurant.deliverOrPickup)
            setDeliverOrPickup(state.activeRestaurant.deliverOrPickup)
    }, [state.activeRestaurant])

    const renderTooltip = (props2) => (
        <Tooltip id="button-tooltip" className="float-right" {...props2}>
            Naše papírové tašky jsou plně recyklovatelné
        </Tooltip>
    );

    const renderTooltipFreeDelivery = (props2) => (
        <Tooltip id="button-tooltip" className="float-right" {...props2}>
            Do částky pro dosažení rozvozu zdarma se nezapočítá cena dopravy
        </Tooltip>
    );

    return (
        <Container className={`cart-container`} style={{ "marginTop": size.width < 767.98 ? "90px" : "auto" }}>
            {/* Vaše objednávka */}

            <div className="rounded mb-4 p-4 osahan-cart-item order-cart order-cart-border">

                <>
                    {state?.isMultiRest ?
                        <h5 className="mb-1 order-cart-title">Sdružená objednávka</h5>
                        :
                        <h5 className="mb-1 order-cart-title">Vaše objednávka {state.activeRestaurant && " - " + state.activeRestaurant.name}</h5>
                    }


                    <p className="mb-4">{state.cart && state.cart.reduce((a, b) => a + (b.quantity || 0), 0)} položek</p>

                    <div className="bg-white rounded mb-2 cartItems-wrap">
                        <CartItems restaurant={state.activeRestaurant && state.activeRestaurant.name} />
                    </div>
                    <div className="order-cart-transport">
                        <div className="text-center">
                            <input id="deliveryCheck" className="order-cart-transport-checkbox mr-1" type="radio" defaultChecked={state.activeRestaurant && state.activeRestaurant.deliverOrPickup ? false : true} onChange={() => { setDeliverOrPickup(false) }} name="transport" /><label for="deliveryCheck" className="mr-2">Chci dovézt</label>
                            <input id="pickupCheck" disabled className="order-cart-transport-checkbox mr-1" type="radio" defaultChecked={state.activeRestaurant && state.activeRestaurant.deliverOrPickup ? true : false} onChange={() => { setDeliverOrPickup(true) }} name="transport" /><label for="pickupCheck">Vyzvednu si <small>(Již brzy)</small></label>
                        </div>
                    </div>

                    <div className="order-cart-added-item">
                        <p className="mb-0 order-cart-added-item-price">{obal || 0} Kč</p>
                        <p className="mb-0 order-cart-added-item-text">Obaly</p>
                    </div>
                    {state.total?.delivery_orderpackaging > 0 && <div className="order-cart-added-item">
                        <p className="mb-0 order-cart-added-item-price">{state.total.delivery_orderpackaging} Kč</p>
                        <p className="mb-0 order-cart-added-item-text">Obal za objednávku
                            <OverlayTrigger
                                style={{ "marginLeft": "3rem" }}
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                            ><Icofont size="1" icon="info-circle" className="ml-1" />{ }</OverlayTrigger>
                        </p>
                    </div>}
                    {!deliverOrPickup &&
                        <div className="order-cart-added-item">
                            {doprava == 0 ?
                                <p className="mb-0 order-cart-added-item-price">zdarma</p>
                                : !state.activeRestaurant ?
                                    <p className="mb-0 order-cart-added-item-price">{state?.location ? "":"od "} {Number(info?.delivery_price) + Number(info?.vlastni == "a" ? 0 : Number(state?.location?.doprava || 0))} Kč</p>
                                    :
                                    <p className="mb-0 order-cart-added-item-price">  {state?.location ? "":"od "}  {doprava} Kč</p>
                            }
                            <p className="mb-0 order-cart-added-item-text">Doprava</p>
                        </div>
                    }
                    {(!deliverOrPickup && state.activeRestaurant && (state.location?.nofreedelivery || "n") !== "a" && state.activeRestaurant.delivery_free && (state.activeRestaurant.delivery_free + state.activeRestaurant.delivery_price - totalPrice) > 0) ?
                        <div className="order-cart-added-item">
                            <p className="mb-0 order-cart-added-item-price">{state.activeRestaurant.delivery_free + state.activeRestaurant.delivery_price - totalPrice} Kč</p>
                            <p className="mb-0 order-cart-added-item-text green-text">Do dopravy zdarma zbývá <OverlayTrigger
                                style={{ "marginLeft": "3rem" }}
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltipFreeDelivery}
                            ><Icofont size="1" icon="question-circle" className="ml-1" />{ }</OverlayTrigger></p>
                        </div> : ""}

                    {state.activeRestaurant && state.activeRestaurant.isBonus != 0 &&
                        <div className="order-cart-added-item">
                            <p className="mb-0 order-cart-added-item-price">{floor((totalPrice + state.totalAddition.creditsUsed - doprava) * (state.activeRestaurant.percentage_bonus/100))}</p>
                            <p className="mb-0 order-cart-added-item-text green-text">Počet kreditů za tuto objednávku</p>
                        </div>
                    }
                    {/* {state.activeRestaurant && Number(props.minimal_order) > (totalPrice + state.totalAddition.creditsUsed - doprava) && !isInCheckout &&
                        <div className="order-cart-added-item">
                            <p className="mb-0 order-cart-added-item-price ">{Number(props.minimal_order) + doprava - totalPrice - state.totalAddition.creditsUsed} Kč</p>
                            <p className="mb-0 order-cart-added-item-text" style={{ "color": "#C62727" }}> Do minimální ceny objednávky zbývá <Icofont icon="exclamation-circle" style={{ "fontSize": "1.3rem" }} /></p>
                        </div>} */}
                    {state.activeRestaurant && minimalOrderAddition > 0 &&
                    <div className="order-cart-added-item">
                        <p className="mb-0 order-cart-added-item-price ">{minimalOrderAddition} Kč</p>
                        <p className="mb-0 order-cart-added-item-text" style={{ "color": "#C62727" }}> Doplatit minimální objednávku ve výši <Icofont icon="exclamation-circle" style={{ "fontSize": "1.3rem" }} /></p>
                    </div>}
                    {state.totalAddition.tipCourier > 0 &&
                        <div className="order-cart-price-total">
                            <p className="mb-0 order-cart-price-total-price"><small>+{state.totalAddition.tipCourier} Kč</small></p>
                            <p className="mb-0 order-cart-price-total-text"><small>Spropitné pro kurýra</small> </p>
                        </div>}
                    {state.totalAddition.creditsUsed > 0 &&
                        <div className="order-cart-price-total">
                            <p className="mb-0 order-cart-price-total-price"><small>-{state.totalAddition.creditsUsed} Kč</small></p>
                            <p className="mb-0 order-cart-price-total-text"><small>Použité kredity</small> </p>
                        </div>}
                    <div className="order-cart-price-total">
                        <p className="mb-0 order-cart-price-total-price">{totalPrice} Kč</p>
                        <p className="mb-0 order-cart-price-total-text">Zatím celkem <small>(vč. DPH)</small></p>
                    </div>

                    {!hideFinishButton && state.cart.length > 0 && !isInCheckout ? <NavLink className="nav-link" exact to={"/rozvoz-jidel/" + state.activeRestaurant.mesto_url + "/" + state.activeRestaurant.rest_url + "/" + "platba"} className="mt-4 order-cart-submit">{isOpen ? "Objednat" : "Objednat na později"}</NavLink> : <Button onClick={() => scrollTo("submit-newcheckout-button")} className="mt-4 order-cart-submit">Pokračovat k objednávce</Button> }
                    {/* <NavLink className="nav-link" exact to="platba" className="mt-4 order-cart-submit">{isInCheckout ? "Dokončit objednávku ":"Objednat"}</NavLink> 
                        <Button onClick={isInCheckout?()=>{}:()=>{window.location.href =  window.location.href.split('/').slice(0,-1).join('/')+'/platba'}}  className="mt-4 order-cart-submit">{isInCheckout ? "Dokončit objednávku ":"Objednat"}</Button>  */}
                </>

            </div>


            {/* /End vaše objednávka */}
        </Container>
    )
}

function scrollTo(id) {
    try {
        const yOffset = -300;
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
    } catch (error) {
        console.log("Scroll to Error")
    }

}