import React from 'react'
import WhereWeAreDelivery from './custom/WhereWeAreDelivery'

const ContactDeliveryFood = () => {
    return (
        <div style={{"paddingTop":"110px"}}>
            <WhereWeAreDelivery/>
        </div>
    )
}

export default ContactDeliveryFood
