import React from 'react'
import AddressSwitch from './AddressSwitch'
import CheckboxFilter from './CheckboxFilter'
import NameFilter from './NameFilter'
import { Row,Col } from 'react-bootstrap'

export default function FilterRest(props) {
    return (
        <div style={{padding:"15px 0px 0px 0px"}}>
            
            <h6>Filtrovat restaurace:</h6>

            <Row>
            <Col sm={12} md={3}><NameFilter/></Col>

            <Col sm={12} md={9} style={{padding:".5rem 1rem"}}><CheckboxFilter/></Col>
            {props.multiCity &&<Col sm={12} md={12}><AddressSwitch/></Col>}
            </Row>
        </div>
    )
}
