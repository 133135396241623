import {
    AppTrackingTransparency,
    AppTrackingStatusResponse,
  } from 'capacitor-plugin-app-tracking-transparency';

export const checkTrackingPermision = async (dispatch)=>{

    ios_requestPermission()

    let result = await ios_getStatus()

    if(result?.status && result.status == "authorized"){
        dispatch({ type: 'ENABLE_TRACKING' })
    }

}



let ios_getStatus = async ()=>{
    const response = await AppTrackingTransparency.getStatus();
  
    console.log(response);
    // { status: 'authorized' } for example
  
    return response;
}
  
let ios_requestPermission = async ()=>{ //Zeptá se na povinné povolení o sledování uživatele
const response = await AppTrackingTransparency.requestPermission();

console.log(response);
// { status: 'authorized' } for example

return response;
}


//Funkce slouží pro odesílání konverzí na trackovací služby. (Google gtag, sklik)
//volá se pokud user_tracking true
export const checkForConversions = (order_number,data) => {

    console.log("Conversion: "+order_number,data)
    console.log("Google GAQ:",window._gaq)
    try {
        
    
    window._gaq.push(['_addTrans',
      order_number,           // transaction ID - required
      "JidloPodNos",
      data.payment.totalCheck,          // total - required
      '',           // tax
      '',              // shipping
      '',       // city
      '',     // state or province
      'ČR'             // country
    ]);
  /*   data.cart.forEach(element => {
      window._gaq.push(['_addItem',
        order_number,      // transaction ID - necessary to associate item with transaction
        element.nazev,           // SKU/code - required
        element.cena,          // unit price - required
        element.quantity            // quantity - required
      ]);
    }); */
    window._gaq.push(['_addItem',
      order_number,      // transaction ID - necessary to associate item with transaction
      'JidloPodNos',           // SKU/code - required
      data.payment.totalCheck,          // unit price - required
      '1'               // quantity - required
    ]);
  
    window._gaq.push(['_trackTrans']);
  
    window.gtag('event', 'conversion', {
      'send_to': 'AW-1003115517/JxEmCJzM4a8BEP2nqd4D',
      'value': data.payment.totalCheck,
      'currency': 'CZK',
      'transaction_id': ''+Math.floor(new Date())
    })

    console.log("sklik conversion")
    var conversionConf = {
        id: 100135278,
        value: null
    };
      
    if (window.rc && window.rc.conversionHit) {
    
      window.rc.conversionHit(conversionConf);
    
    }
  
    console.log("Conversion check finished")
    } catch (error) {
            
    }
  }