import React from 'react'
import MayLikeItem from '../common/MayLikeItem';
import OwlCarousel from 'react-owl-carousel3';
import PropTypes from 'prop-types'; 

const ExtraDrinks = () => {
	const mayLikeItems = [
		{"title": "Coca Cola", "price" : "40 Kč", "image":require("../../resources/Examples/cocaCola.png"),"imageClass":"img-fluid","imageAlt":"carousel"},
		{"title": "Fanta", "price" : "40 Kč", "image":require("../../resources/Examples/fanta.png"),"imageClass":"img-fluid","imageAlt":"carousel"},
		{"title": "Coca Cola", "price" : "40 Kč", "image":require("../../resources/Examples/cocaCola.png"),"imageClass":"img-fluid","imageAlt":"carousel"},
		{"title": "Fanta", "price" : "40 Kč", "image":require("../../resources/Examples/fanta.png"),"imageClass":"img-fluid","imageAlt":"carousel"},
		{"title": "Coca Cola", "price" : "40 Kč", "image":require("../../resources/Examples/cocaCola.png"),"imageClass":"img-fluid","imageAlt":"carousel"},
		{"title": "Fanta", "price" : "40 Kč", "image":require("../../resources/Examples/fanta.png"),"imageClass":"img-fluid","imageAlt":"carousel"},

	]
    return (
        <div className="mt-2">
        <OwlCarousel nav loop {...options} className="owl-theme owl-carousel-five offers-interested-carousel">
			{mayLikeItems ? (mayLikeItems.map((item) => (
				<div className="item">
					<MayLikeItem 
						title={item.title}
						price={item.price}
						image={item.image}
						imageClass={item.imageClass}
						imageAlt={item.imageAlt}
					/>
				</div>
				
			))):""}
	         
	         
	    </OwlCarousel>
          </div>
    )
}
const options={
	responsive: {
        0:{
            items:2,
        },
        600:{
            items:3,
        },
        1000: {
          items: 4,
        },
        1200: {
          items: 5,
        },
      },
    lazyLoad: true,
    pagination: "false",
    loop: true,
    dots: false,
    autoPlay: 2000,
    nav: true,
    navText: ["<i class='icofont-thin-left'></i>", "<i class='icofont-thin-right'></i>"]
}
export default ExtraDrinks

ExtraDrinks.propTypes = {
    mayLikeItems: PropTypes.array,
    
};

ExtraDrinks.defaultProps = {
    mayLikeItems: [],
}