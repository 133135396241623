import React,{useContext,useEffect,useState} from 'react';
import TopSearch from './home/TopSearch';
import AppBanner from './custom/AppBanner';
import WhereAreWe from './custom/WhereAreWe';
import BecomeMember from './custom/BecomeMember';
import AdvertisingBanners from './custom/AdvertisingBanners';
import {AppContext} from '../context.js'
import {Redirect} from  'react-router-dom'
import { Helmet } from 'react-helmet';
import {useLocation} from "react-router-dom";
import WelcomeModal from './home/WelcomeModal';
import { useParams } from "react-router-dom";


import RestaurantOffers from './custom/RestaurantOffers';
import { HowItWorks } from './custom/HowItWorks';



const Index = (props)=> {
	const {state, dispatch} = useContext(AppContext)

	let query = useQuery();
	let { id } = useParams(); 

	const [showWelcome, setShowWelcome] = useState(false)

	useEffect(() => {
		if(query.get("message")=="welcome"){
			setShowWelcome(true)
		}
	}, [])

	useEffect(() => {
		if(query.get("kategorie")){
			dispatch({type: 'SET_RESTAURANT_FILTER', payload: query.get("kategorie")})
		}
	}, [query.get("kategorie")])

	useEffect(() => {
		if(state.location?.mesto_url && id &&  id!==state.location.mesto_url){
			dispatch({ type: 'REMOVELOCATION' })
		}
	}, [id])

	
    	return (
    		<>
				{state.location &&
				<Helmet>
					<title>Rozvoz jídla {state.location.mesto} - Jidlopodnos</title>
				</Helmet>}
				<WelcomeModal show={showWelcome}></WelcomeModal>

    			<TopSearch didSearch = {props.didSearch}/>

				{/* {state.location? <Redirect to={`/rozvoz-jidel/${state.location.mesto_url}`} />:""} */}
				{props.didSearch &&
				<><AdvertisingBanners/><RestaurantOffers/></>
				}
				<> 
				{state.platform == "web" && <AppBanner/>}
				<WhereAreWe/></> <HowItWorks/>{/* <BecomeMember/>  */}
    		</>
    	);
    }

	function useQuery() {
		return new URLSearchParams(useLocation().search);
	}



export default Index;