import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Index from './components/Index';
import MyAccount from './components/MyAccount';
import NotFound from './components/NotFound';
import Thanks from './components/Thanks';
import ResetPassword from './components/ResetPassword';
import ActivateAccount from './components/ActivateAccount';
import TrackOrder from './components/TrackOrder';
import Contact from './components/Contact';
import Detail from './components/Detail';
import Podminikyuzitistranek from './components/static-pages/Podminikyuzitistranek';
import VOP from './components/static-pages/VOP';
import { Alerts } from './components/Alerts';
import { Notifications } from './components/Notifications';
import { AppUrlListener } from './components/AppUrlListener';
import URLHandler  from './components/PoledniMenu';
import KontaktSpoluprace from './components/Collaboration';
import StanSeKuryrem from './components/CourierForm';
import JakNaKredity from './components/HowToUseCredits';
import Soukromi from './components/static-pages/soukromi';
import { Conversions } from './components/Conversions';

import KontaktRozvozJidel from './components/ContactDeliveryFood';
import SeznamAlergenu from './components/static-pages/SeznamAlergenu';

import {Helmet} from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-select2-wrapper/css/select2.css';

import {AppContextProvider} from './context'
import { ProtectedRoute } from "./protectedRoute.js";

import './scss/mixins.scss';
import './scss/App.scss';
import './scss/Custom.scss';
import PoledniMenu from './components/PoledniMenu';
import Icofont from 'react-icofont';
import { Jumbotron } from 'react-bootstrap';

import { Capacitor } from '@capacitor/core';
//import 'capacitor-plugin-app-tracking-transparency'; // only if you want web support

import {
  AppTrackingTransparency,
  AppTrackingStatusResponse,
} from 'capacitor-plugin-app-tracking-transparency';
import Cookies from './components/Cookies';

const didSearch = false;
const onMobileApp = false;
const userLoggedIn = false;


class App extends React.Component  {

  constructor(props) {
    super(props);
    this.state = { hasError: false };
    ios_requestPermission()
    
  }


  static getDerivedStateFromError(error) {
    return { hasError: error };
  }  

  
  render() {

    if (this.state.hasError) {

      const errorLogContent = {
        type: "React APP crash error App.js catch", // You can adjust this to whatever categorization you'd prefer
        error: this.state.hasError,
        url: window.location.href,  // Include the page URL where the error occurred
        userAgent: navigator.userAgent,  // Include browser and OS details
      };
    
      // Send the error log to your existing API endpoint
      fetch('/api/orders/error', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(errorLogContent),
      })

      localStorage.clear();
      return (<Jumbotron>
        <h1>Nastala chyba. Pokuste se obnovit stránku.</h1><br/>
        <p>{this.state.hasError}</p>
        </Jumbotron>);
    }

    return (
      <AppContextProvider >
          <Helmet>
            <title>Jidlopodnos</title>
            {window.Capacitor?.platform && window.Capacitor.platform != "web" ?
            <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover, user-scalable=no" />
            :
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            }

          </Helmet>
          {
            (this.props.location.pathname!=='/login' && this.props.location.pathname!=='/register') ? <Header didSearch = {didSearch} userLoggedIn = {userLoggedIn}/>:''
          }
          <div className="contentWrap">
          <Switch>
            
            <Route path="/" exact component={() => <Index didSearch = {false} onMobileApp = {onMobileApp} userLoggedIn = {userLoggedIn} />} />
            <ProtectedRoute path="/mujucet" component={MyAccount} />
            <Route path="/404" exact component={NotFound} />
            {/* <Route path="/invoice" exact component={Invoice} /> */}

            <Route path="/hotovo" exact component={Thanks} />
            <Route path="/hotovo/:year/:cislo" exact component={Thanks} />
            <Route path="/obnova-hesla" exact component={ResetPassword} />
            <Route path="/aktivace-uctu" exact component={ActivateAccount} />
            <Route path="/detail" component={Detail} />
            <Route path="/rozvoz-jidel/:id/:restaurant" component={Detail} />
            <Route path="/rozvoz-jidel/:id" children={<Index didSearch = {true} onMobileApp = {onMobileApp} userLoggedIn = {userLoggedIn} />} />
            <Route path="/objednavka/:year/:cislo" component={TrackOrder} />
            <Route path="/kontakt-rozvoz-jidel/:id" component={Contact} />
            <Route path="/kontakt-rozvoz-jidel" component={KontaktRozvozJidel} />
            <Route path="/podminky-uziti-stranek" component={Podminikyuzitistranek} />
            <Route path="/seznam-alergenu" component={SeznamAlergenu} />
            <Route path="/vseobecne-obchodni-podminky/:id" component={VOP} />
            <Route path="/poledni-menu/:city" component={PoledniMenu} />
            <Route path="/kontakt-spoluprace" component={KontaktSpoluprace} />
            <Route path="/stan-se-kuryrem" component={StanSeKuryrem} />
            <Route path="/cerpani-kreditu" component={JakNaKredity} />
            <Route path="/soukromi" component={Soukromi} />
            
            <Route exact component={NotFound} />
          </Switch>
          </div>
          {
            this.props.location?.href?.includes("jidlopodnos.cz/poledni-menu-") ? <Redirect to={"/poledni-menu/"+this.props.location.pathname.slice(14,)}></Redirect>:""
          }
          {
            (this.props.location.pathname!=='/login' && this.props.location.pathname!=='/register') ? <Footer/>:''
          }
          <Alerts/> 
          <Notifications/>
          <AppUrlListener/>
          <Conversions/>
          <Cookies/>
      </AppContextProvider >
    );
  }
}

let ios_getStatus = async ()=>{
  const response = await AppTrackingTransparency.getStatus();

  console.log(response);
  // { status: 'authorized' } for example

  return response;
}

let ios_requestPermission = async ()=>{ //Zeptá se na povinné povolení o sledování uživatele
  const response = await AppTrackingTransparency.requestPermission();

  console.log(response);
  // { status: 'authorized' } for example

  

  return response;
}

export default App;
