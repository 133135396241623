import React,{useContext,useEffect,useState} from 'react';
import {Link} from 'react-router-dom';
import {Row,Col,Container, Button, Spinner, FormControl,InputGroup,Form} from 'react-bootstrap';
import Icofont from 'react-icofont';
import useFetchARefresh from '../useFetchAutoRefresh'
import {useParams} from 'react-router-dom';
import {AppContext} from '../context.js'
import { useForm } from 'react-hook-form';
import axios from 'axios';
import useWindowSize from '../useWindowsize';

 

const ReviewOrder = (props)=> {

	let { guid,hodnoceno } = props 
	const {state, dispatch} = useContext(AppContext)

	const size = useWindowSize();
	
	const [foodReview, setFoodReview] = useState(false)
	const [deliveryReview, setDeliveryReview] = useState(false)
	const [reviewText, setReviewText] = useState("")

	const [submiting, setSubmiting] = useState(false)
	const [done, setDone] = useState(hodnoceno)

	const submitReview = ()=> {
		setSubmiting(true)
		axios({
			method: 'post',
			url: '/api/orders/review',
			data:{
				guid,
				foodReview,
				deliveryReview,
				reviewText
			}
		}).then(response => {
			
			setSubmiting(false)
			if (response.status == 200) {
				setDone(true)
			} else {
				
			}

		}).catch(error => {
			
		})
	}

	if(done || props.hodnoceno) return (
		<section className="section bg-white osahan-track-order-page position-relative mx-auto w-50">
			
			<h3><Icofont style={{"fontSize":"1em"}} icon="tick-boxed"/> Hodnocení odesláno</h3>
			<p>Děkujeme za ohodnocení objednávky.</p>
		</section>
	)

	return (
		<section className={`section bg-white osahan-track-order-page position-relative mx-auto ${size.width >= 767.98 && "w-50"}`}>
			<h3>Hodnotit objednávku</h3>
			<p>Dejte nám vědět, jak jste byli spokojeni.</p>
			
			<h5 className="text-primary mb-3">Jak jste byli spokojeni s objednanými produkty?</h5>
			<Form.Label className={size.width <= 767.98 && "w-100"} style={{"maxWidth":"80%"}} htmlFor={""}  >
				<Form.Check className="mr-0 ml-3" required type="radio" onChange={()=>{setFoodReview(1)}} inline name={"r1"} id={""} />
				Hrůza
			</Form.Label>
			<Form.Label className={size.width <= 767.98 && "w-100"} style={{"maxWidth":"80%"}} htmlFor={""}  >
				<Form.Check className="mr-0 ml-3"  required type="radio" onChange={()=>{setFoodReview(2)}} inline name={"r1"} id={""} />
				Nic moc
			</Form.Label>
			<Form.Label className={size.width <= 767.98 && "w-100"} style={{"maxWidth":"80%"}} htmlFor={""}  >
				<Form.Check className="mr-0 ml-3"  required type="radio" onChange={()=>{setFoodReview(3)}} inline name={"r1"} id={""} />
				Průměr
			</Form.Label>
			<Form.Label className={size.width <= 767.98 && "w-100"} style={{"maxWidth":"80%"}} htmlFor={""}  >
				<Form.Check className="mr-0 ml-3"  required type="radio" onChange={()=>{setFoodReview(4)}} inline name={"r1"} id={""} />
				Super
			</Form.Label>
			<Form.Label className={size.width <= 767.98 && "w-100"} style={{"maxWidth":"80%"}} htmlFor={""}  >
				<Form.Check className="mr-0 ml-3"  required type="radio" onChange={()=>{setFoodReview(5)}} inline name={"r1"} id={""} />
				Absolutně nejlepší
			</Form.Label>

			<br/><br/>
			<h5 className="text-primary mb-3">Jak jste byli spokojeni s dovozem?</h5>
			<Form.Label className={size.width <= 767.98 && "w-100"} style={{"maxWidth":"80%"}} htmlFor={""}  >
				<Form.Check className="mr-0 ml-3" required type="radio" onChange={()=>{setDeliveryReview(1)}} inline name={"r2"} id={""} />
				Trvalo to moc dlouho
			</Form.Label>
			<Form.Label className={size.width <= 767.98 && "w-100"} style={{"maxWidth":"80%"}} htmlFor={""}  >
				<Form.Check className="mr-0 ml-3"  required type="radio" onChange={()=>{setDeliveryReview(2)}} inline name={"r2"} id={""} />
				Mohlo to být rychlejší
			</Form.Label>
			<Form.Label className={size.width <= 767.98 && "w-100"} style={{"maxWidth":"80%"}} htmlFor={""}  >
				<Form.Check className="mr-0 ml-3"  required type="radio" onChange={()=>{setDeliveryReview(3)}} inline name={"r2"} id={""} />
				Průměr
			</Form.Label>
			<Form.Label className={size.width <= 767.98 && "w-100"} style={{"maxWidth":"80%"}} htmlFor={""}  >
				<Form.Check className="mr-0 ml-3"  required type="radio" onChange={()=>{setDeliveryReview(4)}} inline name={"r2"} id={""} />
				Dobrý čas
			</Form.Label>
			<Form.Label className={size.width <= 767.98 && "w-100"} style={{"maxWidth":"80%"}} htmlFor={""}  >
				<Form.Check className="mr-0 ml-3"  required type="radio" onChange={()=>{setDeliveryReview(5)}} inline name={"r2"} id={""} />
				To byla ale rychlost
			</Form.Label>
			<br/><br/>
			<h5 className="text-primary">Máte nějaký komentář?</h5>
			<textarea style={{"maxWidth":"80%"}} onChange={(e)=>setReviewText(e.target.value)} cols="35"></textarea>
			<br/><br/>
			{!submiting && <Button disabled={!(foodReview&&deliveryReview)} onClick={submitReview}>Odeslat hodnocení</Button>}
	      </section>
    	);
    
}


export default ReviewOrder;