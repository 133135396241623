import React,{useState,useEffect,useContext, useCallback} from 'react';
import {Link} from 'react-router-dom';
import {Row,Col,Container,Form,Button,Spinner} from 'react-bootstrap';
import Icofont from 'react-icofont';
import axios from 'axios'
import {AppContext} from '../context.js'
import { useHistory } from "react-router-dom";
import AddAddressModal from './modals/AddAddressModal';
import useWindowSize from '../useWindowsize';
            


function AddressSearch (props) {

		const {state, dispatch} = useContext(AppContext)

		const [searchInput, setsearchInput] = useState("")

		const [searchResult, setsearchResult] = useState(props.addresses || []) // props.addresses ||
		const [resultSelected, setResultSelected] = useState(false)
		const [searchLoading, setSearchLoading] = useState(false)
		const [searchError, setSearchError] = useState(false)

		const [preventResolve, setPreventResolve] = useState(false)

		let history = useHistory();
		const size = useWindowSize();	

		let timer;


		useEffect(() => {
			setsearchResult(props.addresses)
		}, [props.addresses])

		useEffect(() => { 
			setSearchError("")
			if(searchInput.length>2){
				
				clearTimeout(timer);
				var ms = 200; // milliseconds
				timer = setTimeout(function() {
				let timestamp = new Date().getTime()
				axios.get("https://api.jidlopodnos.cz/api.php/ruian?text="+searchInput) //https://api.jidlopodnos.cz/api.php/ruian?text= || /api/location/inlineSearch?query=
				.then(response => {
					console.log(response.data,timestamp,new Date().getTime())

					if(response.data && response.data.length>0){
						setsearchResult(response.data)
					}else{
						setsearchResult([])
						setSearchError("Adresa nenalezena")
					}
					/*
					else{
						resultsMesto([])
						setSearchError("Město nenalezeno.")
					}	 */
				})
				.catch(error => { console.log("Error");console.log(error); });
				}, ms);
			}
			
		}, [searchInput]) 

		useEffect(() => {
			if(state.location){
				setResultSelected(state.location.adresa)
				//setsearchInput()
			}
		}, [])

		

		//POLYGONY
		const resolveLocation = useCallback((r)=>{ //Default set location in global context
			//if props.requireCity => check city match
			setsearchResult([])

			if(resultSelected || r.gps_x){
				let location = resultSelected ? resultSelected:r;
				setSearchLoading(true)

				
				axios.get(`https://api.jidlopodnos.cz/api.php/gpstest/${location.gps_x}/${location.gps_y}`).then(response =>{
				
					console.log(response)
					setSearchLoading(false)
					if(response.status==200){

							if(response.data.oblast?.length==0){
								return setSearchError("Do této oblasti momentálně nedoručujeme")
							}

							setIsResolvingGPS(false)
							//console.log(response.data)
							let gpsResult
							let polygonRestaurants
							let isMulti = false
							if(Array.isArray(response.data.oblast)){
								if(props.requireCity){
									let findMatchingCity = response.data.oblast.find(oblast=>oblast.mesto==props.requireCity)
									console.log("adressearch, findmatchingcity: ",findMatchingCity)
									gpsResult = findMatchingCity || response.data.oblast[0]
								}else{
									gpsResult = response.data.oblast[0]
								}
								polygonRestaurants = response.data.restaurace
								console.log("Founde multiple gps: ",response.data)
								if(response.data.oblast.length>1)
								isMulti = true
							}else{
								gpsResult = response.data.oblast
								console.log("Singlelocation found",gpsResult)
							}
						
						if(gpsResult && gpsResult.code!=="error"){
							let mesto_url = gpsResult.mesto;
							if(props.requireCity && props.requireCity !== gpsResult.mesto){ //Kontrola při aktivní restauraci v checkout
								setSearchError("Neplatná adresa pro zvolenou restauraci")
								return
							}
							
							mesto_url = mesto_url.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/\s+/g, '-');
							console.log("Location",location)
							
							let locationData = {source:"AddressSearch",...gpsResult,mesto_url:mesto_url,polygonRestaurants,adresa:{...location,kod_adm:location.kod_adm||location.id,string:location.string||location.name}}
							if(isMulti) locationData.multiLocation = response.data.oblast
							
							dispatch({ type: 'LOADLOCATION', payload: locationData })
							if(props.isHomepage){
								setTimeout(() => {
									history.push("/rozvoz-jidel/"+mesto_url)
								}, 150); // Adjust delay as needed
								
							}
						}else{
							setSearchError((response.data.message || gpsResult.message))
						}
					}else{
						console.log("chyba serveru")
					}
					
					
				})
			}else{
				setSearchError("Zadejte celou adresu")
			}
		},[resultSelected])

		const returnLocation = useCallback((r)=>{ //props.isSearchOnly == true vrací skrz setLocationFound
			console.log("Return location",resultSelected)
			setsearchResult([])
			if(resultSelected || r){	
				let location = resultSelected ? resultSelected:r;
				axios.get(`https://api.jidlopodnos.cz/api.php/gps/${location.gps_x}/${location.gps_y}`).then(response =>{
					console.log(response)
					if(response.status==200){
						if(response.data.mesto){
							let mesto_url = response.data.mesto;
							mesto_url = mesto_url.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/\s+/g, '-');
							console.log("Location returned",location)
							setsearchResult([])
							props.setLocationFound(location.id)
							setsearchInput(location.name)
							//dispatch({ type: 'LOADLOCATION', payload: {...response.data,mesto_url:mesto_url,adresa:{...location,string:location.typ_so=="č.p." ? location.nazev_ulice+" "+location.cd+"/"+location.co+", "+location.nazev_obec+" "+location.psc:location.nazev_obec+" č.e. "+location.cd+" "+location.psc}} })
							//history.push("/rozvoz-jidel/"+mesto_url)
						}else{
							setSearchError(response.data.message)
						}
					}else{
						console.log("chyba serveru")
					}
					
					
				}).catch(e=>{console.log(e)})
			}
		},[resultSelected])

		const [isResolvingGPS, setIsResolvingGPS] = useState(false)
		const locateByGPS = async()=>{
			if (navigator.geolocation) {
				setIsResolvingGPS(true)
				navigator.geolocation.getCurrentPosition(({coords})=>{
					fetch(`https://api.jidlopodnos.cz/api.php/gpsreverse/${coords.latitude}/${coords.longitude}/`)
					//fetch(`https://api.jidlopodnos.cz/api.php/gpsreverse/50.3397469/14.4925097/`)
						.then(response => response.json())
						.then(result=>{
							resolveLocation(result)
						})
				})
			  } else {
				alert("Zařízení nepodporuje geolokaci.")
				setIsResolvingGPS(false)
			  }
		}

    	return (<>
	                  <div className={`${props.isHomepage && "homepage-search-form"}`}>
	                     <Form className="form-noborder">
	                        <div className="form-row">
	                           <Form.Group className={`${props.isHomepage && "col-lg-10 col-md-10" } col-sm-12 marginRightMobile`}>
	                              
								  {/* Vyhledávač input */}
								  <Form.Control onFocus={()=>{size.width < 767.98 && scrollTo("inputSearch")}} id="inputSearch" className="marginRightMobile" type="text" placeholder={"Zadejte místo dodání, příklad: Vodíčkova 1, Praha"} size='lg' onChange={e => {setsearchInput(e.target.value);setResultSelected(false);setSearchError(false)}} value={searchInput} />
								  {false && !props.isSearchOnly && size.width > 767.98 && <Button onClick={locateByGPS} variant="link" className="locate-me" to="#">{isResolvingGPS ? <><Icofont icon='location-arrow'/> Načítání...</>:<><Icofont icon='ui-pointer'/> Najděte Mě</>}</Button>}
								  {/* Výsledky hledání */}
								 
									<div className="marginRightMobile w-100">
									<ul className="search-results col-md-10"> 
									  {searchResult && searchResult.length>0 ? (searchResult.slice(0,10).map((r) => (
										  <li onClick={()=>{props.isSearchOnly? returnLocation(r) : resolveLocation(r)}}>{(r.name||"") + " "+(r.string||"")}</li>
										))):""} 
								  	</ul>
									</div>
								 
	                           </Form.Group>
							   
							   {size.width < 767.98 &&
							   <Form.Group className='col-lg-2 col-md-2 col-sm-12 pl-0'>
							   {!props.isSearchOnly && <Button onClick={locateByGPS} variant="link" style={{"background":"#ececec none repeat scroll 0 0","width":"100%"}} to="#">{isResolvingGPS ? <><Icofont icon='location-arrow'/> Načítání...</>:<><Icofont icon='ui-pointer'/> Najděte Mě</>}</Button>}
	                           </Form.Group>
							   }
							   {props.isHomepage ? 
	                           <Form.Group className='col-lg-2 col-md-2 col-sm-12 pl-0'>
	                              <Button onClick={resolveLocation} className="btn btn-primary btn-block btn-lg btn-gradient">Hledat</Button>
	                           </Form.Group>
								:""}
	                        </div>
	                     </Form>
						 {searchLoading && <><Spinner animation="grow" size="sm" className='mr-1' /><small>Načítání...</small></>}
						 {searchError && <><h5 className="homepage-search-description text-danger mt-3">{searchError} </h5><br></br></>}
	                  </div>
	    </>)
}


const options2={
	responsive: {
        0:{
            items:2,
        },
        764:{
            items:2,
        },
        765: {
          items: 1,
        },
        1200: {
          items: 1,
        },
      },
      lazyLoad: true,
      loop: true,
      autoplay: true,
      autoplaySpeed: 1000,
      dots: false,
      autoplayTimeout: 2000,
      nav: true,
      navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
      autoplayHoverPause: true,
}

function pscFormater(psc) {
    return psc.replace(/[^\dA-Z]/g, '').replace(/(.{3})/g, '$1 ').trim();
}

function scrollTo(id){
	try {
		const yOffset = -80; 
		const element = document.getElementById(id);
		const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
	
		window.scrollTo({top: y, behavior: 'smooth'});
	} catch (error) {
		console.log("Scroll to Error")
	}

}

export default AddressSearch;