import React, { useState, useContext, useEffect, useRef } from 'react'
import { Button, Form, Row, Col, FormControl, InputGroup, ButtonGroup } from 'react-bootstrap';
import { AppContext } from '../../context.js'
import { useForm } from "react-hook-form";
import axios from 'axios';
import { getLocationString } from '../../getLocationString.js';

import Icofont from 'react-icofont';

import AddressSearch from '../AddressSearch';
import google_analytics from '../../models/google_analytics.js';

const OrderForm = (props) => {

    const { state, dispatch } = useContext(AppContext)

    const orderForm = useRef(null);

    const { register, handleSubmit, setFocus, watch, formState: { errors } } = props.formHook;
    const onSubmit = data => alert(data);

    const removeLocation = () => {
        dispatch({ type: 'REMOVELOCATION' })
    }

    const { setFirstname, setLastname, setPhone, setEmail } = props
    const [overrideAddress, setOverrideAddress] = useState(false)
    const [locationFound, setLocationFound] = useState(false)

    const [addresses, setAddresses] = useState([])

    useEffect(() => {
        if (state.user)
            axios({
                method: 'get',
                url: '/api/location/fav'
            }).then(response => {
                console.log("Result from: /api/location/fav", response)
                if (response.status === 200) {

                    let addresses_array = response.data.map(loc => { return { ...loc, string: getLocationString(loc) } })

                    setAddresses(addresses_array)

                    //if(!state.location)
                    //	resolveLocation(response.data[0])
                    //setUpdate(false)
                }
                else
                    console.log("Načítání adres ze serveru se nepodařilo.")
            })
    }, [state.user])

    useEffect(() => {
        if (locationFound) {
            console.log(locationFound)
            // axios.post().then(response => {
            // 	console.log(response)
            // 	if(response.status===200){
            // 		if(props.update)
            // 			props.update(true)
            // 			//window.location.reload();
            // 		else
            // 			window.location.reload();
            // 	}
            // 	else
            // 		alert("Načítání adres ze serveru se nepodařilo.")
            //   })
        }
    }, [locationFound])
    

    useEffect(() => {
        if (state.location && props.beginCheckoutSended) {
            google_analytics.add_shipping_info(state.cart, state.activeRestaurant, props.totalPrice)
        }
    }, [state.location, props.beginCheckoutSended])



    return (
        <div className="checkout-delivery-address mt-4">
            <div className="checkout-section-header">
                <div className="checkout-section-number">
                    <span>2.</span>
                </div>
                <div className="checkout-section-title">
                    <span >Zadejte doručovací údaje</span>
                </div>
            </div>
            <br></br>
            <Form onSubmit={handleSubmit(onSubmit)} ref={orderForm}>
                <Row style={{ "margin-top": "1rem" }}>
                    <Col lg={6} md={6} sm={12} >
                        <InputGroup className="mb-3 courier-tip-input">
                            <FormControl {...register("firstname", {
                                required: "Zadejte jméno",
                                minLength: {
                                    value: 2,
                                    message: 'Zadejte aspoň 2 písmena' // JS only: <p>error message</p> TS only support string
                                }
                            })}
                                onChange={e => setFirstname(e.target.value)}
                                defaultValue={state.user ? state.user.jmeno : ""}
                                placeholder="Jméno"
                            />
                        </InputGroup>
                        {errors.firstname && <p className="text-primary mb-3">{errors?.firstname?.message}</p>}
                    </Col>
                    <Col lg={6} md={6} sm={12} >
                        <InputGroup className="mb-3 courier-tip-input">
                            <FormControl onChange={e => setLastname(e.target.value)}
                                defaultValue={state.user ? state.user.prijmeni : ""}
                                placeholder="Příjmení"
                            />
                        </InputGroup>
                    </Col>


                    <Col lg={12} md={12} sm={12} >
                        <h6 className="checkout-section-title">Kontaktní údaje</h6>
                    </Col>
                    <Col lg={6} md={6} sm={12} >
                        <InputGroup className="mb-3 courier-tip-input">
                            <FormControl
                                {...register("email", {
                                    required: "Zadejte email",
                                    pattern: {
                                        value: /^\S+@\S+$/i,
                                        message: "Zadejte platný email"
                                    }
                                })}
                                ariaInvalid={"true"}
                                type="email"
                                onChange={e => setEmail(e.target.value)}
                                defaultValue={state.user ? state.user.login : ""}
                                placeholder="Email"
                            />

                        </InputGroup>
                        {errors.email && <p className="text-primary mb-3">{errors?.email?.message}</p>}
                    </Col>
                    <Col lg={6} md={6} sm={12} >
                        <InputGroup className="mb-3 courier-tip-input">
                            <FormControl {...register("phone", {
                                required: "Zadejte telefon",
                                pattern: {
                                    value: /^\+?(\d){4,}$/,
                                    message: "Zadejte platný telefon"
                                }
                            })}
                                onChange={e => setPhone(e.target.value)}
                                defaultValue={state.user ? state.user.telefon : ""}
                                placeholder="Telefon"
                            />

                        </InputGroup>
                        {errors.phone && <p className="text-primary mb-3">{errors?.phone?.message}</p>}
                    </Col>
                </Row>
            </Form>

            {state.location && !overrideAddress ?
                <>
                    <h6 className="checkout-section-chosen-title">Doručovací adresa:  </h6>
                    <span className="pointer" onClick={removeLocation}>{state.location.adresa.string} <Icofont icon="ui-edit" className="input_img" />  </span>
                </>
                :
                <>
                    <br />
                    <h6 className="">Doručovací adresa:</h6>
                    <AddressSearch addresses={addresses} requireCity={state.activeRestaurant?.city} />
                    {props.searchError && <p className="text-danger">Původní adresa odstraněna. {props.searchError}</p>}
                </>
            }

        </div>
    )
}

export default OrderForm