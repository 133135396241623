import React, {useContext, useState} from 'react'
import DropDownTitle from '../common/DropDownTitle';
import {NavDropdown,Form, Button,Modal} from 'react-bootstrap';
import {Navbar,Nav} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import axios from 'axios'
import {AppContext} from '../../context.js'
import { FacebookLogin, FacebookLoginResponse } from '@capacitor-community/facebook-login';
import Icofont from 'react-icofont';



const FacebookLogin_app = () => {

	const context = useContext(AppContext)

	const login = async ()=>{
		const result = await FacebookLogin.login({ permissions: ['email', 'user_birthday', 'user_photos', 'user_gender'] });
		console.log("FACEBOOK RESPONSE:",result)
		if (!result.accessToken) {
		console.log("NO TOKEN")
		return
		}
		console.log(`Facebook access token is ${result.accessToken.token}`);
		axios({
			method: 'post',
			url: '/api/auth/app_facebook/'+result.accessToken.token
		  }).then(response => {
			if(!response.data.error)
				context.dispatch({type: 'LOADUSER', payload: response.data})
			else
				console.log("FB LOGIN ERROR")
		  }).catch(error=>{
			console.log("FB LOGIN ERROR",error)
		  })
	}
    return (
		<button onClick={login} style={{"border":"none","padding":"0","background":"white"}}>
		<Nav.Link>
		<Icofont icon='facebook' size=''/> Přihlásit přes Facebook
		</Nav.Link>
		</button>
    )
}

export default FacebookLogin_app
