import React, { useState,useEffect } from 'react'
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import useWindowSize from '../useWindowsize'
import { useContext } from 'react';
import {AppContext} from '../context.js'
import { useHistory } from 'react-router-dom';


export const Notifications = () => {

    const [notifications, setnotifications] = useState([]);
    const {dispatch} = useContext(AppContext)
    let history = useHistory();

    useEffect(()=>{
        PushNotifications.checkPermissions().then((res) => {
            console.log("CheckNotificationPermission Response:",res.receive,res)
            if (res.receive !== 'granted') {
              PushNotifications.requestPermissions().then((res) => {
                if (res.receive === 'denied') {
                  showMessage('Push Notification permission denied');
                }
                else {
                  showMessage('Push Notification permission granted');
                  register();
                }
              });
            }
            else {
              register();
            }
          });
    },[])
    
    const register = () => {
        console.log('Initializing HomePage');

        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();

        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
            (token) => {
                dispatch({ type: 'LOAD_NOTIFICATIONTOKEN',payload: token })
                showMessage(token.value);
            }
        );

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
            (error) => {
                console.log('Error on registration: ' + JSON.stringify(error));
            }
        );

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
            (notification) => {

                showMessage(notification)
                setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
            }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
            (notification) => {
                
                showMessage("Object:",notification)
                history.push("/objednavka/"+notification.notification.data.order_number);
                setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
            }
        );
    }

    const showMessage = (msg) => {
        console.log(msg)
        //alert(msg)
    }



    return (<></>)
}
