import React,{useState,useContext,useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Image,Badge, OverlayTrigger,Tooltip} from 'react-bootstrap';
import Icofont from 'react-icofont';
import CardItemOverlay from '../custom/CardItemOverlay';
import axios from 'axios'
import moment from 'moment';
import {AppContext} from '../../context'


const CardItem = (props)=> {

	const renderTooltip = (props2) => (
		<Tooltip id="button-tooltip" className="float-right" {...props2}>
		  {props.alert_badge_description}
		</Tooltip>
	  );

	const {state, dispatch} = useContext(AppContext)

	const [isFav, setIsFav] = useState(props.isFav)

	const [maxUtility, setMaxUtlity] = useState(false)

	const addToFav = (param)=> {
		
		axios({
			method: 'post',
			url: '/api/user/fav_rest/'+param,
		  }).then(response => {
			if(!response.data.error)
				console.log("Ok")
			else
				console.log("Error")
		  })
	}

	const removeFav = (param)=> {
		
		axios({
			method: 'delete',
			url: '/api/user/fav_rest/'+param,
		  }).then(response => {
			if(!response.data.error)
				console.log("Ok")
			else
				console.log("Error")
		  })
	}

	

	const {alertText,cityUtility} = props

	useEffect(() => {
        if(cityUtility?.cityUtility == 100){
			setMaxUtlity(true)
		}else{
			setMaxUtlity(false)
		}
    }, [cityUtility])


	const [closesSoon, setClosesSoon] = useState(false)
	useEffect(() => {

		let delivery_to = moment(props.delivery_to,"hh:mm")
		let now = moment()

		let timeDifference = delivery_to.diff(now,"minutes")
		
		if(timeDifference<=30 && timeDifference > 0) setClosesSoon(timeDifference)

	}, [props.delivery_to])

	return (<>
		<Link to={props.linkUrl}>
			{props.alert_badge ? <div className="alert-badge alert-badge-padding">{props.alert_badge} 
			&nbsp;	{props?.alert_badge_description &&  <OverlayTrigger
					style={{"marginLeft":"1rem"}}
					placement="top"
					delay={{ show: 250, hide: 400 }}
					overlay={renderTooltip}
					><Icofont size="2" icon="exclamation-circle"/>{}</OverlayTrigger>}
			</div> :<div className="alert-badge-padding"></div>}
    		<div className={`list-card bg-white rounded overflow-hidden position-relative shadow-sm ${alertText && "closedRest"}`} >
				
               <div className={`list-card-image ${alertText && "closedRest"}`} data-alerttext={alertText}>
               	  {props.rating ? (
	                  <div className="star position-absolute">
                  		<Badge variant="success">
	                  		<Icofont icon='star'/> {props.rating}
	                  	</Badge>
	              	  </div>
	              	  )
	              	  :""
	              }
				  {cityUtility?.cityUtility==100 ? (
	                  <div className="star position-absolute">
                  		<Badge variant="danger">
	                  		<Icofont icon='exclamation-circle'/> Pouze objednávky na později
	                  	</Badge>
	              	  </div>
	              	  )
	              	  :""
	              }
				  {closesSoon && 
	                  <div className="star position-absolute" style={{"bottom":"auto","top":"8px","right":"auto","left":"8px"}}>
                  		<Badge variant="danger">
	                  		<Icofont icon='exclamation-circle'/> Zavírá za {closesSoon} min
	                  	</Badge>
	              	  </div>  
	              }
                  <div className={`favourite-heart position-absolute ${props.favIcoIconColor}`}>
                  	<div>
				  		{state.user ? isFav == 1?
                  		<Icofont onClick={(e)=>{e.preventDefault();removeFav(props.id);setIsFav(0)}} style={{"color":"rgb(215, 6, 6)"}} icon='heart'/>
						  :
						<Icofont onClick={(e)=>{e.preventDefault();addToFav(props.id);setIsFav(1)}} style={{"color":"black"}} icon='heart'/>
						:""}
                  	</div>
                  </div>
                  <div>
                  	<Image style={{"minHeight":"151px"}} src={props.image} className={props.imageClass} alt={props.imageAlt} />
                  </div>
               </div>
               <div className="p-3 position-relative">
                  <div className="list-card-body">
                     <h6 className="mb-1"><span className="text-black">{props.title}</span></h6>
                     {props.subTitle && props.subTitle!=="undefined" ? (
	                     <p className="text-gray mb-3">{props.subTitle}</p>
	                     )
	                     :''
	                 }
	                 {(props.time || props.price)? (
	                     <p className="text-gray mb-4 time">
	                     	{props.time ? (
								 <>
								 	<span className="card-delivery-price">
										<CardItemOverlay text="Cena dopravy" icon="auto-rickshaw" customClass="card-delivery-price-icon" /> <span className="card-delivery-price-text">{Number(props.deliveryPrice)+Number(props?.vlastni == "a" ? 0 : Number(state?.location?.doprava || 0) )} Kč</span>
									</span>				
									<span className="bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2">
										<Icofont icon='wall-clock'/> {props.time}
									</span> 
									
								 </>
		                     	)
		                     	:""
		                    }
							
	                     </p>
	                   	):''
	                 }
					
					 <div className="card-information-icons">
						 {	props.stravenky == "1" ?
						 		<CardItemOverlay text="Přijímá stravenky" icon="ticket" />
							: ""
						 }
						 {	props.creditCard == "1" ?
								<CardItemOverlay text="Lze platit kartou" icon="credit-card" />
							: ""
						 }
						 {	props.bonusProgram == "1" ?
								<CardItemOverlay text="Bonusový program" icon="coins" />
							: ""
						 }
						 {	props.freeDelivery == "1" && state.location?.nofreedelivery !== "a" ?
								<CardItemOverlay text="Doprava zdarma od: " icon="free-delivery" freeDeliveryFrom = {props.delivery_free} />
							: ""
						 }
{/* 						 {	props.canBookTable == true ?
								<CardItemOverlay text="Lze rezervovat stůl" icon="paper" />
							: ""
						 } */}

					 	

					 </div>
                  </div>
                  
               </div>
            </div>
			</Link>
		</>);
	}

	



export default CardItem;