import React from 'react';
import {Col, Image} from 'react-bootstrap';
import { Link } from 'react-router-dom';

const SingleAdvertisingBanner = (props) => {
    return (
        
        <Col key={props.image} md={3}>
            <div className="banner-advertising" >
                <a href={props.linkUrl}>
                    <Image src={"https://www.jidlopodnos.cz"+props.image} onError={(e)=>{e.target.onerror = null; e.target.style.opacity="0"}}/>
                </a>
            </div>
        </Col>
        
    )
}


export default SingleAdvertisingBanner