import React from 'react';
import {Link} from 'react-router-dom';
import {Row,Col,Container, Button, Spinner} from 'react-bootstrap';
import Icofont from 'react-icofont';
import useFetch from '../../useFetch.js'
import {useParams} from 'react-router-dom';
 

const TrackOrder = ()=> {

	let html = `<div class="container" style=""><h1>V&scaron;eobecn&eacute; podm&iacute;nky užit&iacute; těchto str&aacute;nek</h1>

	<p>Předem se omlouv&aacute;me za striktn&iacute;, form&aacute;ln&iacute; a neosobn&iacute; t&oacute;n pr&aacute;vn&iacute;ho jazyka, kter&yacute; běžně</p>
	
	<p>neuž&iacute;v&aacute;me, ale z pr&aacute;vn&iacute;ch, ale i finančn&iacute;ch důvodů jsme nuceni n&aacute;sleduj&iacute;c&iacute; ř&aacute;dky formulovat</p>
	
	<p>v t&eacute;to stroh&eacute; řeči.</p>
	
	<p>Podm&iacute;nky užit&iacute; obchodn&iacute; společnosti Food time s.r.o., IČO: 17418038, se s&iacute;dlem Jaurisova</p>
	
	<p>515/4, Michle, 140 00 Praha (d&aacute;le jen &bdquo;jidlopodnos.cz&ldquo;) pro př&iacute;stup a užit&iacute; webov&yacute;ch str&aacute;nek</p>
	
	<p>www.jidlopodnos.cz (d&aacute;le jen &bdquo;Podm&iacute;nky&ldquo;)</p>
	
	<h2>I. V&yacute;klad pojmů</h2>
	
	<p><strong>jidlopodnos.cz (provozovatel)</strong> - v&yacute;&scaron;e uveden&aacute; společnost, kter&aacute; provozuje zde popsan&yacute;</p>
	
	<p>Agreg&aacute;tor služeb na webov&eacute; adrese www.jidlopodnos.cz. Jidlopodnos.cz pronaj&iacute;m&aacute;</p>
	
	<p>Agreg&aacute;tor s předem stanoven&yacute;mi a dohodnut&yacute;mi funkcemi dal&scaron;&iacute;m subjektům</p>
	
	<p>(Provozovn&aacute;m) a umožňuje jim tak prostřednictv&iacute;m Agreg&aacute;toru uskutečňovat objedn&aacute;vky</p>
	
	<p>od Z&aacute;kazn&iacute;ků na z&aacute;kladě &quot;Zprostředkovatelsk&eacute; služby&quot; mezi jidlopodnos.cz a Provozovnou.</p>
	
	<p><strong>Provozovna</strong> - subjekt, kter&yacute; poskytuje nab&iacute;dku služeb nebo zbož&iacute; a využ&iacute;v&aacute; agreg&aacute;tor</p>
	
	<p>jidlopodnos.cz k uskutečňov&aacute;n&iacute; objedn&aacute;vek od Z&aacute;kazn&iacute;ků. Pr&aacute;vn&iacute; vztah jidlopodnos.cz a</p>
	
	<p>Provozovnou je upraven na z&aacute;kladě &quot;Zprostředkovatelsk&eacute; smlouvy&quot;.</p>
	
	<p><strong>Z&aacute;kazn&iacute;k</strong> - jak&aacute;koli osoba nebo subjekt, kter&yacute; uskutečňuje objedn&aacute;vku zbož&iacute; nebo služeb</p>
	
	<p>prostřednictv&iacute;m Agreg&aacute;toru jidlopodnos.cz</p>
	
	<p><strong>Agreg&aacute;tor</strong> - Agreg&aacute;tor služeb na webov&eacute; adrese www.jidlopodnos.cz je internetov&aacute;</p>
	
	<p>aplikace umožňuj&iacute;c&iacute; realizaci objedn&aacute;vek zbož&iacute; nebo služeb od Z&aacute;kazn&iacute;ka k Provozovně</p>
	
	<p><strong>Objedn&aacute;vka</strong> - uzavřen&iacute; objedn&aacute;vky zbož&iacute; nebo služeb prob&iacute;h&aacute; prostřednictv&iacute;m Agreg&aacute;toru</p>
	
	<p>a je z&aacute;vazn&aacute; jak pro Provozovnu, tak pro Z&aacute;kazn&iacute;ka.</p>
	
	<h2>II. Z&aacute;kladn&iacute; ustanoven&iacute;</h2>
	
	<p>1. jidlopodnos.cz provozuje a spravuje agreg&aacute;tor Provozoven s rozvozem j&iacute;del a zbož&iacute; na</p>
	
	<p>internetov&eacute; str&aacute;nce www.jidlopodnos.cz (d&aacute;le jen &bdquo;jidlopodnos.cz&ldquo;). jidlopodnos.cz</p>
	
	<p>pronaj&iacute;m&aacute; jidlopodnos.cz se v&scaron;emi funkcemi jin&yacute;m subjektům (d&aacute;le jen &bdquo;Provozovna&ldquo;) a vyv&iacute;j&iacute;</p>
	
	<p>činnost k tomu, aby Z&aacute;kazn&iacute;k mohl uzav&iacute;rat s Provozovnou smlouvy na využit&iacute; služeb nebo</p>
	
	<p>zbož&iacute;</p>
	
	<p>2. Podm&iacute;nky upravuj&iacute; pr&aacute;va a povinnosti osob využ&iacute;vaj&iacute;c&iacute;ch jidlopodnos.cz (d&aacute;le jen</p>
	
	<p>&bdquo;Z&aacute;kazn&iacute;k&ldquo;) při vstupu na jidlopodnos.cz, a dal&scaron;&iacute;m použ&iacute;v&aacute;n&iacute; jidlopodnos.cz, jakož i dal&scaron;&iacute;</p>
	
	<p>souvisej&iacute;c&iacute; pr&aacute;vn&iacute; vztahy.</p>
	
	<p>3. Z&aacute;kazn&iacute;k vstupem na jidlopodnos.cz stvrzuje, že se sezn&aacute;mil s aktu&aacute;ln&iacute;m zněn&iacute;m</p>
	
	<p>Podm&iacute;nek užit&iacute; str&aacute;nek, a zavazuje se jimi ř&iacute;dit.</p>
	
	<p>4. jidlopodnos.cz může zněn&iacute; podm&iacute;nek jednostranně měnit či doplňovat. jidlopodnos.cz</p>
	
	<p>informuje Z&aacute;kazn&iacute;ka o změně Obchodn&iacute;ch podm&iacute;nek na jidlopodnos.cz, př&iacute;padně jin&yacute;m</p>
	
	<p>vhodn&yacute;m způsobem tak, aby se s aktu&aacute;ln&iacute;m zněn&iacute;m podm&iacute;nek Z&aacute;kazn&iacute;k mohl bez</p>
	
	<p>nepřiměřen&yacute;ch obt&iacute;ž&iacute; sezn&aacute;mit. Podm&iacute;nky nab&yacute;vaj&iacute; &uacute;činnosti okamžikem jejich zveřejněn&iacute; na</p>
	
	<p>jidlopodnos.cz.</p>
	
	<p>&nbsp;</p>
	
	<h2>III. Použit&iacute; jidlopodnos.cz</h2>
	
	<p>1. Př&iacute;stup a použ&iacute;v&aacute;n&iacute; jidlopodnos.cz je bezplatn&eacute;. Z&aacute;kazn&iacute;k nese v&scaron;ak n&aacute;klady vznikl&eacute; mu</p>
	
	<p>v souvislosti s realizac&iacute; př&iacute;stupu a použ&iacute;v&aacute;n&iacute; jidlopodnos.cz (tj. n&aacute;klady na internetov&eacute;</p>
	
	<p>připojen&iacute; aj.).</p>
	
	<p>2. jidlopodnos.cz nen&iacute; &uacute;častn&iacute;kem smluvn&iacute;ch vztahů mezi Provozovnou a Z&aacute;kazn&iacute;kem.</p>
	
	<p>jidlopodnos.cz nenese ž&aacute;dnou odpovědnost za splněn&iacute; povinnost&iacute; ze Zprostředkovan&eacute;</p>
	
	<p>smlouvy ani nenese odpovědnost za př&iacute;padnou &scaron;kodu vzniklou Z&aacute;kazn&iacute;kovi na z&aacute;kladě nebo</p>
	
	<p>v souvislosti se Zprostředkovanou smlouvou.</p>
	
	<p>3. jidlopodnos.cz pronaj&iacute;m&aacute; jidlopodnos.cz Provozovn&aacute;m, a nenese odpovědnost za</p>
	
	<p>př&iacute;padnou z&aacute;vadnost obsahu či vzhledu materi&aacute;lů zveřejňovan&yacute;ch Provozovnami na</p>
	
	<p>jidlopodnos.cz. Jidlopodnos.cz neodpov&iacute;d&aacute; za &uacute;plnost, přesnost, pravdivost materi&aacute;lů</p>
	
	<p>zveřejňovan&yacute;ch na jidlopodnos.cz ani za př&iacute;padn&eacute; nekalosoutežn&iacute; jedn&aacute;n&iacute; Proozovny</p>
	
	<p>prostřednictv&iacute;m jidlopodnos.cz nebo poru&scaron;en&iacute; osobnostn&iacute;ch nebo autorsk&yacute;ch pr&aacute;v. V&scaron;echny</p>
	
	<p>ceny na jidlopodnos.cz jsou ceny uveřejněn&eacute; Provozovnou.</p>
	
	<p>4. jidlopodnos.cz nezaručuje nepřeru&scaron;en&yacute; př&iacute;stup na jidlopodnos.cz, ani nez&aacute;vadnost a</p>
	
	<p>bezpečnost jidlopodnos.cz. jidlopodnos.cz neodpov&iacute;d&aacute; za &scaron;kodu způsobenou Z&aacute;kazn&iacute;kovi při</p>
	
	<p>realizaci př&iacute;stupu a už&iacute;v&aacute;n&iacute; jidlopodnos.cz, včetně př&iacute;padn&eacute; &scaron;kody vznikl&eacute; při stahov&aacute;n&iacute; dat</p>
	
	<p>zveřejněn&yacute;ch na jidlopodnos.cz, &scaron;kody způsoben&eacute; přeru&scaron;en&iacute;m provozu, poruchou</p>
	
	<p>jidlopodnos.cz, poč&iacute;tačov&yacute;mi viry, &scaron;kodu v důsledku ztr&aacute;ty dat, zisku, neopr&aacute;vněn&eacute;ho</p>
	
	<p>př&iacute;stupu k přenosům a datům Z&aacute;kazn&iacute;ka.</p>
	
	<p>5. jidlopodnos.cz si vyhrazuje pr&aacute;vo kdykoliv omezit či ukončit Provozovně nebo Z&aacute;kazn&iacute;kovi</p>
	
	<p>př&iacute;stup na jidlopodnos.cz.</p>
	
	<p>6. Provozovna nese odpovědnost za ve&scaron;kerou &scaron;kodu vzniklou neopr&aacute;vněn&yacute;m z&aacute;sahem</p>
	
	<p>Provozovny do jidlopodnos.cz či syst&eacute;mu, kter&yacute; realizuje přenos z jidlopodnos.cz třet&iacute;m</p>
	
	<p>osob&aacute;m.</p>
	
	<h2>IV. Ochrana osobn&iacute;ch &uacute;dajů</h2>
	
	<p><strong>A. &Uacute;vod</strong></p>
	
	<p>1. Soukrom&iacute; n&aacute;v&scaron;těvn&iacute;ků na&scaron;ich webov&yacute;ch str&aacute;nek je pro n&aacute;s velmi důležit&eacute; a zav&aacute;zali</p>
	
	<p>jsme se je chr&aacute;nit. Tato pravidla vysvětluj&iacute;, co s va&scaron;imi osobn&iacute;mi &uacute;daji děl&aacute;me.</p>
	
	<p>2. V&aacute;&scaron; souhlas s použ&iacute;v&aacute;n&iacute;m souborů cookies v souladu s na&scaron;imi podm&iacute;nkami při prvn&iacute;</p>
	
	<p>n&aacute;v&scaron;těvě na&scaron;ich webov&yacute;ch str&aacute;nek n&aacute;m umožňuje použ&iacute;t soubory cookies při každ&eacute;</p>
	
	<p>va&scaron;&iacute; n&aacute;v&scaron;těvě na&scaron;eho webu.</p>
	
	<p><strong>B. Shromažďov&aacute;n&iacute; osobn&iacute;ch &uacute;dajů</strong></p>
	
	<p>Shromažďov&aacute;ny, ukl&aacute;d&aacute;ny a použ&iacute;v&aacute;ny mohou b&yacute;t n&aacute;sleduj&iacute;c&iacute; typy osobn&iacute;ch &uacute;dajů:</p>
	
	<p>1. informace o va&scaron;em poč&iacute;tači včetně IP adresy, geografick&eacute;ho um&iacute;stěn&iacute;, typu a verze</p>
	
	<p>prohl&iacute;žeče a operačn&iacute;ho syst&eacute;mu;</p>
	
	<p>2. informace o va&scaron;ich n&aacute;v&scaron;těv&aacute;ch a použit&iacute; těchto webov&yacute;ch str&aacute;nek včetně zdroje</p>
	
	<p>doporučen&iacute;, d&eacute;lky n&aacute;v&scaron;těvy, zobrazen&yacute;ch str&aacute;nek a průchodu webov&yacute;ch str&aacute;nek;</p>
	
	<p>3. informace jako je e-mailov&aacute; adresa, kterou zad&aacute;v&aacute;te při registraci na na&scaron;ich</p>
	
	<p>webov&yacute;ch str&aacute;nk&aacute;ch;</p>
	
	<p>vlastn&iacute; nastaven&iacute; historie&ldquo; a zru&scaron;te za&scaron;krtnut&iacute; pol&iacute;čka &bdquo;Přij&iacute;mat soubory cookies z</p>
	
	<p>webů&ldquo;; a</p>
	
	<p>3. v Chromu (verze 29) můžete blokovat v&scaron;echny soubory cookies v nab&iacute;dce</p>
	
	<p>&bdquo;Přizpůsoben&iacute; a ovl&aacute;d&aacute;n&iacute;&ldquo;, kde klikněte na &bdquo;Nastaven&iacute;&ldquo;, &bdquo;Zobrazit pokročil&aacute;</p>
	
	<p>nastaven&iacute;&ldquo; a &bdquo;Nastaven&iacute; obsahu&ldquo; a pak pod nadpisem &bdquo;Cookies&ldquo; vyberte</p>
	
	<p>&bdquo;Blokovat webům možnost nastaven&iacute; jak&yacute;chkoliv &uacute;dajů&ldquo;.</p>
	
	<p>Blokov&aacute;n&iacute; v&scaron;ech souborů cookies bude m&iacute;t negativn&iacute; dopad na použitelnost mnoha</p>
	
	<p>webov&yacute;ch str&aacute;nek. Pokud soubory cookies zablokujete, nebudete na na&scaron;ich webov&yacute;ch</p>
	
	<p>str&aacute;nk&aacute;ch moci využ&iacute;t v&scaron;echny funkce.</p>
	
	<p>3. Soubory cookies již uložen&eacute; ve va&scaron;em poč&iacute;tači můžete vymazat &ndash; např&iacute;klad:</p>
	
	<p>1. v Internet Exploreru (verze 10) mus&iacute;te soubory cookies vymazat ručně (instrukce</p>
	
	<p>jak to prov&eacute;st naleznete zde: http://support.microsoft.com/kb/278835);</p>
	
	<p>2. ve Firefoxu (Verze 24) můžete soubory cookies smazat kliknut&iacute;m na &bdquo;N&aacute;stroje&ldquo;,</p>
	
	<p>&bdquo;Možnosti&ldquo; a &bdquo;Soukrom&iacute;&ldquo;, pak vyberete &bdquo;Použ&iacute;t vlastn&iacute; nastaven&iacute; historie&ldquo;,</p>
	
	<p>kliknete na &bdquo;Zobrazit soubory cookies&ldquo; a pak kliknete na &bdquo;Odstranit v&scaron;echny</p>
	
	<p>soubory cookies&ldquo;; a</p>
	
	<p>3. v Chromu (verze 29) můžete v&scaron;echny soubory cookies smazat v nab&iacute;dce</p>
	
	<p>&bdquo;Přizpůsoben&iacute; a ovl&aacute;d&aacute;n&iacute;&ldquo;, kde klikněte na &bdquo;Nastaven&iacute;&ldquo;, &bdquo;Zobrazit pokročil&aacute;</p>
	
	<p>nastaven&iacute;&ldquo; a &bdquo;Vymazat data prohl&iacute;žeče&ldquo; a před t&iacute;m, než kliknete na &bdquo;Vymazat</p>
	
	<p>data prohl&iacute;žeče&ldquo;, vyberete &bdquo;Smazat soubory cookies a dal&scaron;&iacute; data str&aacute;nek a</p>
	
	<p>roz&scaron;&iacute;řen&iacute;&ldquo;.</p>
	
	<p>4. Vymaz&aacute;n&iacute; souborů cookies bude m&iacute;t negativn&iacute; dopad na použitelnost mnoha</p>
	
	<p>webov&yacute;ch str&aacute;nek.</p>
	
	<h2>V. Autorsk&aacute; pr&aacute;va</h2>
	
	<p>1. Jidlopodnos.cz je vlastn&iacute;kem jidlopodnos.cz.</p>
	
	<p>2. Jidlopodnos.cz je autorsk&yacute;m d&iacute;lem ve smyslu z&aacute;kona č. 121/2000 Sb., autorsk&yacute; z&aacute;kon,</p>
	
	<p>v platn&eacute;m zněn&iacute;. jidlopodnos.cz vykon&aacute;v&aacute; ve&scaron;ker&aacute; majetkov&aacute; pr&aacute;va vztahuj&iacute;c&iacute; se k</p>
	
	<p>jidlopodnos.cz.</p>
	
	<p>3. Obsah jidlopodnos.cz nelze uchov&aacute;vat, upravovat, &scaron;&iacute;řit ani k němu vykon&aacute;vat jin&aacute;</p>
	
	<p>majetkov&aacute; pr&aacute;va, ledaže k takov&eacute;mu jedn&aacute;n&iacute; udělil jidlopodnos.cz předem souhlas.</p>
	
	<h2>VI. Pr&aacute;va a povinnosti Provozovny</h2>
	
	<p>1. Provozovna vůči jidlopodnos.cz prohla&scaron;uje a zaručuje, že:</p>
	
	<p>a. je plně způsobil&aacute; k pr&aacute;vn&iacute;m &uacute;konům, a to zejm&eacute;na s ohledem na svůj věk, nebo je</p>
	
	<p>zastoupena z&aacute;konn&yacute;m z&aacute;stupcem,</p>
	
	<p>b. ve&scaron;ker&eacute; &uacute;daje, kter&eacute; poskytuje jidlopodnos.cz, jsou pravdiv&eacute;, &uacute;pln&eacute;, přesn&eacute; a spr&aacute;vn&eacute;,</p>
	
	<p>c. se před zah&aacute;jen&iacute;m už&iacute;v&aacute;n&iacute; jidlopodnos.cz důkladně sezn&aacute;mila s těmito Podm&iacute;nkami, že</p>
	
	<p>těmto Podm&iacute;nk&aacute;m zcela rozum&iacute; a souhlas&iacute; s nimi.</p>
	
	<p>d. bude použ&iacute;vat jidlopodnos.cz prostřednictv&iacute;m ne v&iacute;ce než jednoho uživatelsk&eacute;ho &uacute;čtu.</p>
	
	<p>V př&iacute;padě opr&aacute;vněn&eacute;ho podezřen&iacute;, že Provozovna vytvořila a už&iacute;v&aacute; v&iacute;ce uživatelsk&yacute;ch &uacute;čtů,</p>
	
	<p>m&aacute; jidlopodnos.cz pr&aacute;vo tyto &uacute;čty zablokovat a smazat.</p>
	
	<p>2. Provozovna je povinna při už&iacute;v&aacute;n&iacute; jidlopodnos.cz dodržovat pr&aacute;vn&iacute; předpisy je povinna</p>
	
	<p>vždy respektovat pr&aacute;va jidlopodnos.cz i třet&iacute;ch osob, zejm&eacute;na při nakl&aacute;d&aacute;n&iacute; s autorsk&yacute;mi</p>
	
	<p>d&iacute;ly a jin&yacute;mi předměty pr&aacute;v du&scaron;evn&iacute;ho vlastnictv&iacute;. Provozovna zejm&eacute;na nesm&iacute;:</p>
	
	<p>a. už&iacute;vat jidlopodnos.cz v rozporu s těmito Podm&iacute;nkami,</p>
	
	<p>b. komerčně už&iacute;vat kter&eacute;koli č&aacute;sti jidlopodnos.cz způsobem způsobil&yacute;m po&scaron;kodit</p>
	
	<p>jidlopodnos.cz a/nebo Z&aacute;kazn&iacute;ka,</p>
	
	<p>c. z&iacute;sk&aacute;vat přihla&scaron;ovac&iacute; jm&eacute;na a/nebo hesla jin&yacute;ch Provozoven,</p>
	
	<p>d. zneuž&iacute;vat, blokovat, modifikovat či jinak měnit jakoukoliv souč&aacute;st jidlopodnos.cz, nebo se</p>
	
	<p>i jen pokusit naru&scaron;it stabilitu, chod nebo data jidlopodnos.cz,</p>
	
	<p>e. použ&iacute;vat mechanismy, programov&eacute; vybaven&iacute; nebo jin&eacute; postupy, kter&eacute; by mohly m&iacute;t</p>
	
	<p>negativn&iacute; vliv na provoz jidlopodnos.cz. jidlopodnos.cz je možn&eacute; už&iacute;vat jen v rozsahu, kter&yacute;</p>
	
	<p>nen&iacute; na &uacute;kor pr&aacute;v Provozoven nebo jidlopodnos.cz a kter&yacute; je v souladu s jeho určen&iacute;m.</p>
	
	<p>3. Provozovna se, bez ohledu na jin&aacute; ustanoven&iacute; těchto Podm&iacute;nek, zavazuje, že:</p>
	
	<p>a. nebude použ&iacute;vat jidlopodnos.cz, pokud by jeho použit&iacute;m ze strany Provozovny do&scaron;lo</p>
	
	<p>k poru&scaron;en&iacute; pr&aacute;vn&iacute;ch předpisů,</p>
	
	<p>b. pokud j&iacute;/mu je m&eacute;ně než 18 let, nebude použ&iacute;vat takov&eacute; služby jidlopodnos.cz, u nichž je</p>
	
	<p>podm&iacute;nkou pro už&iacute;v&aacute;n&iacute; minim&aacute;ln&iacute; věk 18 let, přičemž takov&eacute; služby jsou označeny symbolem</p>
	
	<p>&bdquo;18+&ldquo; nebo jin&yacute;m symbolem shodn&eacute;ho v&yacute;znamu.</p>
	
	<h2>VIII. Hodnocen&iacute; a Uživatelsk&eacute; recenze</h2>
	
	<p>Hodnocen&iacute; a Recenze restaurac&iacute; a dal&scaron;&iacute;ch prodejců (d&aacute;le jen &bdquo;provozovny&ldquo;) na platform&aacute;ch</p>
	
	<p>J&iacute;dlo pod nos se zakl&aacute;d&aacute; na uživatel&iacute;ch, kteř&iacute; provedli n&aacute;kup a byla jim z&aacute;silka doručena. Ti</p>
	
	<p>pot&eacute; maj&iacute; možnost na z&aacute;kladě doručen&eacute;ho e-mailu Recenzi a Hodnocen&iacute; na stupnici 1</p>
	
	<p>(nejhor&scaron;&iacute;) až 5 (nejlep&scaron;&iacute;) provozovně udělit.</p>
	
	<p>Hodnocen&iacute; a Recenze jsou veřejn&eacute; na profilech provozoven bez &uacute;dajů o z&aacute;kazn&iacute;kovi.</p>
	
	<p>VIII. Z&aacute;věrečn&aacute; ustanoven&iacute;</p>
	
	<p>1. Ve&scaron;ker&eacute; pr&aacute;vn&iacute; vztahy vznikaj&iacute;c&iacute; na z&aacute;kladě nebo v souvislosti s jidlopodnos.cz se ř&iacute;d&iacute;</p>
	
	<p>pr&aacute;vn&iacute;m ř&aacute;dem Česk&eacute; republiky, a to bez ohledu na to, odkud byl př&iacute;stup a užit&iacute;</p>
	
	<p>jidlopodnos.cz realizov&aacute;n.</p>
	
	<p>2. Je-li nebo stane-li se někter&eacute; z ustanoven&iacute; Podm&iacute;nek neplatn&yacute;m nebo ne&uacute;činn&yacute;m,</p>
	
	<p>nam&iacute;sto takov&yacute;ch neplatn&yacute;ch ustanoven&iacute; nastoup&iacute; ustanoven&iacute;, jehož smysl se neplatn&eacute;mu</p>
	
	<p>ustanoven&iacute; co nejv&iacute;ce přibližuje. Neplatnost&iacute; nebo ne&uacute;činnost&iacute; někter&eacute;ho ustanoven&iacute; nen&iacute;</p>
	
	<p>dotčena platnost a &uacute;činnost ostatn&iacute;ch ustanoven&iacute;.</p>
	
	<p>3. Kontaktn&iacute; &uacute;daje: adresa elektronick&eacute; po&scaron;ty: obchod@foodipsum, telefon +420 603 289</p>
	
	<p>295.</p>
	
	<p>4. Tyto Podm&iacute;nky nab&yacute;vaj&iacute; &uacute;činnosti dne 1. 1. 2023</p>
	
	<p>&nbsp;</p>
	
	
    	</div>`


	//const {data: order,loading: loadingOrder,error:errorOrder} = useFetch(`/api/orders/detail/${year}/${cislo}`) 

/* 	if(loadingOrder) return (
		<Container className="pb-5" style={{"paddingTop":"8rem","minHeight":"60vh"}}>
			<Row>
				<Col md={12} className="text-center load-more">
				<Button variant="primary" type="button" disabled="">
					<Spinner animation="grow" size="sm" className='mr-1' />
					Načítání...
				</Button>  
				</Col>
			</Row>
		</Container>
	) */

/* 	if(errorOrder) return (
		<Container className="pb-5" style={{"padding-top":"8rem","min-height":"60vh"}}>
			<Row>
				<Col md={12} className="text-center load-more">
				<Button variant="primary" type="button" disabled="">
					Chyba 
				</Button>  
				</Col>
			</Row>
		</Container>
	) */

	return (
		<section dangerouslySetInnerHTML={{ __html: html }} className="section bg-white osahan-track-order-page position-relative" style={{"paddingTop":"130px"}}>
	
		</section>
    	);
    
}


export default TrackOrder;