import React, { useEffect, useState } from 'react'
import { Button, Card, Form, ListGroup, ListGroupItem } from 'react-bootstrap'
import CookiesModal from './modals/CookiesModal'
import {useContext} from 'react';
import {AppContext} from '../context.js'

const Cookies = () => {
    const [closed, setClosed] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    
    
    const {state, dispatch} = useContext(AppContext)

    useEffect(() => {
        let cookies_permission = JSON.parse(localStorage.getItem('cookies_permission'))

        if(cookies_permission){
          setClosed(true)
        }
    }, [])
    

    const handleChoice = ({analytic,marketing})=>{
        if(analytic || marketing){
            dispatch({ type: 'SET_COOKIES_PERMISSION',payload:{analytic,marketing} })
        }
    }

    return (
        <>
            {closed ? <></> :
                <div className="d-flex align-items-end w-100 justify-content-center fixed-bottom" style={{"backgroundColor":"#e0e0e0"}}>
                    <div className='row w-100 bg-light p-5 justify-content-center '>
                        <div className="col-md-6 col-xs-6 m-3">
                            Na našich webových stránkách používáme soubory cookies. Některé z nich jsou nezbytné, zatímco jiné nám pomáhají vylepšit tento web a váš uživatelský zážitek. Souhlasíte s používáním všech cookies?
                            <br /><br /><a href='https://cs.wikipedia.org/wiki/HTTP_cookie' target='_blank' className='red-text'>Informace o cookies</a>
                        </div>
                        <div className="col-md-4 col-xs-6 text-center">
                            <Button className='w-75 m-2'
                                variant="danger"
                                onClick={() => { setClosed(true);handleChoice({marketing:true,analytic:true}) }}>
                                Souhlasím
                            </Button><br />
                            <Button className='w-75 m-2'
                                variant="secondary"
                                onClick={() => { setOpenModal(true) }}>
                                Přizpůsobit
                            </Button>
                        </div>
                    </div>
                </div>
            }
            <CookiesModal closeCookies={setClosed} setShow={setOpenModal} show={openModal} handleChoice={handleChoice} />
        </>
    )
}

export default Cookies