import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import Icofont from 'react-icofont';
import axios from 'axios'
import { AppContext } from '../context.js'
import { useHistory } from "react-router-dom";
import AddAddressModal from './modals/AddAddressModal';



function AddressPick(props) {

	const { state, dispatch } = useContext(AppContext)

	const { addresses } = props
	const [showAddressModal, setShowAddressModal] = useState(false)
	const [showAddressResults, setShowAddressResults] = useState(false)

	const [searchError, setSearchError] = useState(false)

	let history = useHistory();

	const resolveLocation = useCallback((r) => {
		console.log("resolving location ", r)

		if (r && r.kod_adm) {
			let location = r;

			axios.get(`https://api.jidlopodnos.cz/api.php/gpstest/${location.gps_x}/${location.gps_y}`).then(response => {
				//console.log(response)
				if (response.status == 200) {
					let gpsResult
					let polygonRestaurants
					let isMulti = false
					if(Array.isArray(response.data.oblast)){
						gpsResult = response.data.oblast[0]
						polygonRestaurants = response.data.restaurace
						console.log("Founde multiple gps: ",response.data)
						if(response.data.oblast.length>1)
						isMulti = true
					}else{
						gpsResult = response.data.oblast
						console.log("Singlelocation found",gpsResult)
					}

					if(gpsResult && gpsResult.code!=="error"){
						let mesto_url = gpsResult.mesto;
						if(props.requireCity && props.requireCity !== gpsResult.mesto){ //Kontrola při aktivní restauraci v checkout
							setSearchError("Neplatná adresa pro zvolenou restauraci")
							return
						}
						
						mesto_url = mesto_url.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/\s+/g, '-');
						console.log("Location",location)
						
						let locationData = {source:"AddressPick",...gpsResult,mesto_url:mesto_url,polygonRestaurants,adresa:{...location,kod_adm:location.kod_adm||location.id,string:location.string||location.name}}
						if(isMulti) locationData.multiLocation = response.data.oblast
						
						dispatch({ type: 'LOADLOCATION', payload: locationData })
						if(props.isHomepage)
							setTimeout(() => {
								history.push("/rozvoz-jidel/"+mesto_url)
							}, 150); // Adjust delay as needed
					}else{
						setSearchError((response.data.message || gpsResult.message))
					}
				} else {
					console.log("chyba serveru")
				}


			})
		}
	}, [])

	const hideAddressModal = () => {
		setShowAddressModal(false)
	}

	return (<>
		<AddAddressModal show={showAddressModal} onHide={hideAddressModal} />
		<div className={`homepage-search-form`}>
			<Form className="form-noborder">
				<div className="">
					{/* <Form.Group className={`col-lg-10 col-md-10 col-sm-12`}>
						<Row style={{ "height": "100%" }}>
							<Col className="pr-0" md={12}>
								<Form.Control  className="checkout-delivery-time-select" as="select" custom onChange={(e)=>resolveLocation(JSON.parse(e.target.value))} style={{"height":"100%"}}>
											<option>Vyberte doručovací adresu</option>
											{addresses.map(address => (
												<option value={JSON.stringify(address)}>{(address.name?address.name+" - " : "") + getLocationString(address)}</option>
											))}
										</Form.Control> 
								
							</Col>
						</Row>
					</Form.Group> */}
					<Form.Group /* className={`col-sm-10`} */>
						{/* <Form.Control type="text" placeholder={state.location ? getLocationString(state.location.adresa):"Zadejte místo dodání"} size='lg' onChange={e => {setsearchInput(e.target.value);setResultSelected(false);setSearchError(false)}} value={searchInput} /> */}
						<h5 className="text-primary">Doručujeme na adresu: <span onClick={() => setShowAddressResults(!showAddressResults)} className="text-secondary AddresspickText">{state.location?.adresa?.string}</span></h5>
						{/* Výsledky hledání */}
						{showAddressResults &&
							<Row style={{ "position": "absolute", "zIndex": "999", "width": "100%" }}>
								<Col className="pr-0" md={12}>
									<ul className="search-results"> {/* Cislo(ulice) search */}
										{addresses.map(address => (
											<li onClick={() => { resolveLocation(address); setShowAddressResults(false) }}><Icofont icon='location-pin' /> {(address.name ? address.name + " - " : "") + getLocationString(address)}</li>
										))}
										<li onClick={() => {dispatch({ type: 'REMOVELOCATION' }) ;/*  history.push('/') */}}><Icofont icon='plus' /> Jiná adresa</li>
										{/* {resultsUlice && resultsUlice.length>0 ? (resultsUlice.map((r) => (
											<li onClick={()=>{}}><Icofont icon='location-pin'/> {r.cd}</li>
										))):""}  */}
									</ul>
								</Col>
							</Row>
						}
					</Form.Group>

					{/* <Form.Group className='col-lg-2 col-md-2 col-sm-12'>
						<Button onClick={() => setShowAddressModal(true)} variant="secondary" className="btn btn-block btn-lg btn-gradient"> <Icofont icon="plus" className="input_img" /> Přidat</Button>
					</Form.Group> */}

				</div>
			</Form>
			{state.location?.message && <h6 className=" text-danger mt-3">{state.location?.message}</h6>}
			{searchError && <><h5 className="homepage-search-description text-danger mt-3">{searchError} </h5><br></br></>}
		</div>
	</>)
}

const getLocationString = loc => {
	loc.psc = pscFormater(loc.psc)
	return loc.typ_so == "č.p." ? loc.nazev_ulice ? (loc.nazev_ulice + " " + loc.cd + (loc.co ? "/" + loc.co : "") + ", " + loc.nazev_obec + " " + loc.psc) : loc.nazev_obec + " " + loc.cd + (loc.co ? "/" + loc.co : "") + ", " + loc.psc : loc.nazev_obec + " č.e. " + loc.cd + " " + loc.psc
}

function pscFormater(psc) {
	return psc.replace(/[^\dA-Z]/g, '').replace(/(.{3})/g, '$1 ').trim();
}


const options2 = {
	responsive: {
		0: {
			items: 2,
		},
		764: {
			items: 2,
		},
		765: {
			items: 1,
		},
		1200: {
			items: 1,
		},
	},
	lazyLoad: true,
	loop: true,
	autoplay: true,
	autoplaySpeed: 1000,
	dots: false,
	autoplayTimeout: 2000,
	nav: true,
	navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
	autoplayHoverPause: true,
}

export default AddressPick;