import React,{useState} from 'react';
import {Form,Modal,Button} from 'react-bootstrap';
import axios from 'axios'

const EditProfileModal = (props) => {

	const [jmenoInput, setJmenoInput] = useState(props.user.jmeno)
	const [prijmeniInput, setPrijmeniInput] = useState(props.user.prijmeni)
	const [telefonInput, setTelefonInput] = useState(props.user.telefon)

	const sendUpdate = (e) => {
		e.preventDefault()
		axios({
			method: 'post',
			url: '/api/user/update_user',
			data:{
				jmeno:jmenoInput || "",
				prijmeni:prijmeniInput || "",
				telefon:telefonInput || "",
				login:props.user.login
			}
		  }).then(response => {
			  console.log("R")
			  console.log(response)
			if(!response.data.error){
				console.log("Ok")
				    axios({
					method: 'get',
					url: '/api/auth'
					}).then(response => {
						if(!response.data.error)
							props.dispatch({type: 'LOADUSER', payload: response.data})
							props.onHide()
					})
			}
			else
				console.log("Error")
		  })
	}

	
    	return (
	        <Modal 
	        	show={props.show} 
	        	onHide={props.onHide}
		        size="sm"
		        centered
		   	  >
			  <Modal.Header closeButton={true}>
			    <Modal.Title as='h5' id="edit-profile">Upravit profil</Modal.Title>
			  </Modal.Header>
			  <Form onSubmit={sendUpdate}>
				<Modal.Body>
					
				<div className="form-row">
					<Form.Group className="col-md-12">
						<Form.Label>Telefon</Form.Label>
						<Form.Control type="text" defaultValue={props.user.telefon} onChange={(e)=>{setTelefonInput(e.target.value)}} placeholder="Zadejte telefon" />
					</Form.Group>
					<Form.Group className="col-md-12">
						<Form.Label>Jméno</Form.Label>
						<Form.Control type="text" defaultValue={props.user.jmeno}  onChange={(e)=>{setJmenoInput(e.target.value)}} placeholder="Např. Petr"/>
					</Form.Group>
					<Form.Group className="col-md-12">
						<Form.Label>Příjmení</Form.Label>
						<Form.Control type="text" defaultValue={props.user.prijmeni} onChange={(e)=>{setPrijmeniInput(e.target.value)}} placeholder="Např. Novák"/>
					</Form.Group>
					{/*  <Form.Label>Heslo</Form.Label>
					<Button type='button' variant="secondary" className='d-flex w-100 text-center justify-content-center'>Změnit heslo</Button> */}
				</div>
			
				</Modal.Body>

				<Modal.Footer>
					<Button type='submit' variant="primary" className='d-flex w-50 text-center justify-content-center'>ULOŽIT</Button>
				</Modal.Footer>
			  </Form>
			</Modal>
    	);
    
}
export default EditProfileModal;