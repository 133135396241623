//Zobrazení menu restaurace
const menu_view = (menu, restaurant) => {
    const analyticsArray = []
    let index = 0
    console.log("GOOGLE ANALYTICS: view_item_list")

    if (!menu) return

    try {
        Object.entries(menu).map(([key, value]) => {
            
            Object.entries(value).map(([i, item]) => {
                
                if (Array.isArray(item)) {
                    item.map((item2) => {
            
                        analyticsArray.push({
                            item_id: item2.polozka_id.toString(),
                            item_name: item2.nazev,
                            currency: "CZK",
                            index: index,
                            item_category: item2.category_name,
                            item_list_id: restaurant.id.toString(),
                            item_list_name: restaurant.name,
                            price: item2.cena,
                            quantity: 1
                        })
                        index += 1
                    })
                } else {

                    analyticsArray.push({
                        item_id: item.polozka_id.toString(),
                        item_name: item.nazev,
                        currency: "CZK",
                        index: index,
                        item_category: item.category_name,
                        item_list_id: restaurant.id.toString(),
                        item_list_name: restaurant.name,
                        price: item.cena,
                        quantity: 1
                    })
                    index += 1
                }
            })
        })
    } catch (error) {
        console.log(error)
    }

    // console.log(JSON.stringify(analyticsArray));

    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    window.dataLayer.push({
        event: "view_item_list",
        ecommerce: {
            items: analyticsArray,
        },
        restaurantName: restaurant.name
    });

}

//Zobrazení jednotlivého jídla
export const view_item = (item, additions, restaurant) => {
    const analyticsArray = []
    let index = 0
    console.log("GOOGLE ANALYTICS: view_item")

    if (!item || !restaurant) return

    console.log(item)

    try {
        Object.entries(item).map(([key, value]) => {
            console.log(value)
            analyticsArray.push({
                item_id: value.polozka_id.toString(),
                item_name: value.nazev,
                currency: "CZK",
                index: index,
                item_category: 'Jídlo',
                item_list_id: restaurant.id.toString(),
                item_list_name: restaurant.name,
                price: value.cena,
                quantity: 1
            })
            index += 1
        })
        if (additions) {
            Object.entries(additions).map(([key, value]) => {
                console.log(value)
                Object.entries(value).map(([i, item]) => {
                    console.log(item)
                    Object.entries(item).map(([i, addition]) => {
                        console.log(addition)
                        addition.map(row => {
                            analyticsArray.push({
                                item_id: row.polozka_id.toString(),
                                item_name: row.name,
                                currency: "CZK",
                                index: index,
                                item_category: row.typ === 'i' ? 'Ingredience' : 'Příloha',
                                item_list_id: restaurant.id.toString(),
                                item_list_name: restaurant.name,
                                price: row.price,
                                quantity: 1
                            })
                            index += 1
                        })
                    })
                })
            })
        }
    } catch (error) {
        console.log(error)
    }


    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    window.dataLayer.push({
        event: "view_item",
        ecommerce: {
            items: analyticsArray,
        },
        restaurantName: restaurant.name
    });

}

//Select_item
export const select_item = (item, restaurant) => {
    const analyticsArray = []
    let index = 0
    console.log("GOOGLE ANALYTICS: select_item")

    if (!item || !restaurant) return

    console.log(item)

    try {
        Object.entries(item).map(([key, value]) => {
            console.log(value)
            analyticsArray.push({
                item_id: value.polozka_id.toString(),
                item_name: value.nazev,
                currency: "CZK",
                index: index,
                item_category: 'Jídlo',
                item_list_id: restaurant.id.toString(),
                item_list_name: restaurant.name,
                price: value.cena,
                quantity: 1
            })
            index += 1
        })
    } catch (error) {
        console.log(error)
    }

    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    window.dataLayer.push({
        event: "select_item",
        ecommerce: {
            items: analyticsArray,
        },
        restaurantName: restaurant.name
    });

}

//Přidání do košíku
export const add_to_cart = (item, additions, quantity, restaurant) => {
    const analyticsArray = []
    let index = 0
    console.log("GOOGLE ANALYTICS: add_to_cart")

    if (!item || !restaurant) return

    console.log(item, additions)

    try {
        Object.entries(item).map(([key, value]) => {
            console.log(value)
            analyticsArray.push({
                item_id: value.polozka_id.toString(),
                item_name: value.nazev,
                currency: "CZK",
                index: index,
                item_category: 'Jídlo',
                item_list_id: restaurant.id.toString(),
                item_list_name: restaurant.name,
                price: value.cena,
                quantity: quantity
            })
            index += 1
        })

        if (additions) {
            Object.entries(additions).map(([key, value]) => {
                console.log(value)
                value.map(row => {
                    analyticsArray.push({
                        item_id: row.polozka_id.toString(),
                        item_name: row.name,
                        currency: "CZK",
                        index: index,
                        item_category: row.typ === 'i' ? 'Ingredience' : 'Příloha',
                        item_list_id: restaurant.id.toString(),
                        item_list_name: restaurant.name,
                        price: row.price,
                        quantity: quantity
                    })
                    index += 1
                })
            })
        }
    } catch (error) {
        console.log(error)
    }

    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
            items: analyticsArray,
        },
        restaurantName: restaurant.name
    });

}

//Odstranění z košíku
export const remove_from_cart = (item, restaurant) => {
    const analyticsArray = []
    let index = 0
    console.log("GOOGLE ANALYTICS: remove_from_cart")

    if (!item || !restaurant) return

    console.log(item)

    try {
        analyticsArray.push({
            item_id: item.polozka_id.toString(),
            item_name: item.nazev,
            currency: "CZK",
            index: index,
            item_category: 'Jídlo',
            item_list_id: item.group_id.toString(),
            item_list_name: restaurant,
            price: item.cena,
            quantity: 1
        })
        index += 1
        if (item.extraItems) {
            item.extraItems.map((value) => {
                analyticsArray.push({
                    item_id: value.polozka_id.toString(),
                    item_name: value.name,
                    currency: "CZK",
                    index: index,
                    item_category: value.typ === 'i' ? 'Ingredience' : 'Příloha',
                    item_list_id: item.group_id.toString(),
                    item_list_name: restaurant,
                    price: value.price,
                    quantity: 1
                })
                index += 1
            })
        }
    } catch (error) {
        console.log(error)
    }

    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    window.dataLayer.push({
        event: "remove_from_cart",
        ecommerce: {
            items: analyticsArray,
        },
        restaurantName: restaurant.name
    });

}

export const view_cart = (item, restaurant) => {
    const analyticsArray = []
    let index = 0
    console.log("GOOGLE ANALYTICS: view_cart")

    if (!item || !restaurant) return

    console.log(item)

    try {
        Object.entries(item).map(([key, value]) => {
            console.log(value)
            analyticsArray.push({
                item_id: value.polozka_id.toString(),
                item_name: value.nazev,
                currency: "CZK",
                index: index,
                item_category: "Jídlo",
                item_list_id: restaurant.id.toString(),
                item_list_name: restaurant.name,
                price: value.cena,
                quantity: 1
            })
            index += 1
            if (value.extraItems) {
                Object.entries(value.extraItems).map(([key, value2]) => {
                    console.log(value2)
                    analyticsArray.push({
                        item_id: value2.polozka_id.toString(),
                        item_name: value2.name,
                        currency: "CZK",
                        index: index,
                        item_category: value2.typ === 'i' ? 'Ingredience' : 'Příloha',
                        item_list_id: value.group_id.toString(),
                        item_list_name: restaurant.name,
                        price: value2.price,
                        quantity: 1
                    })
                    index += 1
                })
            }
        })
    } catch (error) {
        console.log(error)
    }

    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    window.dataLayer.push({
        event: "view_cart",
        ecommerce: {
            items: analyticsArray,
        },
        restaurantName: restaurant.name
    });

}

export const begin_checkout = (item, restaurant) => {
    const analyticsArray = []
    let index = 0
    console.log("GOOGLE ANALYTICS: begin_checkout")

    if (!item || !restaurant) return

    console.log(item)

    try {
        Object.entries(item).map(([key, value]) => {
            console.log(value)
            analyticsArray.push({
                item_id: value.polozka_id.toString(),
                item_name: value.nazev,
                currency: "CZK",
                index: index,
                item_category: "Jídlo",
                item_list_id: restaurant.id.toString(),
                item_list_name: restaurant.name,
                price: value.cena,
                quantity: 1
            })
            index += 1
            if (value.extraItems) {
                Object.entries(value.extraItems).map(([key, value2]) => {
                    console.log(value2)
                    analyticsArray.push({
                        item_id: value2.polozka_id.toString(),
                        item_name: value2.name,
                        currency: "CZK",
                        index: index,
                        item_category: value2.typ === 'i' ? 'Ingredience' : 'Příloha',
                        item_list_id: value.group_id.toString(),
                        item_list_name: restaurant.name,
                        price: value2.price,
                        quantity: 1
                    })
                    index += 1
                })
            }
        })
    } catch (error) {
        console.log(error)
    }

    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    window.dataLayer.push({
        event: "begin_checkout",
        ecommerce: {
            items: analyticsArray,
        },
        restaurantName: restaurant.name
    });

}

export const add_shipping_info = (item, restaurant, totalPrice) => {
    const analyticsArray = []
    let index = 0
    console.log("GOOGLE ANALYTICS: add_shipping_info")

    if (!item || !restaurant) return

    console.log(item)

    try {
        Object.entries(item).map(([key, value]) => {
            console.log(value)
            analyticsArray.push({
                item_id: value.polozka_id.toString(),
                item_name: value.nazev,
                currency: "CZK",
                index: index,
                item_category: "Jídlo",
                item_list_id: restaurant.id.toString(),
                item_list_name: restaurant.name,
                price: value.cena,
                quantity: 1
            })
            index += 1
            if (value.extraItems) {
                Object.entries(value.extraItems).map(([key, value2]) => {
                    console.log(value2)
                    analyticsArray.push({
                        item_id: value2.polozka_id.toString(),
                        item_name: value2.name,
                        currency: "CZK",
                        index: index,
                        item_category: value2.typ === 'i' ? 'Ingredience' : 'Příloha',
                        item_list_id: value.group_id.toString(),
                        item_list_name: restaurant.name,
                        price: value2.price,
                        quantity: 1
                    })
                    index += 1
                })
            }
        })
    } catch (error) {
        console.log(error)
    }

    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    window.dataLayer.push({
        event: "add_shipping_info",
        ecommerce: {
            shipping_tier: "Rozvoz",
            currency: "CZK",
            value: totalPrice,
            items: analyticsArray,
        },
        restaurantName: restaurant.name
    });

}

export const add_payment_info = (item, restaurant, selectedPaymentMethod, totalPrice) => {
    const analyticsArray = []
    let index = 0
    console.log("GOOGLE ANALYTICS: add_payment_info")

    if (!item || !restaurant) return

    console.log(item)

    try {
        Object.entries(item).map(([key, value]) => {
            console.log(value)
            analyticsArray.push({
                item_id: value.polozka_id.toString(),
                item_name: value.nazev,
                currency: "CZK",
                index: index,
                item_category: "Jídlo",
                item_list_id: restaurant.id.toString(),
                item_list_name: restaurant.name,
                price: value.cena,
                quantity: 1
            })
            index += 1
            if (value.extraItems) {
                Object.entries(value.extraItems).map(([key, value2]) => {
                    console.log(value2)
                    analyticsArray.push({
                        item_id: value2.polozka_id.toString(),
                        item_name: value2.name,
                        currency: "CZK",
                        index: index,
                        item_category: value2.typ === 'i' ? 'Ingredience' : 'Příloha',
                        item_list_id: value.group_id.toString(),
                        item_list_name: restaurant.name,
                        price: value2.price,
                        quantity: 1
                    })
                    index += 1
                })
            }
        })
    } catch (error) {
        console.log(error)
    }

    let paymentType = ''
    if (selectedPaymentMethod === "cash") {
        paymentType = "Hotově"
    }
    if (selectedPaymentMethod === "credit-card") {
        paymentType = "Kartou"
    }
    if (selectedPaymentMethod === "meal-voucher") {
        paymentType = "Stravenkami"
    }
    if (selectedPaymentMethod === "twisto") {
        paymentType = "Twisto"
    }

    console.log(paymentType)

    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    window.dataLayer.push({
        event: "add_payment_info",
        ecommerce: {
            payment_type: paymentType,
            currency: "CZK",
            value: totalPrice,
            items: analyticsArray,
        },
        restaurantName: restaurant.name
    });

}

export const purchase = (item, tid, data, restaurant) => {
    try {

    const analyticsArray = []
    let index = 0
    console.log("GOOGLE ANALYTICS: purchase")

    const yearWithId = tid.split("/")

    const transaction_id = yearWithId[1]
    const totalPrice = data.payment.totalCheck - data.payment.deliveryPrice
    const deliveryPrice = data.payment.deliveryPrice
    const tax = data.payment.totalCheck * 0.15

    if (!item || !restaurant) return

    console.log(item, tid, data.payment, restaurant)

    
        Object.entries(item).map(([key, value]) => {
            console.log(value)
            analyticsArray.push({
                item_id: value.polozka_id.toString(),
                item_name: value.nazev,
                currency: "CZK",
                index: index,
                item_category: "Jídlo",
                item_list_id: restaurant.id.toString(),
                item_list_name: restaurant.name,
                price: value.cena,
                quantity: 1
            })
            index += 1
            if (value.extraItems) {
                Object.entries(value.extraItems).map(([key, value2]) => {
                    console.log(value2)
                    analyticsArray.push({
                        item_id: value2.polozka_id.toString(),
                        item_name: value2.name,
                        currency: "CZK",
                        index: index,
                        item_category: value2.typ === 'i' ? 'Ingredience' : 'Příloha',
                        item_list_id: value.group_id.toString(),
                        item_list_name: restaurant.name,
                        price: value2.price,
                        quantity: 1
                    })
                    index += 1
                })
            }
        })
    

    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    window.dataLayer.push({
        event: "purchase",
        ecommerce: {
            transaction_id: transaction_id,
            value: totalPrice,
            shipping: deliveryPrice,
            tax: tax,
            currency: "CZK",
            items: analyticsArray,
        },
        restaurantName: restaurant.name
    });

    } catch (error) {
        console.log(error)
    }
}

export default {
    menu_view,
    view_item,
    select_item,
    add_to_cart,
    remove_from_cart,
    view_cart,
    begin_checkout,
    add_shipping_info,
    add_payment_info,
    purchase
}