import React,{useState,useEffect,useContext} from 'react'
import {Form} from 'react-bootstrap'
import {AppContext} from '../../context.js'
    

export default function CheckboxFilter() {

    const {state, dispatch} = useContext(AppContext)

    const [isBonus, setIsBonus] = useState(0)
    const [isVoucher, setIsVoucher] = useState(0)
    const [isFreeDelivery, setIsFreeDelivery] = useState(0)
    const [isCashles, setIsCashles] = useState(0)

    useEffect(() => {
        let filter = []
        if(isBonus) filter.push("isBonus")
        if(isVoucher) filter.push("isVoucher")
        if(isFreeDelivery) filter.push("isFreeDelivery")
        if(isCashles) filter.push("isCashles")
        
        dispatch({type: 'SET_CHECKBOX_RESTAURANT_FILTER', payload: filter})
    }, [isBonus,isVoucher,isFreeDelivery,isCashles])
    return (
        <>
        <Form.Label htmlFor={"filter1"} className="mr-3" >
        <Form.Check className="m-0" required type="checkbox" id="filter1" onChange={()=>{setIsFreeDelivery(isFreeDelivery?0:1)}} inline />
            Rozvoz zdarma
        </Form.Label>
        <Form.Label htmlFor={"filter2"} className="mr-3"  >
        <Form.Check className="m-0" required type="checkbox" id="filter2" onChange={()=>{setIsCashles(isCashles?0:1)}} inline />
            Platba kartou
        </Form.Label>
        <Form.Label htmlFor={"filter3"} className="mr-3"  >
        <Form.Check className="m-0" required type="checkbox" id="filter3" onChange={()=>{setIsVoucher(isVoucher?0:1)}} inline />
            Platba stravenkami
        </Form.Label>
        <Form.Label htmlFor={"filter4"} className="mr-3"  >
        <Form.Check className="m-0" required type="checkbox" id="filter4" onChange={()=>{setIsBonus(isBonus?0:1)}} inline />
            S bonusovým programem
        </Form.Label>
        </>
    )
}
