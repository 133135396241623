import React,{useState,useEffect} from 'react'
import {Button, Modal} from 'react-bootstrap'

export default function WelcomeModal({show}) {

	const [hidden, setHidden] = useState(false)

    return (
        <Modal className="extra-items" show={show && !hidden}>
				
				<Modal.Body className="p-5 text-center">
					
				  <h3 className="order-big-card-category-title font-weight-light">Díky, že jste se rozhodli vyzkoušet náš nový web!</h3>
					<br></br>
					<p style={{"textAlign":"justify"}}>Patříte mezi první zákazníky ve veřejném testování, při případných potížích mějte trpělivost, web budeme dále vylepšovat.</p>
					<br></br>
				  <p style={{"textAlign":"justify"}}>Vaše objednávka proběhne stejně jako na původním webu a opravdu vám jídlo přivezeme. Je zde několik odlišností, které jistě přispějí k vaší spokojenosti. Potěší nás, když nám dáte vědět o vašich zkušenostech s objednávkou na emailu obchod@jidlopodnos.cz</p>
					<br></br>
				  <h5>Začněte vyplněním adresy...</h5>
					<br></br>
				  <Button onClick={()=>setHidden(true)}>Jdeme na to!</Button>

			  </Modal.Body>
  
		  </Modal>
    )
}
