import React, { useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { AppContext } from '../../context.js'

const CartRecapitulationModal = (props) => {
    const { state, dispatch } = useContext(AppContext)


    const totalPrice = Number(state.total.totalPrice) || 0
    const packaging = Number(state.total.packaging)
    const delivery_orderpackaging = Number(state.total.delivery_orderpackaging) || 0
    const deliveryPrice = Number(state.total.deliveryPrice)
    const minimalOrderAddition = Number(state.total.minimalOrderAddition)
    const creditsUsed = Number(state.totalAddition.creditsUsed)
    const tipCourier = Number(state.totalAddition.tipCourier)
    //let deliveryOrPickup = state.activeRestaurant.deliveryOrPickup - TODO when Pickup is implemented

    const cart = state.cart

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            centered
        >
            <Modal.Header closeButton={true}>
                <Modal.Title as='h5'>Rekapitulace Objednávky</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {/* Cart Part */}
                <div className="d-flex flex-column ">
                    {cart.map((item, index) => {
                        return (<React.Fragment key={index}>
                            <div className="d-flex justify-content-between">
                                <span style={{
                                    width: '70%',
                                }}>{item.nazev + ' ' + item.quantity + 'x'}</span>
                                <span>{Number(Number(item.cena) * Number(item.quantity)).toFixed(2) + ' Kč'}</span>
                            </div>
                            {
                                item.extraItems?.map((extraitem, index) => {
                                    return (
                                        <div className="d-flex justify-content-between" key={index}>
                                            <span className="text-muted" >{'+ ' + extraitem.name + ' ' + item.quantity + 'x'}</span>
                                            <span>{Number(Number(extraitem.price) * Number(item.quantity)).toFixed(2) + ' Kč'}</span>
                                        </div>
                                    )
                                })
                            }</React.Fragment>
                        )
                    })
                    }

                    {
                        packaging > 0 ?
                            <div className="d-flex justify-content-between">
                                <span className="text-muted">Balné</span>
                                <span>{packaging.toFixed(2)} Kč</span>
                            </div>
                            : null
                    }


                    {
                        delivery_orderpackaging > 0 ?
                            <div className="d-flex justify-content-between">
                                <span className="text-muted">Balné taška</span>
                                <span>{delivery_orderpackaging.toFixed(2)} Kč</span>
                            </div>
                            : null
                    }


                    {
                        deliveryPrice > 0 ?
                            <div className="d-flex justify-content-between">
                                <span className="text-muted">Doprava</span>
                                <span>{deliveryPrice.toFixed(2)} Kč</span>
                            </div>
                            : null
                    }

                    {
                        minimalOrderAddition > 0 ?
                            <div className="d-flex justify-content-between">
                                <span className="text-muted">Doplatit minimální objednávku ve výši</span>
                                <span style={{"color":"red"}}>{minimalOrderAddition.toFixed(2)} Kč</span>
                            </div>
                            : null
                    }

                    {
                        tipCourier > 0 ?
                            <div className="d-flex justify-content-between">
                                <span className="text-muted">Spropitné pro kurýra</span>
                                <span>{tipCourier.toFixed(2)} Kč</span>
                            </div>
                            : null
                    }

                    {
                        creditsUsed > 0 ?
                            <div className="d-flex justify-content-between">
                                <span className="text-muted">Kredit</span>
                                <span>-{creditsUsed.toFixed(2)} Kč</span>
                            </div>
                            : null
                    }
                </div>

                {/* Final price part */}
                <hr />
                <div className="d-flex justify-content-between">
                    <span>Celkem (vč. DPH)</span>
                    <span>{totalPrice.toFixed(2)} Kč</span>
                </div>

                {/* Other information part */}
                <hr />
                <div className="d-flex flex-column ">
                    {/* TODO when Pickup is implemented */}
                    {/* <div className="d-flex justify-content-between">
                        <span className="text-muted">Doprava</span>
                        <span>Osobní odběr</span>
                    </div> */}
                    <div className="d-flex justify-content-between">
                        <span className="text-muted">Objednávka</span>
                        <span>{cart.reduce((acc, item) => acc + item.quantity, 0) +
                            cart.reduce((acc, item) => acc + item.extraItems.reduce((acc, extraitem) => acc + item.quantity, 0), 0)
                        } položek</span>
                    </div>

                    <div className="d-flex justify-content-between">
                        <span className="text-muted">Způsob platby</span>
                        <span>{props.selectedPaymentMethod === 'cash' ? 'Hotově při předání'
                            : props.selectedPaymentMethod === 'credit-card' ? 'Kartou on-line ihned'
                                : props.selectedPaymentMethod === 'meal-voucher' ? 'Stravenkmi'
                                    : props.selectedPaymentMethod === 'twisto-gopay' ? 'Twisto - Až po jídle' : null}</span>
                    </div>

                    {
                        props.noteToChef ?
                            <div className="d-flex justify-content-between">
                                <span className="text-muted">Poznámka kuchařovi</span>
                                <span style={{ width: '70%', display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1 }}>{props.noteToChef}</span>
                            </div>
                            : null
                    }

                    {
                        props.noteToCourier ?
                            <div className="d-flex justify-content-between">
                                <span className="text-muted">Poznámka kurýrovi</span>
                                <span style={{ width: '70%', display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1 }}>{props.noteToCourier}</span>
                            </div>
                            : null
                    }

                    {
                        props.address ?
                            <div className="d-flex justify-content-between">
                                <span className="text-muted">Adresa doručení</span>
                                <span style={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1, textAlign:"right" }}>{
                                    props.address
                                }</span>
                            </div>
                            : null
                    }

                    {
                        props.name ?
                            <div className="d-flex justify-content-between">
                                <span className="text-muted">Jméno</span>
                                <span style={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1 }}>{props.name + ' ' + props.lastName ?? ''}</span>
                            </div>
                            : null
                    }

                    {
                        props.phone ?
                            <div className="d-flex justify-content-between">
                                <span className="text-muted">Telefon</span>
                                <span style={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1 }}>{props.phone}</span>
                            </div>
                            : null
                    }

                    {
                        props.email ?
                            <div className="d-flex justify-content-between">
                                <span className="text-muted">Email</span>
                                <span style={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1 }}>{props.email}</span>
                            </div>
                            : null
                    }
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button id="submit-newcheckout-button" style={{ "fontSize": "130%" }} size="lg" onClick={() => {
                    props.onHide()
                    setTimeout(() => {
                        props.submitNewOrder()
                    }, 500);
                }} className="mt-4 order-cart-submit w-100">Objednat <br /><span style={{ "fontSize": "80%" }}>s povinností platby</span></Button>
            </Modal.Footer>
        </Modal>

    )
}

export default CartRecapitulationModal