import React,{useContext} from 'react';
import OwlCarousel from 'react-owl-carousel3';
import useFetch from '../../useFetch';
import ProductBox from '../home/ProductBox';
import {AppContext} from '../../context.js'

const CategoriesCarousel = ()=> {

	const {state, dispatch} = useContext(AppContext)

	const {data: categoreis,loading,error} = useFetch('/api/restaurants/categories') 

	const setFilter = (filter)=>{
		dispatch({type: 'SET_RESTAURANT_FILTER', payload: filter})
	}

	return (
		categoreis &&
		<OwlCarousel nav loop {...options} className="owl-carousel-category owl-theme">
			{categoreis?.map(category=>(
				<div className="item">
				<ProductBox 
					boxClass='osahan-category-item'
					title={category.nazev}
					imageAlt='carousel'
					icon={category.img || "chef"}
					onClick={scrollTo}
					setFilter={setFilter}
				/>
				</div>
			))}
		</OwlCarousel>
	);
	
}



function scrollTo(id){
	try {
		const yOffset = -80; 
		const element = document.getElementById(id);
		const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
	
		window.scrollTo({top: y, behavior: 'smooth'});
	} catch (error) {
		console.log("Scroll to Error")
	}

}

const options={
	responsive: {
        0:{
            items:3,
        },
        600:{
            items:4,
        },
        1000: {
          items: 6,
        },
        1200: {
          items: 8,
        },
      },
      loop: true,
      lazyLoad: true,
      autoplay: true,
      dots: false,
      autoplaySpeed: 500,
      autoplayTimeout: 2000,
      autoplayHoverPause: true,
      nav: true,
      navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"]
}

export default CategoriesCarousel;