
import React, {useState} from 'react';
import Icofont from 'react-icofont';
import { Button } from 'react-bootstrap';
import useWindowSize from '../../useWindowsize'
import { NavLink,useParams } from 'react-router-dom';

  
const ReturnButton = () =>{
  
  
  const [visible, setVisible] = useState(true)
  const size = useWindowSize();
  let { id:mesto_url } = useParams();
  
  if(size.width <= 767.98 || window.Capacitor?.platform != "web") return ( //Mobile only
  <NavLink to={"/rozvoz-jidel/"+mesto_url}>
  <Button  style={{"display": visible ? 'inline' : 'none',...style}}>
      <Icofont icon="arrow-left"></Icofont> Zpět na nabídku
  </Button>
  </NavLink>
  )
  else return <></>

}
  
export default ReturnButton;

const style = {
    "backgroundColor":"#C62727",
    "zIndex":"999",
    "position":"fixed",
    "left":"2.5vw",
    "bottom":"80px",

}