import React, { useMemo } from 'react'
import { Container, Row} from 'react-bootstrap'
import SingleAdvertisingBanner from './SingleAdvertisingBanner';
import { useParams } from "react-router-dom";
import useFetch from '../../useFetch.js'
import useWindowSize from '../../useWindowsize';

const AdvertisingBanners = () => {
    const size = useWindowSize();
    let { id } = useParams(); 
    const {data: allimages,loading,error} = useFetch('/api/restaurants/banner?city='+id)
    const images = useMemo(() => {
        if(allimages)
        return allimages.filter(x=>x.obr)

        return []
    }, [allimages])





    return (
        <>
            {images &&
            <Container fluid style={{"max-width":"1292px", "width":`${size <= 767.98 ? '80%' : '100%'}`}} className={"banner-advertising-parent"}>
                <h3>Doporučujeme</h3>
                <Row>
                    { images && images.length > 0 ? (images.slice(0,4).map((image) => (
                        image.obr ? <SingleAdvertisingBanner key={image.obr} image = {image.obr} linkUrl={image.url} />:""
                    ))):""
                    }
                </Row>
                <Row>
                    { images && images.length > 4 ? (images.slice(4,8).map((image) => (
                        image.obr ? <SingleAdvertisingBanner key={image.obr} image = {image.obr} linkUrl={image.url} />:""
                    ))):""}
                </Row>
            </Container>
        }
        </>
    )
}

export default AdvertisingBanners

