import axios from 'axios';
import React, { useCallback, useState } from 'react';
import {Form,InputGroup,Modal,ButtonToolbar,Button,ToggleButton,ToggleButtonGroup,Row} from 'react-bootstrap';
import Icofont from 'react-icofont';
import AddressSearch from '../AddressSearch';
const AddAddressModal = (props)=> {

	const [locationFound, setLocationFound] = useState(false)
	const [nameInput, setNameInput] = useState("")
	
	const addAddress = useCallback(
		() => {
			if(locationFound){
				axios.post("/api/location/fav/"+locationFound+(nameInput.length>0?"?name="+nameInput:"")).then(response => {
					console.log(response)
					if(response.status===200){
						if(props.update)
							props.update(true)
							//window.location.reload();
						else
							window.location.reload();
					}
					else
						alert("Načítání adres ze serveru se nepodařilo.")
				  })
				
			}
			
		},
		[locationFound],
	)

	
    	return (
	        <Modal 
	        	show={props.show} 
	        	onHide={props.onHide}
		        centered
		   	  >
			  <Modal.Header closeButton={true}>
			    <Modal.Title as='h5' id="add-address">Přidat doručovací adresu</Modal.Title>
			  </Modal.Header>

			  <Modal.Body>
  				<Form>
             <div className="form-row">
			 	<Form.Group className="col-md-12">
                   <Form.Label>Poznámka pro mě</Form.Label>
                   <Form.Control onChange={e=>setNameInput(e.target.value)} value={nameInput} type="text" placeholder="Např. Práce / Domov" />
                </Form.Group>
                <Form.Group className="col-md-12">
                   <Form.Label>Oblast doručení</Form.Label>
				   <AddressSearch setLocationFound={setLocationFound} isSearchOnly={true}/>
                </Form.Group>
             </div>
          </Form>      
			  </Modal.Body>

			  <Modal.Footer>
			    {/* <Button type='button' onClick={this.props.onHide} variant="outline-primary" className="d-flex w-50 text-center justify-content-center">CANCEL</Button> */}
			    <Button onClick={()=>{addAddress();props.onHide()}} type='button' variant="primary" className='d-flex w-50 text-center justify-content-center'>Potvrdit</Button>
			  </Modal.Footer>
			</Modal>
    	);
    
}
export default AddAddressModal;