import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {Media,Image,OverlayTrigger,Tooltip} from 'react-bootstrap';
import PropTypes from 'prop-types'; 
import StarRating from '../common/StarRating';
import Icofont from 'react-icofont';


const Review = (props) => {

  const {data} = props


    return (
      <div className="reviews-members pt-4 pb-4">
         <Media>
            <Media.Body>
               <div className="reviews-members-header">
                  <div className="star-rating float-right">
                  	<StarRating fontSize={14} disabled={true} star={data.hodnoceni} getValue={() => {}}/>
                  </div>
                  <h6 className="mb-1"><Link className="text-black" to={props.profileLink}>{props.name}</Link></h6>
                  <p className="text-gray">{props.reviewDate}</p>
               </div>
               <div className="reviews-members-body">
                  <p>{data.popis}</p>
               </div>
               <div className="reviews-members-footer">

               </div>
            </Media.Body>
         </Media>
      </div>
    );
  
}



export default Review;