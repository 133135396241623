import React from 'react';
import PropTypes from 'prop-types'; 

class CartDropdownHeader extends React.Component {
	render() {
		return (

			<div className={this.props.className}>
				{
					this.props.image?
					<img alt="" src={this.props.image} className={this.props.imageClass} />:''
				}
	             <h6 className="mb-0 cart-dropdown-title">{this.props.title}</h6>
				{
					this.props.subTitle?
	             	<p className="mb-0 cart-dropdown-subtitle">{this.props.subTitle}</p>:''
	            }
	        </div>
		);
	}
}


export default CartDropdownHeader;