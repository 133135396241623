import React from 'react';
import {Card,Media} from 'react-bootstrap';
import Icofont from 'react-icofont';
import PropTypes from 'prop-types'; 

class ChooseAddressCard extends React.Component {

	render() {
    	return (
         <>
         {this.props.type==="hasAddress"?
	         (
               <Card className={`bg-white addresses-item mb-4 ${this.props.selected ? " selected " :" "}` + (this.props.boxClass)}>
               <div className="gold-members p-4">
                  <Media>
                  
                     <div className="mr-3"><Icofont icon={this.props.icoIcon} className={this.props.iconclassName} /></div>
                     <div className="media-body">
                        <span className="addresses-item-time"><Icofont icon="clock-time"/> 30min</span>
                        <h6 className="mb-1">{this.props.title}</h6>
                        <p className="addresses-item-address">{this.props.address}
                        </p>
                        <p className="mb-0 text-black font-weight-bold">
                        </p>
                     </div>
                  </Media>
               </div>
               </Card>
            )
	         :
            (
               <Card className="addresses-item-add-address" onClick={this.props.onAddNewClick}>
                  <Icofont className="add-address-icon" icon="settings-alt"/>
                  <div>PŘIDAT NOVOU ADRESU</div>
               </Card>
            )
            
         }
        </>
    	);
    }
}

ChooseAddressCard.propTypes = {
  title: PropTypes.string.isRequired,
  icoIcon: PropTypes.string.isRequired,
  iconclassName: PropTypes.string,
  address: PropTypes.string,
  onDeliverHere: PropTypes.func,
  onAddNewClick: PropTypes.func,
  type: PropTypes.string.isRequired
};

ChooseAddressCard.defaultProps = {
  	type:'hasAddress'
}


export default ChooseAddressCard;
