import React, {useState, useContext} from 'react';
import {Switch,Route} from 'react-router-dom';
import {NavLink,Link } from 'react-router-dom';
import {Row,Col,Container,Image} from 'react-bootstrap';
import Orders from './myaccount/Orders';
import Favourites from './myaccount/Favourites';
import Payments from './myaccount/Payments';
import Addresses from './myaccount/Addresses';
import EditProfileModal from './modals/EditProfileModal';
import Icofont from 'react-icofont';




import {AppContext} from '../context.js'
import { ProtectedRoute } from "../protectedRoute.js";
import Credits from './myaccount/Credits';
import Statistics from './myaccount/Statistics';


 const MyAccount =() => {
  
   
  const [showEditProfile, setshowEditProfile] = useState(false)
  const hideEditProfile = () => setshowEditProfile(false);
  const {state, dispatch} = useContext(AppContext)

    	return (
    		<>
        <EditProfileModal show={showEditProfile} setshowEditProfile={setshowEditProfile} onHide={hideEditProfile} user={state.user} dispatch={dispatch}/>
        <section className="section pb-4 osahan-account-page" style={{"padding-top":"4rem"}}>
           <Container>
              <Row>
                 <Col md={3}>
                    <div className="osahan-account-page-left shadow-sm bg-white h-100">
                       <div className="border-bottom p-4">
                          <div className="osahan-user text-center">
                             <div className="osahan-user-media">
                                 <Icofont style={{"padding":"15px","font-size":"4rem"}} icon='user-alt-3'/>
                                <div className="osahan-user-media-body" style={{"margin-top":"10px"}}>
                                   <h6 className="mb-2"> {state?.user?.jmeno + " " +state?.user?.prijmeni} </h6>
                                   <p className="mb-1"> <Icofont icon='phone'/> {state.user && state.user.telefon ? ""+state.user.telefon : <Link to='#' onClick={() => setshowEditProfile(true)} className="mr-3"> Přidat telefon</Link>}</p>
                                   <p>{state.user && state.user.login}</p>
                                   <p className="mb-0 text-black font-weight-bold"><Link to='#' onClick={() => setshowEditProfile(true)} className="text-primary mr-3"><i className="icofont-ui-edit"></i> UPRAVIT</Link></p>
                                </div>
                             </div>
                          </div>
                       </div>
                       <ul className="nav flex-column border-0 pt-4 pl-4 pb-4">
                           
                           {/* <li className="nav-item">
                              <NavLink className="nav-link" activeClassName="active" exact to="/mujucet/statistiky"><Icofont icon="chart-bar-graph"/>Statistiky</NavLink>
                           </li> */}
                          <li className="nav-item">
                             <NavLink className="nav-link" activeClassName="active" exact to="/mujucet/objednavky"><i className="icofont-food-cart"></i> Objednávky</NavLink>
                          </li>
                          <li className="nav-item">
                             <NavLink className="nav-link" activeClassName="active" exact to="/mujucet/oblibene"><i className="icofont-heart"></i> Oblíbené</NavLink>
                          </li>
                          <li className="nav-item">
                              <NavLink className="nav-link" activeClassName="active" exact to="/mujucet/kredity"><Icofont icon="upload-alt"/> Kredity</NavLink>
                           </li>
                          {/* <li className="nav-item">
                             <NavLink className="nav-link" activeClassName="active" exact to="/mujucet/platby"><i className="icofont-credit-card"></i> Způsoby plateb</NavLink>
                          </li> */}
                          <li className="nav-item">
                             <NavLink className="nav-link" activeClassName="active" exact to="/mujucet/adresy"><i className="icofont-location-pin"></i> Adresy</NavLink>
                          </li>
                         
                       </ul>
                    </div>
                 </Col>
                 <Col md={9}>
                  <Switch>
                     <ProtectedRoute path="/mujucet/kredity" exact component={() => <Credits credits={[
                        {"name":"všech restauracích", "count": 642},
                        {"name":"Polévkárna od mámy", "count": 56},
                        {"name":"Řízkárna od mámy", "count": 2},
                        {"name":"BAIFU SUSHI", "count": 11},
                        {"name":"Vietnam Minth Tuan", "count": 14},
                        {"name":"Restaurant Bambus", "count": 26},
                        {"name":"MamaBurger", "count": 56},
                        ]}/>} />
                     <ProtectedRoute path="/mujucet/statistiky" exact component={() => <Statistics 
                     
                     favoriteRestaurants = {[
                        {"name":"Restaurace pod Kaštanem - Pastelákovi", "count" : 76},
                        {"name":"Lam Anh Asio Bistro", "count" : 73},
                        {"name":"Pizzerie in PIazza Restaurant", "count" : 38},
                        {"name":"New York Burger and Chicken", "count" : 34},
                     ]}
                     favoriteFoods = {[
                        {"name":"Svíčková na Smetaně, knedlík","fromRestaurant":"Restaurace pod Kaštanem - Pastelákovi", "count" : 76},
                        {"name":"120g Klasik + 4 Topinky","fromRestaurant":"pivnice u Feřtů", "count" : 73},
                        {"name":"Gulášová polévka","fromRestaurant":"Restaurace pod Kaštanem - Pastelákovi", "count" : 38},
                        {"name":"Bacon Cheeseburger","fromRestaurant":"New York Burger and Chicken", "count" : 34},
                     ]}
                     lastOrders = {[
                        {"date": "09.02.2021 15:23","fromRestaurant":"Restaurace pod Kaštanem - Pastelákovi", "price" : 289},
                        {"date":"02.02.2021 14:23","fromRestaurant":"Rudys Kebabza", "price" : 1000},
                        {"date":"04.05.2021 15:23","fromRestaurant":"Restaurace pod Kaštanem - Pastelákovi", "price" : 150},
                        {"date":"07.02.2020 15:23","fromRestaurant":"New York Burger and Chicken", "price" : 311},
                     ]}
                     
                     />} />
                     <ProtectedRoute path="/mujucet/objednavky" component={Orders} />
                     <ProtectedRoute path="/mujucet/oblibene" exact component={Favourites} />
                     <ProtectedRoute path="/mujucet/platby" exact component={Payments} />
                     <ProtectedRoute path="/mujucet/adresy" exact component={Addresses} />
                  </Switch>
                 </Col>
              </Row>
           </Container>
        </section>
    		</>
    	);
    }




export default MyAccount;