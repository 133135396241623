import React from 'react'
import Icofont from 'react-icofont';
import PropTypes from 'prop-types'; 

const PaymentMethod = (props) => {
    return (
        <div style={{"opacity":props.disabled?"0.5":"1"}} className={`payment-method ${props.selected==props.value ? " selected" : ""} `}>
            {props.customIcon ? (
                <img src={props.imagePath} className="payment-method-image"/>
            ):(
                <Icofont icon={props.iconName} className="payment-method-icon"/> 
            )}
            
            <span>{props.title}</span>
        </div>
    )
}

export default PaymentMethod

PaymentMethod.propTypes = {
    selected: PropTypes.bool,
    customIcon: PropTypes.bool,
    imagePath: PropTypes.string,
    iconName: PropTypes.string,
    title: PropTypes.string,
};

PaymentMethod.defaultProps = {
    selected: false,
    customIcon: false,
    imagePath: "",
    iconName: "",
    title: "",
}