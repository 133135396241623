import React, { useContext } from 'react'
import CheckoutItem from '../common/CheckoutItem';

import { AppContext } from '../../context.js'

const CartItems = ({ items, canEditQty, restaurant }) => {

    const { state, dispatch } = useContext(AppContext)

    return (
        <>
            {state.cart.map((item, index) => (
                <CheckoutItem
                    key={index}
                    restaurant={restaurant}
                    item={item}
                    canEditQty={true}
                    dispatch={dispatch}
                />
            ))}
        </>
    )
}

export default CartItems


