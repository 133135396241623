import React,{useContext,useState} from 'react';
import {Link} from 'react-router-dom';
import {Row,Col,Container, Button, Spinner} from 'react-bootstrap';
import Icofont from 'react-icofont';
import useFetch from '../useFetch.js'
import {useParams} from 'react-router-dom';


import {AppContext} from '../context.js'
 

const Contact = ()=> {

	const {state, dispatch} = useContext(AppContext)
	let { id } = useParams();
	const {data: html,loading: loading,error:error} = useFetch(`/api/location/contact/`+(id)) 

	if(loading) return (
		<Container className="pb-5" style={{"paddingTop":"8rem","minHeight":"60vh"}}>
			<Row>
				<Col md={12} className="text-center load-more">
				<Button variant="primary" type="button" disabled="">
					<Spinner animation="grow" size="sm" className='mr-1' />
					Načítání...
				</Button>  
				</Col>
			</Row>
		</Container>
	)

	if(error) return (
		<Container className="pb-5" style={{"padding-top":"8rem","min-height":"60vh"}}>
			<Row>
				<Col md={12} className="text-center load-more">
				<Button variant="primary" type="button" disabled="">
					Chyba 
				</Button>  
				</Col>
			</Row>
		</Container>
	)

	return (<>
		<section dangerouslySetInnerHTML={{ __html: html.content }} className="section pt-5  bg-white osahan-track-order-page position-relative">
	
	      </section>
		  {/* <Link to="/kontakt-rozvoz-jidel">Kontakt na ostatní města...</Link> */}
    	</>);
    
}


export default Contact;