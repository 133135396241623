import React from 'react';
import {OverlayTrigger, Tooltip } from 'react-bootstrap';
import Icofont from 'react-icofont';
import PropTypes from 'prop-types'; 

const CardItemOverlay = (props) => {
    return (
        <OverlayTrigger
			key="top"
			placement="top"
			overlay={
				<Tooltip id='tooltip-top'>
					{props.text} { props.freeDeliveryFrom ? <> {props.freeDeliveryFrom} Kč</> : ""}
				</Tooltip>
		}>
			<Icofont className={`card-information-icon ${props.customClass}`} icon={props.icon}/>
		</OverlayTrigger>
    )
}

export default CardItemOverlay

CardItemOverlay.propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    freeDeliveryFrom: PropTypes.number,
};

CardItemOverlay.defaultProps = {
    text: '',
    icon: '',
}