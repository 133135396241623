import React from 'react'
import { Col } from 'react-bootstrap'
import PropTypes from 'prop-types'; 

const Statistics = (props) => {
    return (
        <div className="p-4 bg-white shadow-sm">
            <h4 className="font-weight-bold mt-0 mb-4">Statistiky</h4>
            <Col className="my-account-statistics">
                <h6 className="my-account-statistics-titles">Vaše oblíbené restaurace</h6>
                <div className="my-account-statistics-favorites-parent mt-2">
                    {props.favoriteRestaurants ? props.favoriteRestaurants.map((restaurant) => (

                        <><span>{restaurant.name}</span><span className="my-account-credits-right">{restaurant.count} x objednáno</span></>

                    )):""}
                    
                </div>

                <h6 className="my-account-statistics-titles mt-4">Vaše oblíbená jídla</h6>
                <div className="my-account-statistics-favorites-parent mt-2">
                    {props.favoriteFoods ? props.favoriteFoods.map((food) => (

                        <><span>{food.name} z {food.fromRestaurant}</span><span className="my-account-credits-right">{food.count} x objednáno</span></>

                    )):""}
                </div>

                <h6 className="my-account-statistics-titles mt-4">Vaše poslední objednávky</h6>
                <div className="mt-2">
                    {props.lastOrders ? props.lastOrders.map((order) => (

                        <><span>{order.date} z {order.fromRestaurant} {order.price} Kč</span><br/></>

                    )):""}
                </div>

            </Col>
        </div>
    )
}

export default Statistics


Statistics.defaultProps = {
    favoriteFoods: '',
    favoriteRestaurants: '',  
    lastOrders: '',  
}
