import React,{useContext,useEffect} from 'react'
import { Helmet } from 'react-helmet'
import {AppContext} from '../context.js'
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';


export const Conversions = () => {

    const {state} = useContext(AppContext)
    const location = useLocation();

    const paths = ['olomouc', 'opava', 'ostrava', 'prostejov', 'usti-nad-labem', 'teplice', 'louny', 'melnik', 'zatec'];

    useEffect(() => {
      let cityUrl = location.pathname.split('/');
      let cityName = false
      

      if(cityUrl.includes("hotovo")){
        cityName = state?.location?.mesto_url || false;
      }else{
        cityName = cityUrl[2] || false;
      }
    
      console.log("GA4 check " + cityName + " " + paths.includes(cityName));
    
      if (paths.includes(cityName)) {
        console.log("GA4 - LOADED GTM FOR " + cityName);
    
        let gtmId = '';
    
        switch (cityName) {
          case 'olomouc':
          case 'opava':
          case 'ostrava':
          case 'prostejov':
            gtmId = 'GTM-NT8537S';
            break;
          case 'usti-nad-labem':
          case 'teplice':
            gtmId = 'GTM-PV9M7WV';
            break;
          case 'louny':
          case 'melnik':
          case 'zatec':
            gtmId = 'GTM-NH8MK77';
            break;
          default:
            break;
        }
    
        if (gtmId) {
          const tagManagerArgs = {
            gtmId: gtmId
          };
    
          TagManager.initialize(tagManagerArgs);
        }
      }
    }, [location]);


    if(state.user_tracking == true && state.cookies_permission?.analytic  && state.cookies_permission?.marketing) return (
        <Helmet>

                {/* <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MV4HG9L "height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> */}
            
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-1003115517"></script>
                
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-SBK4HF0WBE"></script>

                <script src="https://c.seznam.cz/js/rc.js"></script>

                <script async src="/trackingScript.js"></script>

        </Helmet>
    )
    else return <Helmet>
            
        </Helmet>
}
