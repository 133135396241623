import React from 'react';
import {Link } from 'react-router-dom';
import {Image,Media} from 'react-bootstrap';
import Icofont from 'react-icofont';
import { Button } from 'react-bootstrap';

class OrderCard extends React.Component {

	render() {
		let {order} = this.props;
    	return (
	      <div className="bg-white card mb-4 order-list">
	          <div className="gold-members p-4">
	                <Media>
	                   <Image className="mr-4" src={`https://www.jidlopodnos.cz/data/restaurace/mkartan/${order.restaurant_id}.jpg`} onError={(e)=>{e.target.onerror = null; e.target.style.display="none"}} alt={this.props.imageAlt} />
	                   <Media.Body>
	                   		{this.props.deliveredDate?
	                   			(
			                      <span className="float-right text-info">
			                      	<Icofont icon="check-circled" className="text-success ml-1" />
			                      </span>
			                    )
			                    :""
	                   		}
	                      <h6 className="mb-2">
	                      	<Link to={`/rozvoz-jidel/${order.mesto_url}/${order.restaurant_url}`}  className="text-black">{order.restaurant_name} </Link>
	                      </h6>
	    
	                      <p className="text-gray mb-3">
	                      	<Icofont icon="list" /> Objednávka #{order.order_number} 
	                      	<Icofont icon="clock-time" className="ml-2" /> {order.order_date} 
	                      </p>
	                      <p className="text-dark">
	                      	{order.order_items} 
	                      </p>
	                      <hr />
	                      <p className="mb-0 text-black text-primary pt-2">
	                      	<span className="text-black font-weight-bold"> Celkem zaplaceno:</span> {order.order_price} Kč
							  <br/>
							<Link to={`/objednavka/${order.order_number}?guid=${order.guid}`} className="float-right">
							   	Detail
							</Link>
	                      </p>
	                   </Media.Body>
	                </Media>
	          </div>
	       </div>
    	);
    }
}

export default OrderCard;